import { useMedia } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { BundlesModal } from './BundlesModal';
import type { AvailableBundle, Bundle } from 'shared/api';

export const useBundlesModal = (bundles: Array<AvailableBundle | Bundle>) => {
  const { isMobile } = useMedia();

  const { open } = useCreateModal(
    BundlesModal,
    { size: isMobile ? 'bottomSheet' : 'large', bundles },
    { customId: 'bundleDeals' }
  );

  const openBundlesModal = useCallback(() => {
    open();
  }, [open]);

  return { openBundlesModal };
};
