import { ModalView, useMedia, type ModalComponentProps } from '@partstech/ui';
import { useToggle } from '@partstech/ui/hooks';
import { useCallback, useMemo } from 'react';
import { usePlans } from '../../hooks/usePlans';
import { useSubscription } from '../../hooks/useSubscription';
import { useSubscriptionSelection } from '../../hooks/useSubscriptionSelection';
import { DesktopView } from './DesktopView';
import { MobileView } from './MobileView';
import type { PaidFeatureAlias } from '../../constants/features';
import type { ProductAlias } from '../../constants/products';

type ComparePlansModalProps = {
  highlightFeature?: PaidFeatureAlias | null;
};

export const ComparePlansModal = ({
  highlightFeature = null,
  onClose,
}: ModalComponentProps<ComparePlansModalProps>) => {
  const { isMobile } = useMedia();

  const { isUpdatePeriod, isYearly: isYearlyPeriod, periodToggle, selectPlan } = useSubscriptionSelection();

  const { isToggle: isYearly, toggle } = useToggle(isYearlyPeriod);

  const { isLoading, plans, getPlanByAlias } = usePlans(isYearly);
  const { isRestrictedEX, subscription } = useSubscription();

  const hasHeader = !isMobile || isRestrictedEX;

  const plansList = useMemo(() => {
    if (isRestrictedEX) {
      return plans.filter((plan) => plan.alias === subscription?.plan.alias);
    }

    return plans;
  }, [isRestrictedEX, plans, subscription?.plan]);

  const togglePeriod = useCallback(() => {
    toggle();
    periodToggle();
  }, [toggle, periodToggle]);

  const handleControlButtonClick = useCallback(
    (alias: ProductAlias) => {
      selectPlan(alias, true);
      onClose();
    },
    [selectPlan, onClose]
  );

  return (
    <ModalView
      title="My subscription page"
      closeIcon="arrow_back"
      onClose={onClose}
      headerElevation={hasHeader}
      headerDivider={hasHeader}
    >
      {isMobile ? (
        <MobileView
          highlightFeature={highlightFeature}
          isRestrictedEX={isRestrictedEX}
          plans={plansList}
          getPlanByAlias={getPlanByAlias}
          periodToggle={togglePeriod}
        />
      ) : (
        <DesktopView
          highlightFeature={highlightFeature}
          isControlButtonDisabled={isUpdatePeriod}
          isLoading={isLoading}
          isRestrictedEX={isRestrictedEX}
          isYearly={isYearly}
          plans={plansList}
          onControlButtonClick={handleControlButtonClick}
          periodToggle={togglePeriod}
        />
      )}
    </ModalView>
  );
};
