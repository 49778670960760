import {
  AvailabilityLine,
  EnhancementAvailabilityLine,
  FeaturedAvailabilityLine,
  FeaturedEnhancementsAvailabilityLine,
} from 'models';
import type { AvailabilityLineData } from 'models';
import type { AvailabilityLineVariant } from 'types/product';

export const createAvailability = (line: AvailabilityLineData, variant?: AvailabilityLineVariant) => {
  switch (variant) {
    case 'featured':
    case 'partialMain':
      return new FeaturedAvailabilityLine(line);

    case 'defaultEnhancements':
      return new EnhancementAvailabilityLine(line);

    case 'featuredEnhancements':
    case 'partialMainEnhancements':
      return new FeaturedEnhancementsAvailabilityLine(line);

    default:
      return new AvailabilityLine(line);
  }
};
