import { DealerQuote } from '../../../models/Quote/DealerQuote';
import { Quote } from '../../../models/Quote/Quote';
import { createAvailabilityLineFromData } from './createAvailabilityLineFromData';
import type { Product } from 'models';
import type { Quote as QuoteType } from 'types/product';

export const createQuoteFromData = (
  quote: QuoteType,
  stockMessage: Product['stockMessage'],
  isDealer?: boolean,
  storeId?: string,
  accountNickname?: string | null,
  filterEnhancements: boolean = false,
  hasTargetSupplier: boolean = false
) => {
  if (!quote) {
    return null;
  }

  const variant = Quote.getVariant(
    quote.availability?.lines?.map((line) => line.group) ?? [],
    quote.status,
    hasTargetSupplier,
    filterEnhancements
  );

  const lines =
    quote.availability?.lines?.map((line) => createAvailabilityLineFromData(line, storeId, accountNickname, variant)) ??
    [];

  if (isDealer) {
    return new DealerQuote({
      action: quote.action,
      status: quote.status,
      lines,
      stockMessage,
      variant,
    });
  }

  return new Quote({ action: quote.action, status: quote.status, lines, stockMessage, variant });
};
