import { targetSupplierIds } from 'constant';
import { Media, type Store, type Supplier } from 'models';
import { Brand } from '../../models/Brand';
import { PartNumber } from '../../models/PartNumber';
import { Product } from '../../models/Product';
import { createQuoteFromData } from './quote/createQuoteFromData';
import type { AttributeName } from 'constant';
import type { Bundle, ProductDeliveryNote } from 'shared/api';
import type { Product as ProductRestType } from 'shared/api/rest/gen/shop';
import type { ProductEntity } from 'types/product';

type ProductRest = ProductEntity & {
  bundles?: Bundle[];
  sponsoredPurchasesCount?: number | null;
  shippingCharge?: boolean;
};

type Arguments = {
  productData: ProductRest;
  supplier?: Supplier | null;
  store?: Store | null;
  showRetailPrice?: boolean;
  isPreferred?: boolean;
  accountNickname?: string | null;
  filterEnhancements?: boolean;
};

const createStockMessage = (stockMessage: Record<string, string> | null): ProductDeliveryNote[] | null =>
  stockMessage
    ? [stockMessage].reduce<ProductDeliveryNote[]>((acc, note) => ({ ...acc, label: note, text: note }), [])
    : null;

const createImages = (images: ProductRestType['images']) => {
  const formatedImages = [images.partImage, ...images.other];

  return {
    images: formatedImages.map((image) => ({
      full: image?.full ?? '',
      preview: image?.preview ?? '',
      medium: image?.medium ?? '',
    })),
    image360Groups: images.image360Groups.map((group) =>
      group.map((groupImage) => ({
        full: groupImage.full,
        medium: groupImage.preview,
        preview: groupImage.preview,
      }))
    ),
    videos: [],
  };
};

const createAttributes = (attributes: ProductRestType['attributes']) =>
  attributes.map((attribute) => ({
    name: attribute.name as AttributeName,
    values: attribute.value,
  }));

const createVariations = (variations: ProductRestType['variations']) =>
  variations.map(({ attributes, notes, variationId }) => ({
    attributes: createAttributes(attributes),
    notes: notes.map((note) => ({ group: note })),
    variationID: variationId,
  }));

/** @deprecated use src/factories/product/createBaseProductFromQuery.ts instead */
export const createProductFromData = ({
  productData: product,
  supplier = null,
  store = null,
  showRetailPrice = false,
  isPreferred = false,
  accountNickname = null,
  filterEnhancements = false,
}: Arguments) => {
  const model = new Product({
    attributes: createAttributes(product.attributes),
    bundles: product.bundles ?? [],
    coreCharge: product.coreCharge ?? null,
    credentialId: product.credentialId ? String(product.credentialId) : null,
    descriptions: product.descriptions.map((description) => ({
      ...description,
      values: description.value,
    })),
    fetCharge: product.fetCharge ?? null,
    hasVehicleFitment: product.hasVehicleFitment,
    id: product.id,
    interchangePart: product.interchangePart,
    lineCardId: product.lineCardId,
    listPrice: product.listPrice ?? null,
    mapPrice: product.mapPrice ?? null,
    notes: product.notes,
    notesOem: product.notesOem,
    partTypeId: product.partTypeId ? String(product.partTypeId) : null,
    partTypeName: product.partTypeName,
    price: product.price ?? null,
    quantityStep: product.quantityStep ?? null,
    rebates: product.rebates,
    retailPrice: product.shopRetailPrice ?? null,
    rewardPoints: product.rewardPoints,
    sponsoredPurchasesCount: product.sponsoredPurchasesCount ?? null,
    sponsorType: product.sponsorType ?? null,
    stockMessage: createStockMessage(product.stockMessage),
    shippingCharge: product.shippingCharge ?? false,
    sortHash: product.sortHash,
    stocked: false,
    title: product.title,
    variations: createVariations(product.variations),
    vehicleId: product.vehicleId,
  });

  if (supplier) {
    model.setSupplier(supplier);
  }

  if (store) {
    model.setStore(store);
  }

  model.setMedia(new Media(createImages(product.images)));

  model.setShowRetailPrice(showRetailPrice);

  model.setPartNumber(new PartNumber({ partNumber: product.partNumber, id: product.partNumberId }));

  model.setBrand(
    new Brand({
      id: product.brandId ? String(product.brandId) : null,
      isPreferred,
      lineCode: product.brandLineCode,
      logo: product.brandLogo,
      name: product.brandName,
    })
  );

  if (product.quote) {
    model.setQuote(
      createQuoteFromData(
        product.quote,
        createStockMessage(product.stockMessage),
        supplier?.isDealer,
        store?.id,
        accountNickname,
        targetSupplierIds.includes(Number(supplier?.id)),
        filterEnhancements
      )
    );
  }

  return model;
};
