/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { FullProductFragmentDoc } from './FullProductFragment.generated';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetProductQueryVariables = Types.Exact<{
  partNumberId: Types.Scalars['String']['input'];
  accountId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  lineCardId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  partTypeId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vehicleId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vin?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetProductQuery = {
  __typename?: 'Query';
  product?: {
    __typename?: 'Product';
    marketingDescriptions: Array<Types.Scalars['String']['input']>;
    coreCharge?: Types.Scalars['BigDecimal']['input'] | null;
    customerPrice?: Types.Scalars['BigDecimal']['input'] | null;
    fetCharge?: Types.Scalars['BigDecimal']['input'] | null;
    hasFitment: Types.Scalars['Boolean']['input'];
    id: string;
    listPrice?: Types.Scalars['BigDecimal']['input'] | null;
    mapPrice?: Types.Scalars['BigDecimal']['input'] | null;
    notesOem: Array<Types.Scalars['String']['input']>;
    orderType: Types.OrderType;
    partNumber: Types.Scalars['String']['input'];
    partNumberId: Types.Scalars['String']['input'];
    price?: Types.Scalars['BigDecimal']['input'] | null;
    quantityStep: number;
    sponsorType: Types.SponsorType;
    sponsoredPurchasesCount?: number | null;
    sale: Types.Scalars['Boolean']['input'];
    shippingCharge: Types.Scalars['Boolean']['input'];
    sortHash?: Types.Scalars['String']['input'] | null;
    stocked: Types.Scalars['Boolean']['input'];
    title: Types.Scalars['String']['input'];
    attachments: Array<{
      __typename?: 'ProductAttachment';
      url: Types.Scalars['String']['input'];
      filename: Types.Scalars['String']['input'];
      group?: Types.Scalars['String']['input'] | null;
    }>;
    diagrams?: Array<{
      __typename?: 'Diagram';
      id: string;
      category?: { __typename?: 'PartCategory'; id: string } | null;
    }> | null;
    productAttributes: Array<{
      __typename?: 'ProductAttribute';
      name: Types.Scalars['String']['input'];
      values: Array<Types.Scalars['String']['input']>;
    }>;
    extendedInformation: Array<{
      __typename?: 'ProductExtendedInformation';
      name: Types.Scalars['String']['input'];
      value: Types.Scalars['String']['input'];
    }>;
    freightPackage?: {
      __typename?: 'ProductFreightPackage';
      weight?: Types.Scalars['Float']['input'] | null;
      merchandising?: {
        __typename?: 'ProductDimensions';
        height: Types.Scalars['Float']['input'];
        length: Types.Scalars['Float']['input'];
        width: Types.Scalars['Float']['input'];
      } | null;
      shipping?: {
        __typename?: 'ProductDimensions';
        height: Types.Scalars['Float']['input'];
        length: Types.Scalars['Float']['input'];
        width: Types.Scalars['Float']['input'];
      } | null;
    } | null;
    links: Array<{
      __typename?: 'ProductLink';
      name: Types.Scalars['String']['input'];
      url: Types.Scalars['String']['input'];
    }>;
    specificMarketingDescriptions: Array<{
      __typename?: 'ProductDescription';
      code: Types.Scalars['String']['input'];
      label: Types.Scalars['String']['input'];
      values: Array<Types.Scalars['String']['input']>;
    }>;
    interchanges?: Array<{
      __typename?: 'ProductInterchange';
      brandName?: Types.Scalars['String']['input'] | null;
      partNumber: Types.Scalars['String']['input'];
      type: Types.ProductInterchangeType;
    }> | null;
    warnings: Array<
      | { __typename?: 'ProductWarningAttachment'; url: Types.Scalars['String']['input'] }
      | { __typename?: 'ProductWarningImage'; url: Types.Scalars['String']['input'] }
      | { __typename?: 'ProductWarningText'; text: Types.Scalars['String']['input'] }
    >;
    attributes: Array<{
      __typename?: 'ProductAttribute';
      name: Types.Scalars['String']['input'];
      values: Array<Types.Scalars['String']['input']>;
    }>;
    availability: Array<{
      __typename?: 'ProductAvailabilityLine';
      address?: Types.Scalars['String']['input'] | null;
      group?: Types.Scalars['String']['input'] | null;
      id?: number | null;
      name: Types.Scalars['String']['input'];
      quantity: number;
      type: Types.ProductAvailabilityLineType;
    }>;
    brand?: {
      __typename?: 'Brand';
      id: string;
      lineCode?: Types.Scalars['String']['input'] | null;
      logo?: Types.Scalars['String']['input'] | null;
      name: Types.Scalars['String']['input'];
    } | null;
    bundles: Array<{
      __typename?: 'Bundle';
      id: string;
      name: Types.Scalars['String']['input'];
      description?: Types.Scalars['String']['input'] | null;
      expirationDate?: Types.Scalars['String']['input'] | null;
    }>;
    descriptions: Array<{
      __typename?: 'ProductDescription';
      code: Types.Scalars['String']['input'];
      label: Types.Scalars['String']['input'];
      values: Array<Types.Scalars['String']['input']>;
    }>;
    deliveryNotes: Array<{
      __typename?: 'ProductDeliveryNote';
      label: Types.Scalars['String']['input'];
      text: Types.Scalars['String']['input'];
    }>;
    images: Array<{
      __typename?: 'ProductImage';
      full: Types.Scalars['String']['input'];
      medium: Types.Scalars['String']['input'];
      preview: Types.Scalars['String']['input'];
    }>;
    interchangeFor?: {
      __typename?: 'ProductInterchange';
      brandName?: Types.Scalars['String']['input'] | null;
      partNumber: Types.Scalars['String']['input'];
      type: Types.ProductInterchangeType;
    } | null;
    image360Groups: Array<{
      __typename?: 'ProductImage360Group';
      images: Array<{
        __typename?: 'ProductImage';
        full: Types.Scalars['String']['input'];
        preview: Types.Scalars['String']['input'];
        medium: Types.Scalars['String']['input'];
      }>;
    }>;
    lineCard?: { __typename?: 'LineCard'; id: string } | null;
    notes: Array<{ __typename?: 'ProductNotes'; group: Array<Types.Scalars['String']['input']> }>;
    partType?: { __typename?: 'PartType'; id: string; name: Types.Scalars['String']['input'] } | null;
    rebates: Array<{
      __typename?: 'ProductRebate';
      couponLink?: Types.Scalars['String']['input'] | null;
      description: Types.Scalars['String']['input'];
      legalLink?: Types.Scalars['String']['input'] | null;
      price?: Types.Scalars['BigDecimal']['input'] | null;
      title: Types.Scalars['String']['input'];
      validDate?: Types.Scalars['String']['input'] | null;
    }>;
    rewardPoints?: {
      __typename?: 'RewardPoints';
      isPromotion: Types.Scalars['Boolean']['input'];
      points: number;
    } | null;
    variations: Array<{
      __typename?: 'ProductVariation';
      variationID: Types.Scalars['String']['input'];
      attributes: Array<{
        __typename?: 'ProductAttribute';
        name: Types.Scalars['String']['input'];
        values: Array<Types.Scalars['String']['input']>;
      }>;
      notes: Array<{ __typename?: 'ProductNotes'; group: Array<Types.Scalars['String']['input']> }>;
    }>;
    videos: Array<{
      __typename?: 'ProductVideo';
      full: Types.Scalars['String']['input'];
      medium: Types.Scalars['String']['input'];
      preview: Types.Scalars['String']['input'];
    }>;
  } | null;
};

export const GetProductDocument = `
    query GetProduct($partNumberId: String!, $accountId: ID, $lineCardId: ID, $partTypeId: ID, $vehicleId: ID, $vin: String) {
  product(
    partNumberId: $partNumberId
    accountId: $accountId
    lineCardId: $lineCardId
    partTypeId: $partTypeId
    vehicleId: $vehicleId
    vin: $vin
  ) {
    ...FullProduct
  }
}
    ${FullProductFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetProduct: build.query<GetProductQuery, GetProductQueryVariables>({
      query: (variables) => ({ document: GetProductDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetProductQuery, useLazyGetProductQuery } = injectedRtkApi;
