import { FullStory } from '@fullstory/browser';

export const getFullStorySessionId = (isDisabled: boolean): string => {
  try {
    if (isDisabled) {
      return '';
    }

    return FullStory('getSession', { format: 'id' }) ?? '';
  } catch (e) {
    return '';
  }
};

export const getFullStorySessionIdAsync = async (isDisabled: boolean): Promise<string> => {
  try {
    if (isDisabled) {
      return '';
    }

    return (await FullStory('getSessionAsync', { format: 'id' })) ?? '';
  } catch (e) {
    return '';
  }
};
