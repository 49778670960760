import { uniqBy } from '@partstech/ui/utils';
import { targetSupplierIds } from 'constant';
import { createAvailabilityLineFromQuery } from 'factories/product';
import { CartOrderItem, DealerQuote, PartType, Quote } from 'models';
import { getCustomAttributesForSection } from 'utils';
import type { AttributeName } from 'constant';
import type { CartOrderItemFragment } from 'features/cart';
import type { ActiveOrderItemRawData, PaidOrderLine } from 'features/savedQuotes';
import type { SupplierAccount, Vehicle } from 'models';
import type { OrderItemProduct } from 'shared/api';
import type { Quote as QuoteData } from 'types/product';

const getQuoteStatus = (product: Partial<OrderItemProduct>) => {
  const hasAvailable = product.availabilityLines?.some((line) => line.quantity > 0);

  if (hasAvailable && !product.specialOrder) {
    return 'IN_STOCK';
  }

  if (product.backOrderable) {
    return 'BACK_ORDER';
  }

  if (product.specialOrder) {
    return 'SPECIAL_ORDER';
  }

  return 'OUT_OF_STOCK';
};

export const createCartOrderItemFromPaidOrderLine = (line: PaidOrderLine, vehicles: Vehicle[]) =>
  new CartOrderItem({
    id: line.id,
    quantity: line.quantity,
    title: line.partName,
    image: line.image,
    partNumber: line.displayPartNumber || line.partNumber,
    isTire: line.partType?.id === PartType.TIRE_ID,
    tireSize: line.tireSize,
    price: line.price,
    coreCharge: line.coreCharge,
    vehicle: line.vehicle?.id ? vehicles.find((vehicle) => vehicle.id === line.vehicle?.id) : null,
    isPaid: true,
    deliveryNotes: line.etas,
    availabilityType: line.expectedAvailabilityType,
    status: line.status,
  });

const createQuote = (
  part: CartOrderItemFragment | ActiveOrderItemRawData,
  isDealer: boolean,
  storeId?: string,
  accountNickname?: string | null,
  supplierId?: string,
  filterEnhancements: boolean = false
) => {
  const product = part.builtItem?.product;

  if (!product) {
    return null;
  }

  const quoteData: QuoteData = {
    status: getQuoteStatus(product),
    availability: { type: 'AVAILABILITY_DETAILED', lines: [], total: null },
    action: 'HIDDEN',
  };

  const variant = Quote.getVariant(
    product.availabilityLines.map((line) => line.group ?? ''),
    quoteData.status,
    targetSupplierIds.includes(Number(supplierId)),
    filterEnhancements
  );

  const availabilityLines = product.availabilityLines.map((line) =>
    createAvailabilityLineFromQuery(line, storeId, accountNickname, variant)
  );

  return isDealer
    ? new DealerQuote({
        action: quoteData.action,
        status: quoteData.status,
        lines: availabilityLines,
        variant,
      })
    : new Quote({
        action: quoteData.action,
        status: quoteData.status,
        lines: availabilityLines,
        variant,
      });
};

export const createCartOrderItemFromActiveCart = (
  part: CartOrderItemFragment,
  vehicles: Vehicle[],
  supplierAccount?: SupplierAccount | null,
  filterEnhancements?: boolean
) => {
  const position =
    part.builtItem?.product && 'attributes' in part.builtItem.product
      ? part.builtItem.product.attributes.find((attr) => attr.name === 'Position')
      : null;

  const rawAttributes =
    part.builtItem?.product?.attributes.map((raw) => ({
      name: raw.name as AttributeName,
      values: raw.value,
    })) || [];

  const attributes = getCustomAttributesForSection(rawAttributes, 'product');

  const notes = part.builtItem?.product?.notes.flatMap((item) => item.notes) || [];

  const availableBundles = part.builtItem
    ? uniqBy(part.builtItem?.availableBundles, (bundle) => bundle.name.toLowerCase())
    : [];

  return new CartOrderItem({
    id: part.id,
    quantity: part.quantity,
    title: part.partName || '',
    image: part.image || '',
    partNumber: part.builtItem?.product?.partNumberDisplay || part.partNumber,
    isTire: part.partType?.id === PartType.TIRE_ID,
    tireSize: part.tireSize,
    quote: createQuote(
      part,
      Boolean(supplierAccount?.supplier?.isDealer),
      supplierAccount?.store?.id,
      supplierAccount?.nickname,
      supplierAccount?.supplier?.id,
      filterEnhancements
    ),
    price: part.builtItem?.product?.price || 0,
    retailPrice: part.builtItem?.product?.customerPrice || 0,
    coreCharge: part.builtItem?.product?.coreCharge || 0,
    fetCharge: part.builtItem?.product?.fetCharge || 0,
    error: part.builtItem?.errors[0]?.message,
    vehicle: part.vehicle?.id ? vehicles.find((vehicle) => vehicle.id === part.vehicle?.id) : null,
    isPaid: false,
    bundled: part.builtItem?.bundled,
    availableBundles,
    position: position?.value.toString(),
    discount: part.builtItem?.discount || 0,
    mapPrice: part.builtItem?.product?.mapPrice || 0,
    listPrice: part.builtItem?.product?.listPrice || 0,
    supplierId: supplierAccount?.supplier?.id,
    supplierAccountId: supplierAccount?.id,
    sponsorType: part.builtItem?.sponsorType,
    partTypeId: part.partType?.id,
    partTypeName: part.partType?.name,
    categoryName: part.partType?.category?.name,
    origin: part.origin,
    partNumberId: part.partNumberId,
    lineCardId: part.lineCardId,
    brandLogo: part.brand?.logo,
    brandName: part.brand?.name,
    rewardPoint: part.builtItem?.boschReward?.points,
    warnings: part.builtItem?.product?.warnings,
    attributes,
    notes,
    deliveryNotes: part.builtItem?.product?.deliveryNotes || [],
  });
};

export const createCartOrderItemFromActiveOrderItem = (
  part: ActiveOrderItemRawData,
  vehicles: Vehicle[],
  isDealer: boolean,
  storeId?: string,
  accountNickname?: string | null,
  supplierId?: string,
  filterEnhancements?: boolean
) =>
  new CartOrderItem({
    id: part.id,
    quantity: part.quantity,
    title: part.partName || '',
    image: part.image || '',
    partNumber: part.partNumber,
    isTire: part.partType?.id === PartType.TIRE_ID,
    tireSize: part.tireSize,
    quote: createQuote(part, isDealer, storeId, accountNickname, supplierId, filterEnhancements),
    price: part.builtItem?.product?.price || 0,
    retailPrice: part.builtItem?.product?.customerPrice || 0,
    coreCharge: part.builtItem?.product?.coreCharge || 0,
    fetCharge: part.builtItem?.product?.fetCharge || 0,
    error: part.builtItem?.errors[0]?.message,
    vehicle: part.vehicle?.id ? vehicles.find((vehicle) => vehicle.id === part.vehicle?.id) : null,
    isPaid: false,
    bundled: part.builtItem?.bundled,
    deliveryNotes: part.builtItem?.product?.deliveryNotes || [],
    supplierId,
  });
