/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../../shared/api/graphql/graphql';

export type CalculatePackagePriceMutationVariables = Types.Exact<{
  input: Types.CompareCalculatePackagePriceInput;
}>;

export type CalculatePackagePriceMutation = {
  __typename?: 'Mutation';
  compareCalculatePackagePrice?: {
    __typename?: 'CompareCalculatePackagePricePayload';
    calculatedPackagePrice: {
      __typename?: 'CalculatedPackagePrice';
      price: Types.Scalars['BigDecimal']['input'];
      tax: Types.Scalars['BigDecimal']['input'];
      tirePrice: Types.Scalars['BigDecimal']['input'];
      items: Array<
        | {
            __typename?: 'CalculatedAddon';
            name: Types.Scalars['String']['input'];
            description?: Types.Scalars['String']['input'] | null;
            perTirePrice?: Types.Scalars['BigDecimal']['input'] | null;
            taxed: Types.Scalars['Boolean']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            type: 'CalculatedAddon';
          }
        | {
            __typename?: 'CalculatedDiscount';
            name: Types.Scalars['String']['input'];
            description?: Types.Scalars['String']['input'] | null;
            perTirePrice?: Types.Scalars['BigDecimal']['input'] | null;
            taxed: Types.Scalars['Boolean']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            type: 'CalculatedDiscount';
          }
        | {
            __typename?: 'CalculatedDisposal';
            name: Types.Scalars['String']['input'];
            description?: Types.Scalars['String']['input'] | null;
            perTirePrice?: Types.Scalars['BigDecimal']['input'] | null;
            taxed: Types.Scalars['Boolean']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            type: 'CalculatedDisposal';
          }
        | {
            __typename?: 'CalculatedFee';
            name: Types.Scalars['String']['input'];
            description?: Types.Scalars['String']['input'] | null;
            perTirePrice?: Types.Scalars['BigDecimal']['input'] | null;
            taxed: Types.Scalars['Boolean']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            type: 'CalculatedFee';
          }
        | {
            __typename?: 'CalculatedFet';
            name: Types.Scalars['String']['input'];
            description?: Types.Scalars['String']['input'] | null;
            perTirePrice?: Types.Scalars['BigDecimal']['input'] | null;
            taxed: Types.Scalars['Boolean']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            type: 'CalculatedFet';
          }
        | {
            __typename?: 'CalculatedLabor';
            name: Types.Scalars['String']['input'];
            description?: Types.Scalars['String']['input'] | null;
            perTirePrice?: Types.Scalars['BigDecimal']['input'] | null;
            taxed: Types.Scalars['Boolean']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            type: 'CalculatedLabor';
          }
      >;
    };
  } | null;
};

export type CalculatedLaborFieldsFragment = {
  __typename?: 'CalculatedLabor';
  name: Types.Scalars['String']['input'];
  description?: Types.Scalars['String']['input'] | null;
  perTirePrice?: Types.Scalars['BigDecimal']['input'] | null;
  taxed: Types.Scalars['Boolean']['input'];
  price: Types.Scalars['BigDecimal']['input'];
};

export type CalculatedFeeFieldsFragment = {
  __typename?: 'CalculatedFee';
  name: Types.Scalars['String']['input'];
  description?: Types.Scalars['String']['input'] | null;
  perTirePrice?: Types.Scalars['BigDecimal']['input'] | null;
  taxed: Types.Scalars['Boolean']['input'];
  price: Types.Scalars['BigDecimal']['input'];
};

export type CalculatedDisposalFieldsFragment = {
  __typename?: 'CalculatedDisposal';
  name: Types.Scalars['String']['input'];
  description?: Types.Scalars['String']['input'] | null;
  perTirePrice?: Types.Scalars['BigDecimal']['input'] | null;
  taxed: Types.Scalars['Boolean']['input'];
  price: Types.Scalars['BigDecimal']['input'];
};

export type CalculatedAddonFieldsFragment = {
  __typename?: 'CalculatedAddon';
  name: Types.Scalars['String']['input'];
  description?: Types.Scalars['String']['input'] | null;
  perTirePrice?: Types.Scalars['BigDecimal']['input'] | null;
  taxed: Types.Scalars['Boolean']['input'];
  price: Types.Scalars['BigDecimal']['input'];
};

export type CalculatedDiscountFieldsFragment = {
  __typename?: 'CalculatedDiscount';
  name: Types.Scalars['String']['input'];
  description?: Types.Scalars['String']['input'] | null;
  perTirePrice?: Types.Scalars['BigDecimal']['input'] | null;
  taxed: Types.Scalars['Boolean']['input'];
  price: Types.Scalars['BigDecimal']['input'];
};

export type CalculatedFetFieldsFragment = {
  __typename?: 'CalculatedFet';
  name: Types.Scalars['String']['input'];
  description?: Types.Scalars['String']['input'] | null;
  perTirePrice?: Types.Scalars['BigDecimal']['input'] | null;
  taxed: Types.Scalars['Boolean']['input'];
  price: Types.Scalars['BigDecimal']['input'];
};

export const CalculatedLaborFieldsFragmentDoc = `
    fragment CalculatedLaborFields on CalculatedLabor {
  name
  description
  perTirePrice
  taxed
  price
}
    `;
export const CalculatedFeeFieldsFragmentDoc = `
    fragment CalculatedFeeFields on CalculatedFee {
  name
  description
  perTirePrice
  taxed
  price
}
    `;
export const CalculatedDisposalFieldsFragmentDoc = `
    fragment CalculatedDisposalFields on CalculatedDisposal {
  name
  description
  perTirePrice
  taxed
  price
}
    `;
export const CalculatedAddonFieldsFragmentDoc = `
    fragment CalculatedAddonFields on CalculatedAddon {
  name
  description
  perTirePrice
  taxed
  price
}
    `;
export const CalculatedDiscountFieldsFragmentDoc = `
    fragment CalculatedDiscountFields on CalculatedDiscount {
  name
  description
  perTirePrice
  taxed
  price
}
    `;
export const CalculatedFetFieldsFragmentDoc = `
    fragment CalculatedFetFields on CalculatedFet {
  name
  description
  perTirePrice
  taxed
  price
}
    `;
export const CalculatePackagePriceDocument = `
    mutation CalculatePackagePrice($input: CompareCalculatePackagePriceInput!) {
  compareCalculatePackagePrice(input: $input) {
    calculatedPackagePrice {
      price
      tax
      tirePrice
      items {
        type: __typename
        ...CalculatedLaborFields
        ...CalculatedFeeFields
        ...CalculatedDisposalFields
        ...CalculatedAddonFields
        ...CalculatedDiscountFields
        ...CalculatedFetFields
      }
    }
  }
}
    ${CalculatedLaborFieldsFragmentDoc}
${CalculatedFeeFieldsFragmentDoc}
${CalculatedDisposalFieldsFragmentDoc}
${CalculatedAddonFieldsFragmentDoc}
${CalculatedDiscountFieldsFragmentDoc}
${CalculatedFetFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CalculatePackagePrice: build.mutation<CalculatePackagePriceMutation, CalculatePackagePriceMutationVariables>({
      query: (variables) => ({ document: CalculatePackagePriceDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCalculatePackagePriceMutation } = injectedRtkApi;
