import { css } from '@emotion/react';
import { Box, Icon, ImageFallback, Typography } from '@partstech/ui';
import { useCallback } from 'react';
import { Carousel } from 'components/Carousel';
import synchronizeIcon from 'images/icons/synchronize.svg';
import type { Theme } from '@partstech/ui';
import type { ProductFile } from 'models';

const styles = {
  carouselButton: (isActive: boolean) => (theme: Theme) => css`
    overflow: hidden;
    cursor: pointer;
    border-radius: ${theme.sizing(1)};
    border: 1px solid ${theme.color.mono40};

    .icon {
      position: absolute;
      top: ${theme.sizing(4)};
      left: ${theme.sizing(9)};
      font-size: ${theme.sizing(9)};
    }

    .carousel-image {
      max-width: ${theme.sizing(25)};
      max-height: ${theme.sizing(14)};
      opacity: ${isActive ? 1 : 0.3};
      transition: all 0.3s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  `,
  button: (theme: Theme) => css`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${theme.sizing(17)};
    margin-left: ${theme.sizing(2)};
    border-radius: ${theme.sizing(1)};
    border: 1px solid ${theme.color.mono17};

    &:hover {
      background-color: ${theme.color.mono6};
    }
  `,
};

type Props = {
  files: ProductFile[];
  has360Images?: boolean;
  currentIndex: number;
  onClick?: (index: number, view360?: boolean) => void;
  alt?: string;
  width?: number;
  height?: number;
};

export const FilesCarousel = ({
  files,
  has360Images,
  currentIndex = 0,
  onClick,
  alt,
  width = 25,
  height = 15,
}: Props) => {
  const handleClick = useCallback(
    (index: number, view360?: boolean) => () => {
      if (onClick) {
        onClick(index, view360);
      }
    },
    [onClick]
  );

  return (
    <Box mb={2} position="relative">
      <Carousel
        filesCount={has360Images ? files.length + 1 : files.length}
        current={currentIndex}
        isHiddenControls
        isControlCurrentSlide
      >
        {files.map((file, index) => (
          <Box key={index} pr={files.length - 1 === index ? 0 : 2} data-testid="carouselItem">
            <Box
              width={width}
              height={height}
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={1}
              css={styles.carouselButton(currentIndex === index)}
              onClick={handleClick(index)}
            >
              {file.isVideo && <Icon name="videocam" color="secondary" />}

              <ImageFallback
                className="carousel-image"
                src={file.preview}
                alt={`${alt || 'preview'} #${index + 1}`}
                data-testid="image"
              />
            </Box>
          </Box>
        ))}

        {has360Images && (
          <button
            css={styles.button}
            onClick={handleClick(files.length, true)}
            type="button"
            data-testid="carouselItem"
          >
            <img src={synchronizeIcon} alt="sync_icon" />
            <Typography variant="caption" color="primary">
              View 360
            </Typography>
          </button>
        )}
      </Carousel>
    </Box>
  );
};
