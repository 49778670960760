import { css } from '@emotion/react';
import { Typography } from '@partstech/ui';
import { plural } from '@partstech/ui/utils';
import { useMemo } from 'react';

const styles = {
  info: css`
    word-break: break-word;
  `,
};

type Props = {
  partsCount: number;
  repairOrderNumber: string;
};

export const CartInfo = ({ partsCount, repairOrderNumber }: Props) => {
  const countLabel = useMemo(() => {
    if (partsCount === 0) {
      return 'No items';
    }

    return `${partsCount} ${plural('item', partsCount)}`;
  }, [partsCount]);

  return (
    <div>
      <Typography color={repairOrderNumber ? 'defaultText' : 'mono40'} css={styles.info}>
        {repairOrderNumber || 'No info'}
      </Typography>
      <Typography component="p" variant="caption" color="subtleText">
        {countLabel}
      </Typography>
    </div>
  );
};
