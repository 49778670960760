import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'hooks/search';
import { useSearchBar } from '../context/SearchBarContext';

type Props = {
  onSearchEntryRequired: () => void;
  onVehicleRequired: () => void;
};

export const useSearchBarStateValidation = ({ onSearchEntryRequired, onVehicleRequired }: Props) => {
  const { searchEntry, vehicle: selectedVehicle } = useSearchBar();
  const { hasValidationInSearchParams, isReady, vehicleId } = useSearchParams();

  const shouldValidate = hasValidationInSearchParams && isReady;

  const isVehicleRequired = useMemo(() => {
    if (!hasValidationInSearchParams) {
      return false;
    }

    return (
      (searchEntry?.isVehicleRequired?.() && !vehicleId && !selectedVehicle) ||
      (!searchEntry && !vehicleId && !selectedVehicle)
    );
  }, [hasValidationInSearchParams, searchEntry, selectedVehicle, vehicleId]);

  const isSearchEntryRequired = shouldValidate && !searchEntry;

  useEffect(() => {
    if (!shouldValidate) {
      return;
    }

    if (isVehicleRequired) {
      onVehicleRequired();
      return;
    }

    if (isSearchEntryRequired && !isVehicleRequired) {
      onSearchEntryRequired();
    }
  }, [isSearchEntryRequired, isVehicleRequired, onSearchEntryRequired, onVehicleRequired, shouldValidate]);
};
