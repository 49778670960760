import type { ProductImage, ProductVideo } from 'shared/api';

export type ProductFile = (ProductVideo | ProductImage) & { isVideo: boolean };

export class Media {
  images: ProductImage[];

  image360Groups: ProductImage[][];

  videos: ProductVideo[] = [];

  constructor({ images, image360Groups, videos }: Pick<Media, 'images' | 'image360Groups' | 'videos'>) {
    this.images = images;
    this.image360Groups = image360Groups;
    this.videos = videos;
  }

  extractImages() {
    if (this.images.length === 0) {
      return [];
    }

    return this.images;
  }

  extractFiles(): ProductFile[] {
    const images = this.extractImages().map((image) => ({ ...image, isVideo: false }));
    const videos = this.videos.length === 0 ? [] : this.videos;
    const modifiedVideos = videos.map((video) => ({ ...video, isVideo: true }));

    return [...images, ...modifiedVideos];
  }

  extract360Images() {
    if (this.image360Groups.length === 0) {
      return [];
    }

    return this.image360Groups.flatMap((imageGroup) => imageGroup.map((image) => image.full ?? '')).reverse();
  }

  getFirstImage() {
    const images = this.extractImages();

    return images[0];
  }

  hasImages360Gropus() {
    return this.image360Groups.length > 0;
  }
}
