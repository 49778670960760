import { isNumber } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useSupplierAccounts } from 'hooks/supplierAccounts';
import { AccountStatus } from 'shared/api';
import { api as generatedApi } from './GetStockOrders.generated';
import type { GetStockOrdersQuery } from './GetStockOrders.generated';
import type { InitialStockOrder } from 'features/stockOrders';

export type RawStockOrder = NonNullable<GetStockOrdersQuery['stockOrder']>;

const emptyParts: RawStockOrder['parts'] = [];

const api = generatedApi.enhanceEndpoints({ addTagTypes: ['StockOrders'] });

export const { useGetStockOrdersQuery } = api;

export const useGetStockOrders = () => {
  const { accounts, isFetching: isAccountsFetching } = useSupplierAccounts({
    isStockOrdersAllowed: true,
    status: AccountStatus.Approved,
  });

  const { data, isLoading, isUninitialized } = useGetStockOrdersQuery(undefined, { skip: isAccountsFetching });

  const commonSupplierId = data?.stockOrder?.supplier?.id;
  const commonStoreId = data?.stockOrder?.store?.id;
  const splittedByLastPurchases = data?.stockOrder?.splitByLastPurchases || false;
  const parts = data?.stockOrder?.parts || emptyParts;

  const commonSupplierAccount = useMemo(
    () =>
      accounts.find((entity) => entity.store?.id === commonStoreId) ||
      accounts.find((entity) => entity.supplier?.id === String(commonSupplierId)) ||
      accounts.find((entity) => !entity.supplier?.isTire && entity.isFirstCall),
    [accounts, commonStoreId, commonSupplierId]
  );

  const stockOrders = useMemo(() => {
    const stockOrdersMap = parts.reduce<Record<number, InitialStockOrder>>((acc, part) => {
      const { quantity, partNumber, lineCardId, partNumberId, selectedIndex } = part;
      const accountId = part.account?.id;
      const supplierId = part.supplier?.id;
      const storeId = part.store?.id;
      const manufacturerId = part.brand?.id;
      const brandName = part.brand?.name;

      const foundSupplierAccount =
        accounts.find((entity) => entity.id === accountId) ||
        accounts.find((entity) => entity.store?.id === storeId) ||
        accounts.find((entity) => entity.supplier?.id === supplierId);

      const supplierAccountId = Number(foundSupplierAccount?.id || commonSupplierAccount?.id || accounts[0]?.id || 0);

      const stockOrder: InitialStockOrder = acc[supplierAccountId] || {
        id: String(supplierAccountId),
        supplierAccount: foundSupplierAccount || commonSupplierAccount || accounts[0] || null,
        typeahead: [],
        shouldStartSearch: true,
        lines: [],
      };

      stockOrder.lines.push({
        quantity,
        searchQuery: partNumber,
        productIds: [],
        manufacturerId,
        lineCardId,
        partNumberId,
        selectedIndex: isNumber(selectedIndex) ? selectedIndex : null,
        brandName,
      });

      return { ...acc, [supplierAccountId]: stockOrder };
    }, {});

    return Object.values(stockOrdersMap);
  }, [accounts, commonSupplierAccount, parts]);

  return {
    stockOrders,
    isLoading: isAccountsFetching || isLoading,
    splittedByLastPurchases,
    isUninitialized,
  };
};
