/* eslint-disable */ /**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigDecimal: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: string; output: string; }
  JSON: { input: any; output: any; }
  LocalTime: { input: any; output: any; }
  Time: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Url: { input: any; output: any; }
  _FieldSet: { input: any; output: any; }
};

export type AcceptInvitationInput = {
  token: Scalars['String']['input'];
};

export type AcceptInvitationPayload = {
  __typename?: 'AcceptInvitationPayload';
  id?: Maybe<Scalars['ID']['output']>;
};

export type Account = {
  __typename?: 'Account';
  active: Scalars['Boolean']['output'];
  additionalInformation: Array<AccountField>;
  credentials: Array<AccountField>;
  customerDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  priority: Scalars['Int']['output'];
  status: AccountStatus;
  store?: Maybe<Store>;
  supplier?: Maybe<Supplier>;
};

export type AccountCredentialsInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type AccountField = {
  __typename?: 'AccountField';
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type AccountPriority = {
  accountId: Scalars['ID']['input'];
  priority: Scalars['Int']['input'];
};

export enum AccountStatus {
  Approved = 'APPROVED',
  HelpRequested = 'HELP_REQUESTED',
  NotApproved = 'NOT_APPROVED',
  PartstechHelpRequested = 'PARTSTECH_HELP_REQUESTED',
  PaymentPending = 'PAYMENT_PENDING',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  ReadyForReview = 'READY_FOR_REVIEW',
  SupplierApprovalNeeded = 'SUPPLIER_APPROVAL_NEEDED'
}

export enum ActivateCartError {
  /** Cart is archived. */
  ArchivedCart = 'ARCHIVED_CART',
  /** Cart is ordered. */
  OrderedCart = 'ORDERED_CART',
  /** User is in punchout mode. We forbid to toggle cart in punchout mode. */
  PunchoutMode = 'PUNCHOUT_MODE',
  /** Cart is punchout quote. We forbid to activate punchout cart. */
  PunchoutQuote = 'PUNCHOUT_QUOTE'
}

export type ActivateCartInput = {
  /** Cart ID. */
  id: Scalars['ID']['input'];
};

export type ActivateCartPayload = {
  __typename?: 'ActivateCartPayload';
  /** Activated cart. */
  cart?: Maybe<Cart>;
  /** Error during activating cart. */
  error?: Maybe<ActivateCartError>;
};

export enum AddItemError {
  /** Provided account is not approved. */
  AccountNotApproved = 'ACCOUNT_NOT_APPROVED',
  /** Provided account was not found, deleted or disabled. */
  AccountNotFound = 'ACCOUNT_NOT_FOUND',
  /** Item is not available. */
  Availability = 'AVAILABILITY',
  /** "Item cannot be bought. */
  DisplayAction = 'DISPLAY_ACTION',
  /** Integration error. Supplier rejects item and order. */
  Integration = 'INTEGRATION',
  /** Internal error. */
  InternalError = 'INTERNAL_ERROR',
  /** Request has invalid data. */
  InvalidRequest = 'INVALID_REQUEST',
  /** Supplier is in outage. */
  Outage = 'OUTAGE',
  /** Outdated. */
  Outdated = 'OUTDATED',
  /** Item is out of stock. */
  OutOfStock = 'OUT_OF_STOCK',
  /** Provided quantity is more than available. */
  Quantity = 'QUANTITY',
  /** Quantity does not match quantity step. */
  QuantityStep = 'QUANTITY_STEP',
  /** Item is special order. */
  SpecialOrder = 'SPECIAL_ORDER'
}

export type AddItemToActiveCartErrorPayload = {
  __typename?: 'AddItemToActiveCartErrorPayload';
  /** Error type. */
  error: AddItemError;
  /** Error message. */
  errorMessage: Scalars['String']['output'];
};

export type AddItemToActiveCartInput = {
  /** Item parameters to add in the active cart. */
  item: ItemParametersInput;
};

export type AddItemToActiveCartPayload = AddItemToActiveCartErrorPayload | AddItemToActiveCartSuccessPayload;

export type AddItemToActiveCartResult = {
  __typename?: 'AddItemToActiveCartResult';
  /** Part number id of item. */
  partNumberId: Scalars['String']['output'];
  /** Result of adding. */
  result: AddItemToActiveCartPayload;
};

export type AddItemToActiveCartSuccessPayload = {
  __typename?: 'AddItemToActiveCartSuccessPayload';
  /** ID of new/existent order where item was added/updated. */
  orderId: Scalars['ID']['output'];
  /** "ID of item that was added or updated. */
  orderItemId: Scalars['ID']['output'];
};

export enum AddLaborItemToActiveCartError {
  /** Cart not found. */
  CartNotFound = 'CART_NOT_FOUND',
  /** Internal error. */
  InternalError = 'INTERNAL_ERROR',
  /** Order item not found. */
  OrderItemNotFound = 'ORDER_ITEM_NOT_FOUND'
}

export type AddLaborItemToActiveCartErrorPayload = {
  __typename?: 'AddLaborItemToActiveCartErrorPayload';
  /** Error type. */
  error: AddLaborItemToActiveCartError;
  /** Error message. */
  errorMessage: Scalars['String']['output'];
};

export type AddLaborItemToActiveCartInput = {
  /** Default hours provided by labor source. */
  defaultHours?: InputMaybe<Scalars['BigDecimal']['input']>;
  /** Hours. */
  hours: Scalars['BigDecimal']['input'];
  mitchell1Identifiers?: InputMaybe<Mitchell1SourceInput>;
  /** Identifiers. */
  motorIdentifiers?: InputMaybe<MotorSourceInput>;
  /** Labor name. */
  name: Scalars['String']['input'];
  /** Notes. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Order item ID. */
  orderItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Part type ID. */
  partTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Labor Skill. */
  skill: Scalars['String']['input'];
  /** Vehicle ID. */
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
  /** VIN. */
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type AddLaborItemToActiveCartPayload = AddLaborItemToActiveCartErrorPayload | AddLaborItemToActiveCartSuccessPayload;

export type AddLaborItemToActiveCartSuccessPayload = {
  __typename?: 'AddLaborItemToActiveCartSuccessPayload';
  /** New cart labor item. */
  cartLaborItem: CartLaborItem;
};

export type AddShopAccountInput = {
  /** Additional information for account. */
  additionalInformation: Array<AdditionalInformationAccountInput>;
  /** Account credentials. */
  credentials: Array<AccountCredentialsInput>;
  /** Account nickname. */
  nickname?: InputMaybe<Scalars['String']['input']>;
  /** Store id. */
  storeId: Scalars['ID']['input'];
  /** Supplier id. */
  supplierId: Scalars['ID']['input'];
};

export type AddShopAccountPayload = {
  __typename?: 'AddShopAccountPayload';
  /** Added account. */
  account?: Maybe<Account>;
};

export enum AdditionalDetail {
  MultiplePeople = 'MULTIPLE_PEOPLE',
  OnePerson = 'ONE_PERSON',
  Parts = 'PARTS',
  Tires = 'TIRES'
}

export type AdditionalFieldValue = {
  /** Name of the field. */
  name: Scalars['String']['input'];
  /** Selected option or entered value. */
  value: Scalars['String']['input'];
};

export type AdditionalInformation = {
  __typename?: 'AdditionalInformation';
  description: Scalars['String']['output'];
  fields: Array<ConfigurationField>;
  title: Scalars['String']['output'];
};

export type AdditionalInformationAccountInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Addon = {
  __typename?: 'Addon';
  applyTax: Scalars['Boolean']['output'];
  calculatedBy: Array<AddonCalculatedBy>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  perTire: Scalars['Boolean']['output'];
  percentage: Scalars['Boolean']['output'];
  percentageAmount?: Maybe<PercentageAmount>;
  price: Scalars['BigDecimal']['output'];
};

export enum AddonCalculatedBy {
  Disposal = 'DISPOSAL',
  Labor = 'LABOR',
  Tires = 'TIRES'
}

export type AddonInput = {
  applyTax: Scalars['Boolean']['input'];
  calculatedBy: Array<AddonCalculatedBy>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  perTire: Scalars['Boolean']['input'];
  percentage: Scalars['Boolean']['input'];
  percentageAmount?: InputMaybe<PercentageAmountInput>;
  price: Scalars['BigDecimal']['input'];
};

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Country;
  state: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type AddressInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Country;
  state: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export enum AdvertisingSegment {
  A = 'A',
  B = 'B'
}

export type AppConfig = {
  __typename?: 'AppConfig';
  googleOauthClientId: Scalars['String']['output'];
  reCaptchaKey: Scalars['String']['output'];
  stripeKey: Scalars['String']['output'];
};

export type ApplicationGroup = {
  __typename?: 'ApplicationGroup';
  additionalProcedures: Array<ProcedureInformation>;
  applicationGroupName: Scalars['String']['output'];
  combinationProcedures: Array<ProcedureInformation>;
  items: Array<Mitchell1LaborItem>;
  procedureNote?: Maybe<Scalars['String']['output']>;
};

export type ApplyTireQuoteInput = {
  hash: Scalars['ID']['input'];
  label: TireQuoteLabel;
};

export type ApplyTireQuotePayload = {
  __typename?: 'ApplyTireQuotePayload';
  hash: Scalars['ID']['output'];
  label: TireQuoteLabel;
};

export enum ArchiveCartError {
  /** Cart is active and cannot be archived. */
  ActiveCart = 'ACTIVE_CART'
}

export type ArchiveCartInput = {
  /** Cart ID. */
  id: Scalars['ID']['input'];
};

export type ArchiveCartPayload = {
  __typename?: 'ArchiveCartPayload';
  /** Result cart. */
  cart?: Maybe<Cart>;
  /** Error during archiving cart. */
  error?: Maybe<ArchiveCartError>;
};

export type Attribute = {
  __typename?: 'Attribute';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type AttributeInput = {
  name: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type AvailableAdditionalFieldOption = {
  __typename?: 'AvailableAdditionalFieldOption';
  text: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AvailableAdditionalFields = {
  __typename?: 'AvailableAdditionalFields';
  default?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  options?: Maybe<Array<AvailableAdditionalFieldOption>>;
  required: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type AvailableBundle = {
  __typename?: 'AvailableBundle';
  description?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type BatchAddItemsToActiveCartInput = {
  /** List of item parameters to add in the active cart. */
  items: Array<ItemParametersInput>;
};

export type BatchAddItemsToActiveCartPayload = {
  __typename?: 'BatchAddItemsToActiveCartPayload';
  /** List of results. */
  results: Array<AddItemToActiveCartResult>;
};

export type BatchAddLaborItemsToActiveCartInput = {
  /** List of items to add in the active cart. */
  items: Array<AddLaborItemToActiveCartInput>;
};

export type BatchAddLaborItemsToActiveCartPayload = {
  __typename?: 'BatchAddLaborItemsToActiveCartPayload';
  /** Error during adding labor items to the active cart. */
  error?: Maybe<AddLaborItemToActiveCartErrorPayload>;
  /** Result of adding. */
  items?: Maybe<Array<CartLaborItem>>;
};

export type BatchDeleteActiveCartLaborItemInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type BatchMitchell1LaborInput = {
  labors: Array<Mitchell1LaborInput>;
};

export type BatchMitchell1LaborPayload = {
  __typename?: 'BatchMitchell1LaborPayload';
  labors: Array<Mitchell1Labor>;
};

export type BatchUpdateActiveCartLaborItemsInput = {
  /** List of items to update in the active cart. */
  items: Array<UpdateActiveCartLaborItemInput>;
};

export type BatchUpdateActiveCartLaborItemsPayload = {
  __typename?: 'BatchUpdateActiveCartLaborItemsPayload';
  /** Error during adding labor items to the active cart. */
  error?: Maybe<UpdateActiveCartLaborItemErrorPayload>;
  /** Result of updating. */
  items?: Maybe<Array<CartLaborItem>>;
};

export type BatchWorkTimesSummariesPayload = {
  __typename?: 'BatchWorkTimesSummariesPayload';
  workTimesSummaries: Array<WorkTimesSummary>;
};

export type BatchWorkTimesSummaryInput = {
  workTimeSummaries: Array<WorkTimesSummaryInput>;
};

export type BoschAccount = {
  __typename?: 'BoschAccount';
  balance?: Maybe<BoschAccountBalanceResult>;
  status: BoschAccountStatus;
};

export type BoschAccountBalance = {
  __typename?: 'BoschAccountBalance';
  expireDate?: Maybe<Scalars['DateTime']['output']>;
  expirePoints: Scalars['Int']['output'];
  points: Scalars['Int']['output'];
};

export type BoschAccountBalanceError = {
  __typename?: 'BoschAccountBalanceError';
  errorType: BoschAccountBalanceErrorType;
  message: Scalars['String']['output'];
};

export enum BoschAccountBalanceErrorType {
  CustomerError = 'CUSTOMER_ERROR',
  ServiceUnavailable = 'SERVICE_UNAVAILABLE'
}

export type BoschAccountBalanceResult = BoschAccountBalance | BoschAccountBalanceError;

export enum BoschAccountStatus {
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export type Brand = {
  __typename?: 'Brand';
  copyright?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lineCode?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type BuiltItem = {
  __typename?: 'BuiltItem';
  availableBundles: Array<AvailableBundle>;
  availableMotorLaborApplications?: Maybe<Array<CartLabor>>;
  boschReward?: Maybe<OrderBoschReward>;
  bundled: Scalars['Boolean']['output'];
  cartOrderItemId: Scalars['ID']['output'];
  discount: Scalars['Float']['output'];
  errors: Array<OrderItemError>;
  product?: Maybe<OrderItemProduct>;
  sponsorType: OrderItemSponsorType;
  sponsoredPurchasesCount?: Maybe<Scalars['Int']['output']>;
};

export type BuiltOrder = {
  __typename?: 'BuiltOrder';
  availableAdditionalFields?: Maybe<Array<AvailableAdditionalFields>>;
  availableFreightTerms: Array<FreightTerm>;
  boschReward?: Maybe<OrderBoschReward>;
  coreChargeTotal: Scalars['Float']['output'];
  customNotesValidation?: Maybe<OrderFieldValidation>;
  discountTotal: Scalars['Float']['output'];
  errors: Array<OrderError>;
  fetChargeTotal: Scalars['Float']['output'];
  paymentWay: OrderPaymentWay;
  priceTotal: Scalars['Float']['output'];
  purchaseOrderNumberValidation?: Maybe<OrderFieldValidation>;
  selectedFrightTermService?: Maybe<FreightTermService>;
  shippingTotal: Scalars['Float']['output'];
  tax: Scalars['Float']['output'];
};

export type Bundle = {
  __typename?: 'Bundle';
  description?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum BuyActiveCartError {
  ApplyParamsErrors = 'APPLY_PARAMS_ERRORS',
  EmptyCart = 'EMPTY_CART',
  Inconsistent = 'INCONSISTENT',
  NoActiveCart = 'NO_ACTIVE_CART'
}

export type BuyActiveCartErrorPayload = {
  __typename?: 'BuyActiveCartErrorPayload';
  buyOrderErrors?: Maybe<Array<BuyOrderErrorPayload>>;
  error: BuyActiveCartError;
  errorMessage: Scalars['String']['output'];
};

export type BuyActiveCartFailedPayload = {
  __typename?: 'BuyActiveCartFailedPayload';
  failedOrders: Array<CartOrder>;
};

export type BuyActiveCartInput = {
  laborItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  orders: Array<BuyOrderInput>;
  submittedLabor?: InputMaybe<Array<SubmittedLabor>>;
};

export type BuyActiveCartMitchell1Payload = {
  __typename?: 'BuyActiveCartMitchell1Payload';
  labors?: Maybe<Array<Mitchell1SubmittedLabor>>;
  notes?: Maybe<Array<Mitchell1SubmittedNote>>;
  orders: Array<Mitchell1Order>;
};

export type BuyActiveCartPayload = BuyActiveCartErrorPayload | BuyActiveCartFailedPayload | BuyActiveCartSuccessPayload;

export type BuyActiveCartSuccessPayload = {
  __typename?: 'BuyActiveCartSuccessPayload';
  failedCallback?: Maybe<Scalars['Boolean']['output']>;
  mitchell1Payload?: Maybe<BuyActiveCartMitchell1Payload>;
  orders: Array<CartOrder>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export type BuyItemInput = {
  itemId: Scalars['ID']['input'];
  supplierNotes?: InputMaybe<Scalars['String']['input']>;
};

export enum BuyOrderError {
  IncorrectShippingMethod = 'INCORRECT_SHIPPING_METHOD',
  Integration = 'INTEGRATION',
  Outage = 'OUTAGE',
  Validation = 'VALIDATION'
}

export type BuyOrderErrorPayload = {
  __typename?: 'BuyOrderErrorPayload';
  error: BuyOrderError;
  errorMessage: Scalars['String']['output'];
  /** Field validation errors. */
  fieldValidationErrors?: Maybe<Array<OrderFieldValidationError>>;
  orderId: Scalars['ID']['output'];
};

export type BuyOrderInput = {
  customNotes?: InputMaybe<Scalars['String']['input']>;
  items: Array<BuyItemInput>;
  orderId: Scalars['ID']['input'];
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>;
  shippingMethodCode: Scalars['String']['input'];
};

export type CalculatedAddon = {
  __typename?: 'CalculatedAddon';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  perTirePrice?: Maybe<Scalars['BigDecimal']['output']>;
  price: Scalars['BigDecimal']['output'];
  taxed: Scalars['Boolean']['output'];
};

export type CalculatedDiscount = {
  __typename?: 'CalculatedDiscount';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  perTirePrice?: Maybe<Scalars['BigDecimal']['output']>;
  price: Scalars['BigDecimal']['output'];
  taxed: Scalars['Boolean']['output'];
};

export type CalculatedDisposal = {
  __typename?: 'CalculatedDisposal';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  perTirePrice?: Maybe<Scalars['BigDecimal']['output']>;
  price: Scalars['BigDecimal']['output'];
  taxed: Scalars['Boolean']['output'];
};

export type CalculatedFee = {
  __typename?: 'CalculatedFee';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  perTirePrice?: Maybe<Scalars['BigDecimal']['output']>;
  price: Scalars['BigDecimal']['output'];
  taxed: Scalars['Boolean']['output'];
};

export type CalculatedFet = {
  __typename?: 'CalculatedFet';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  perTirePrice?: Maybe<Scalars['BigDecimal']['output']>;
  price: Scalars['BigDecimal']['output'];
  taxed: Scalars['Boolean']['output'];
};

export type CalculatedItem = CalculatedAddon | CalculatedDiscount | CalculatedDisposal | CalculatedFee | CalculatedFet | CalculatedLabor;

export type CalculatedLabor = {
  __typename?: 'CalculatedLabor';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  perTirePrice?: Maybe<Scalars['BigDecimal']['output']>;
  price: Scalars['BigDecimal']['output'];
  taxed: Scalars['Boolean']['output'];
};

export type CalculatedPackagePrice = {
  __typename?: 'CalculatedPackagePrice';
  items: Array<CalculatedItem>;
  price: Scalars['BigDecimal']['output'];
  tax: Scalars['BigDecimal']['output'];
  tirePrice: Scalars['BigDecimal']['output'];
};

export type CancelInvitationInput = {
  id: Scalars['ID']['input'];
};

export type CancelInvitationPayload = {
  __typename?: 'CancelInvitationPayload';
  id?: Maybe<Scalars['ID']['output']>;
};

export type Capacity = {
  __typename?: 'Capacity';
  items: Array<CapacityItem>;
  name: Scalars['String']['output'];
};

export type CapacityItem = {
  __typename?: 'CapacityItem';
  extendedParams: Array<ExtendedParameters>;
  itemNotes: Array<Scalars['String']['output']>;
  maxValue?: Maybe<Scalars['String']['output']>;
  minValue?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Card = {
  __typename?: 'Card';
  brand: Scalars['String']['output'];
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner?: Maybe<Scalars['String']['output']>;
};

export type Cart = {
  __typename?: 'Cart';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerEmail?: Maybe<Scalars['String']['output']>;
  demo: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  improvedStatus: CartImprovedStatus;
  laborItems?: Maybe<Array<CartLaborItem>>;
  laborRate?: Maybe<Scalars['Float']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  /** Load cart order by id. */
  order?: Maybe<CartOrder>;
  /** Load cart orders by id. */
  orderByIds?: Maybe<Array<CartOrder>>;
  orderedLaborItems?: Maybe<Array<CartLaborItem>>;
  orders?: Maybe<Array<CartOrder>>;
  paidOrders?: Maybe<Array<Order>>;
  quoteType: CartQuoteType;
  repairOrderNumber?: Maybe<Scalars['String']['output']>;
  showRetailPricing: Scalars['Boolean']['output'];
  status: CartStatus;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  vehicles?: Maybe<Array<CartLinkedVehicle>>;
};


export type CartOrderArgs = {
  id: Scalars['ID']['input'];
};


export type CartOrderByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type CartConnection = {
  __typename?: 'CartConnection';
  edges?: Maybe<Array<CartEdge>>;
  pageInfo: PageInfo;
};

export type CartEdge = {
  __typename?: 'CartEdge';
  cursor: Scalars['String']['output'];
  node: Cart;
};

export enum CartImprovedStatus {
  Approved = 'APPROVED',
  Archived = 'ARCHIVED',
  Created = 'CREATED',
  Ordered = 'ORDERED',
  Shared = 'SHARED',
  Submitted = 'SUBMITTED'
}

export type CartLabor = {
  __typename?: 'CartLabor';
  attributes?: Maybe<Array<Attribute>>;
  baseLaborTimeDescription?: Maybe<Scalars['String']['output']>;
  descriptions?: Maybe<Array<Scalars['String']['output']>>;
  duration: Scalars['BigDecimal']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<Scalars['String']['output']>>;
  parentId?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Position>;
  skill: Scalars['String']['output'];
  skillCode: Scalars['String']['output'];
  taxonomyName: Scalars['String']['output'];
};

export type CartLaborItem = {
  __typename?: 'CartLaborItem';
  defaultHours: Scalars['Float']['output'];
  hours: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  identifiers?: Maybe<CartLaborItemIdentifiers>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  orderGroupId: Scalars['ID']['output'];
  orderItemId?: Maybe<Scalars['ID']['output']>;
  partType?: Maybe<PartType>;
  skill: Scalars['String']['output'];
  source?: Maybe<CartLaborItemSource>;
  vehicle?: Maybe<Vehicle>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type CartLaborItemIdentifiers = Mitchell1Identifiers | MotorIdentifiers;

export enum CartLaborItemSource {
  Manual = 'MANUAL',
  Mitchell1 = 'MITCHELL1',
  Motor = 'MOTOR'
}

export enum CartLaborItemStatus {
  New = 'NEW',
  Ordered = 'ORDERED'
}

export type CartLinkedVehicle = {
  __typename?: 'CartLinkedVehicle';
  id: Scalars['ID']['output'];
  source: CartLinkedVehicleSource;
  vehicle?: Maybe<Vehicle>;
  vin?: Maybe<Scalars['String']['output']>;
};

export enum CartLinkedVehicleSource {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

export type CartOrder = {
  __typename?: 'CartOrder';
  account?: Maybe<Account>;
  additionalFields?: Maybe<Array<OrderAdditionalField>>;
  billingAddress: CartOrderAddress;
  builtOrder?: Maybe<BuiltOrder>;
  customNotes?: Maybe<Scalars['String']['output']>;
  demo: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<CartOrderItem>>;
  optionsPreferences?: Maybe<SupplierOptionsPreferences>;
  purchaseOrderNumber?: Maybe<Scalars['String']['output']>;
  shipToId?: Maybe<Scalars['String']['output']>;
  shippingAddress: CartOrderAddress;
  store?: Maybe<Store>;
  supplier?: Maybe<Supplier>;
};

export type CartOrderAddress = {
  __typename?: 'CartOrderAddress';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Country;
  state: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type CartOrderItem = {
  __typename?: 'CartOrderItem';
  availabilityBranch?: Maybe<Scalars['String']['output']>;
  backOrderQuantity: Scalars['Int']['output'];
  brand?: Maybe<Brand>;
  builtItem?: Maybe<BuiltItem>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  laborItems?: Maybe<Array<CartLaborItem>>;
  lineCardId?: Maybe<Scalars['Int']['output']>;
  origin: CartOrderItemOrigin;
  partName: Scalars['String']['output'];
  partNumber: Scalars['String']['output'];
  partNumberId: Scalars['String']['output'];
  partType?: Maybe<PartType>;
  quantity: Scalars['Int']['output'];
  supplierNotes?: Maybe<Scalars['String']['output']>;
  tireSize?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<Vehicle>;
  vin?: Maybe<Scalars['String']['output']>;
};

export enum CartOrderItemOrigin {
  Api = 'API',
  Search = 'SEARCH',
  StockOrder = 'STOCK_ORDER'
}

export enum CartQuoteType {
  DirectOrder = 'DIRECT_ORDER',
  Punchout = 'PUNCHOUT',
  Regular = 'REGULAR'
}

export enum CartStatus {
  Closed = 'CLOSED',
  New = 'NEW',
  PunchoutPending = 'PUNCHOUT_PENDING'
}

export enum CartStatusFilter {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Created = 'CREATED',
  Ordered = 'ORDERED',
  Submitted = 'SUBMITTED'
}

export type CatalogCategorySearchInput = {
  categoryId: Scalars['ID']['input'];
  subCategoryId?: InputMaybe<Scalars['ID']['input']>;
  vehicleId: Scalars['ID']['input'];
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type CatalogKeywordSearchInput = {
  keyword: Scalars['String']['input'];
  vehicleId: Scalars['ID']['input'];
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type CatalogPartNumberSearchInput = {
  partNumber: Scalars['String']['input'];
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
};

export type CatalogPartTypeAttributeSearchInput = {
  attributes?: InputMaybe<Array<AttributeInput>>;
  partTypeIds: Array<Scalars['ID']['input']>;
  universal?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type CatalogSearchInput = {
  category?: InputMaybe<CatalogCategorySearchInput>;
  keyword?: InputMaybe<CatalogKeywordSearchInput>;
  partNumber?: InputMaybe<CatalogPartNumberSearchInput>;
  partTypeAttribute?: InputMaybe<CatalogPartTypeAttributeSearchInput>;
};

export type CategorySearchInput = {
  accountId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
  subCategoryId?: InputMaybe<Scalars['ID']['input']>;
  vehicleId: Scalars['ID']['input'];
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type ChangeAccountPrioritiesInput = {
  accountPriorities: Array<AccountPriority>;
};

export type ChangeAccountPrioritiesPayload = {
  __typename?: 'ChangeAccountPrioritiesPayload';
  accounts: Array<Account>;
};

export type ChangeActiveShopMembershipInput = {
  membershipID: Scalars['ID']['input'];
};

export type ChangeActiveShopMembershipPayload = {
  __typename?: 'ChangeActiveShopMembershipPayload';
  memberships?: Maybe<Array<ShopMember>>;
};

export type ChangeActiveSupplierMembershipInput = {
  membershipID: Scalars['ID']['input'];
};

export type ChangeActiveSupplierMembershipPayload = {
  __typename?: 'ChangeActiveSupplierMembershipPayload';
  shopMembership?: Maybe<ShopMember>;
  supplierMembership?: Maybe<SupplierMember>;
};

export type ChangeEmailConfirmationInput = {
  confirmationCode: Scalars['String']['input'];
  newEmail: Scalars['String']['input'];
};

export type ChangeEmailConfirmationPayload = {
  __typename?: 'ChangeEmailConfirmationPayload';
  user?: Maybe<User>;
};

export type ChangePasswordConfirmationInput = {
  confirmationCode: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type ChangePasswordConfirmationPayload = {
  __typename?: 'ChangePasswordConfirmationPayload';
  user?: Maybe<User>;
};

export type ChangePasswordInput = {
  currentPassword?: InputMaybe<Scalars['String']['input']>;
};

export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  user?: Maybe<User>;
};

export type ChangePaymentMethodInput = {
  cardId: Scalars['ID']['input'];
};

export type ChangePaymentMethodPayload = {
  __typename?: 'ChangePaymentMethodPayload';
  success: Scalars['Boolean']['output'];
};

export type ChartLine = {
  __typename?: 'ChartLine';
  day: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  tireSize: Scalars['String']['output'];
  totalOrders: Scalars['Int']['output'];
  totalPrice: Scalars['BigDecimal']['output'];
  totalQuantity: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type CheckDuplicatesInput = {
  billingAddress: AddressInput;
  phone: Scalars['String']['input'];
};

export type CheckDuplicatesPayload = {
  __typename?: 'CheckDuplicatesPayload';
  hasDuplicates: Scalars['Boolean']['output'];
};

export type CompareCalculatePackagePriceInput = {
  items: Array<PackageItemInput>;
  price: Scalars['BigDecimal']['input'];
  quantity: Scalars['Int']['input'];
  tax: Scalars['BigDecimal']['input'];
};

export type CompareCalculatePackagePricePayload = {
  __typename?: 'CompareCalculatePackagePricePayload';
  calculatedPackagePrice: CalculatedPackagePrice;
};

export type ConfigurationField = {
  __typename?: 'ConfigurationField';
  helper?: Maybe<Scalars['String']['output']>;
  internalName: Scalars['String']['output'];
  label: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  options: Array<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
  type: ConfigurationFieldType;
  validationRules?: Maybe<ValidationRule>;
};

export enum ConfigurationFieldType {
  Checkbox = 'CHECKBOX',
  Email = 'EMAIL',
  Password = 'PASSWORD',
  Phone = 'PHONE',
  Select = 'SELECT',
  Text = 'TEXT'
}

export type ConfirmationEmailInput = {
  token: Scalars['String']['input'];
};

export type ConfirmationEmailPayload = {
  __typename?: 'ConfirmationEmailPayload';
  user?: Maybe<User>;
};

export enum ConnectShopToSmsError {
  /** The relation already exists. */
  ErrorAlreadyExists = 'ERROR_ALREADY_EXISTS',
  /** Management system not found. */
  ErrorManagementSystemNotFound = 'ERROR_MANAGEMENT_SYSTEM_NOT_FOUND',
  /** Partner API AutoConnect Callback Error. */
  ErrorPartnerApiAutoconnectCallback = 'ERROR_PARTNER_API_AUTOCONNECT_CALLBACK',
  /** Shop not found. */
  ErrorShopNotFound = 'ERROR_SHOP_NOT_FOUND'
}

export type ConnectShopToSmsInput = {
  /** A unique hash corresponding to a record in the database. */
  hash: Scalars['String']['input'];
  /** Shop Id. */
  shopId: Scalars['String']['input'];
};

export type ConnectShopToSmsPayload = {
  __typename?: 'ConnectShopToSmsPayload';
  /** Error, if present. Describes why the connection failed. */
  error?: Maybe<ConnectShopToSmsError>;
  /** The connection is successful. */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum ConnectionTime {
  LessOneMin = 'LESS_ONE_MIN',
  Na = 'NA',
  NextDay = 'NEXT_DAY',
  OnePlusDays = 'ONE_PLUS_DAYS',
  Today = 'TODAY'
}

export type ContentSilo = {
  __typename?: 'ContentSilo';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export enum Country {
  Ca = 'CA',
  Mx = 'MX',
  Us = 'US'
}

export type Coupon = {
  __typename?: 'Coupon';
  amountOff?: Maybe<Scalars['BigDecimal']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  percentOff?: Maybe<Scalars['BigDecimal']['output']>;
};

export type CreateAdditionalDetailsInput = {
  additionalDetails: Array<AdditionalDetail>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneType: UserPhoneType;
};

export type CreateAdditionalDetailsPayload = {
  __typename?: 'CreateAdditionalDetailsPayload';
  onboard?: Maybe<Array<Onboard>>;
  user?: Maybe<User>;
};

export enum CreateCartError {
  /** Punchout has already had a cart. Additional cart cannot be created. */
  PunchoutSessionHasCart = 'PUNCHOUT_SESSION_HAS_CART'
}

export type CreateCartInput = {
  customerEmail?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  repairOrderNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCartPayload = {
  __typename?: 'CreateCartPayload';
  /** New cart. */
  cart?: Maybe<Cart>;
  /** Error during creating new cart. */
  error?: Maybe<CreateCartError>;
};

export type CreateLaborRateInput = {
  isDefault: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  rate: Scalars['BigDecimal']['input'];
};

export type CreateLaborRatePayload = {
  __typename?: 'CreateLaborRatePayload';
  laborRate?: Maybe<LaborRate>;
};

export type CreateMatrixInput = {
  brandIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  items: Array<MatrixItemInput>;
  name: Scalars['String']['input'];
  subCategories?: InputMaybe<Array<MatrixSubCategoryInput>>;
  type: MatrixType;
};

export type CreateMatrixPayload = {
  __typename?: 'CreateMatrixPayload';
  matrix?: Maybe<Matrix>;
};

export type CreateOrUpdateOnboardStepInput = {
  step: CreateOrUpdateOnboardStepType;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrUpdateOnboardStepPayload = {
  __typename?: 'CreateOrUpdateOnboardStepPayload';
  onboardSteps?: Maybe<Array<OnboardStep>>;
};

export enum CreateOrUpdateOnboardStepType {
  Parts = 'PARTS',
  Phone = 'PHONE',
  PrimaryRole = 'PRIMARY_ROLE',
  Referral = 'REFERRAL',
  ShopType = 'SHOP_TYPE',
  Sms = 'SMS',
  Tires = 'TIRES'
}

export type CreatePackageInput = {
  default: Scalars['Boolean']['input'];
  items: Array<PackageItemInput>;
  name: Scalars['String']['input'];
};

export type CreatePackagePayload = {
  __typename?: 'CreatePackagePayload';
  package?: Maybe<Package>;
};

export type CreatePlan = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type CreateRecentSearchInput = {
  attributes?: InputMaybe<Array<RecentSearchAttributeInput>>;
  manufacturers?: InputMaybe<Array<Scalars['String']['input']>>;
  partCategoryID?: InputMaybe<Scalars['ID']['input']>;
  partNumber?: InputMaybe<Scalars['String']['input']>;
  partSubCategoryID?: InputMaybe<Scalars['ID']['input']>;
  partText?: InputMaybe<RecentSearchPartTextInput>;
  partTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  plate?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  tire?: InputMaybe<RecentSearchTireInput>;
  type: RecentSearchType;
  vehicleID?: InputMaybe<Scalars['ID']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRecentSearchPayload = {
  __typename?: 'CreateRecentSearchPayload';
  recentSearch?: Maybe<RecentSearch>;
};

export type CreateRetailPricingInput = {
  taxRate: Scalars['BigDecimal']['input'];
};

export type CreateRetailPricingPayload = {
  __typename?: 'CreateRetailPricingPayload';
  retailPricing?: Maybe<RetailPricing>;
};

export type CreateShippingAddressInput = {
  address: AddressInput;
  default: Scalars['Boolean']['input'];
};

export type CreateShippingAddressPayload = {
  __typename?: 'CreateShippingAddressPayload';
  addresses?: Maybe<Array<ShopAddress>>;
};

export enum CreateShopAndUserWithSmsConnectError {
  /** The relation already exists. */
  ErrorAlreadyExists = 'ERROR_ALREADY_EXISTS',
  /** Management system not found. */
  ErrorManagementSystemNotFound = 'ERROR_MANAGEMENT_SYSTEM_NOT_FOUND',
  /** Partner API AutoConnect Callback Error. */
  ErrorPartnerApiAutoconnectCallback = 'ERROR_PARTNER_API_AUTOCONNECT_CALLBACK',
  /** User already exists with the same email or username. */
  ErrorUserAlreadyExists = 'ERROR_USER_ALREADY_EXISTS',
  /** The billing address provided is invalid. */
  ErrorWrongBillingAddress = 'ERROR_WRONG_BILLING_ADDRESS',
  /** The shipping address provided is invalid. */
  ErrorWrongShippingAddress = 'ERROR_WRONG_SHIPPING_ADDRESS'
}

export type CreateShopAndUserWithSmsConnectInput = {
  address: AddressInput;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  hash: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  shopName: Scalars['String']['input'];
  shopPhoneNumber: Scalars['String']['input'];
  shopType: ShopType;
  utmParams?: InputMaybe<Array<UtmParamInput>>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CreateShopAndUserWithSmsConnectPayload = {
  __typename?: 'CreateShopAndUserWithSmsConnectPayload';
  /** Error, if present. Describes why the connection failed. */
  error?: Maybe<CreateShopAndUserWithSmsConnectError>;
  /** The User and Shop created and connected to sms successful. */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateShopCardInput = {
  token: Scalars['String']['input'];
};

export type CreateShopCardPayload = {
  __typename?: 'CreateShopCardPayload';
  card?: Maybe<Card>;
};

export enum CreateShopError {
  ActiveMemberAlreadyExists = 'ACTIVE_MEMBER_ALREADY_EXISTS'
}

export type CreateShopInput = {
  billingAddress: AddressInput;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  shippingAddress?: InputMaybe<AddressInput>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CreateShopManagementSystemRelationInput = {
  managementSystemId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noSms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateShopManagementSystemRelationPayload = {
  __typename?: 'CreateShopManagementSystemRelationPayload';
  shop?: Maybe<Shop>;
};

export type CreateShopPayload = {
  __typename?: 'CreateShopPayload';
  error?: Maybe<CreateShopError>;
  shop?: Maybe<Shop>;
};

export type CreateStockOrderTemplateInput = {
  items?: InputMaybe<Array<CreateTemplateItem>>;
  name: Scalars['String']['input'];
};

export type CreateStockOrderTemplatePayload = {
  __typename?: 'CreateStockOrderTemplatePayload';
  template?: Maybe<StockOrderTemplate>;
};

export type CreateSubscriptionHelpRequestInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};

export type CreateSubscriptionHelpRequestPayload = {
  __typename?: 'CreateSubscriptionHelpRequestPayload';
  success: Scalars['Boolean']['output'];
};

export type CreateSubscriptionInput = {
  cardId: Scalars['ID']['input'];
  plans: Array<CreatePlan>;
};

export type CreateSubscriptionPayload = {
  __typename?: 'CreateSubscriptionPayload';
  shopSubscription?: Maybe<ShopSubscription>;
};

export type CreateSupportCaseInput = {
  email: Scalars['String']['input'];
  notes: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreateSupportCasePayload = {
  __typename?: 'CreateSupportCasePayload';
  success: Scalars['Boolean']['output'];
};

export type CreateTemplateItem = {
  accountId: Scalars['Int']['input'];
  displayPartNumber: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  lineCardId?: InputMaybe<Scalars['Int']['input']>;
  partName?: InputMaybe<Scalars['String']['input']>;
  partNumberId: Scalars['String']['input'];
  partTypeId?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  supplierId: Scalars['Int']['input'];
  tireSize?: InputMaybe<TireSizeInput>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateTireQuoteInput = {
  customerInfo: TireQuoteCustomerInfoInput;
  matrices: Array<CreateMatrixInput>;
  notificationEmails: Array<Scalars['String']['input']>;
  packageItems: Array<PackageItemInput>;
  quantity: Scalars['Int']['input'];
  taxRate: Scalars['BigDecimal']['input'];
  tires: Array<TireQuoteItemInput>;
};

export type CreateTireQuotePayload = {
  __typename?: 'CreateTireQuotePayload';
  hash: Scalars['ID']['output'];
};

export type CreateUserByGoogleCodeInput = {
  code: Scalars['String']['input'];
  utmParams?: InputMaybe<Array<UtmParamInput>>;
};

export type CreateUserByGoogleOneTapInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  utmParams?: InputMaybe<Array<UtmParamInput>>;
};

export type CreateUserByInvitationInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneType: UserPhoneType;
};

export type CreateUserByRegistrationInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
  referralCode?: InputMaybe<Scalars['String']['input']>;
  selectedSms?: InputMaybe<Scalars['String']['input']>;
  utmParams?: InputMaybe<Array<UtmParamInput>>;
};

export type CreateUserInput = {
  googleCode?: InputMaybe<CreateUserByGoogleCodeInput>;
  googleOneTap?: InputMaybe<CreateUserByGoogleOneTapInput>;
  invitation?: InputMaybe<CreateUserByInvitationInput>;
  registration?: InputMaybe<CreateUserByRegistrationInput>;
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  accountStatus?: Maybe<UserAccountStatus>;
  success: Scalars['Boolean']['output'];
};

export type CustomManagementSystem = {
  __typename?: 'CustomManagementSystem';
  name: Scalars['String']['output'];
};

export type CustomPreferredBrand = {
  __typename?: 'CustomPreferredBrand';
  brand?: Maybe<Brand>;
  brands?: Maybe<Array<Brand>>;
  id: Scalars['ID']['output'];
  priority: Scalars['Int']['output'];
  type: ProductType;
};

export enum DeleteActiveCartItemsError {
  /** No active cart in session. */
  NoActiveCart = 'NO_ACTIVE_CART'
}

export type DeleteActiveCartItemsErrorPayload = {
  __typename?: 'DeleteActiveCartItemsErrorPayload';
  /** Error type. */
  error: DeleteActiveCartItemsError;
  /** Error message. */
  errorMessage: Scalars['String']['output'];
};

export type DeleteActiveCartItemsInput = {
  /** List of ids of items to delete from active cart. */
  itemIds: Array<Scalars['ID']['input']>;
};

export type DeleteActiveCartItemsPayload = DeleteActiveCartItemsErrorPayload | DeleteActiveCartItemsSuccessPayload;

export type DeleteActiveCartItemsSuccessPayload = {
  __typename?: 'DeleteActiveCartItemsSuccessPayload';
  /** Active cart. */
  cart?: Maybe<Cart>;
  /** Ids of deleted items. */
  deletedItemIds: Array<Scalars['ID']['output']>;
};

export enum DeleteActiveCartLaborItemError {
  Internal = 'INTERNAL',
  NoActiveCart = 'NO_ACTIVE_CART'
}

export type DeleteActiveCartLaborItemErrorPayload = {
  __typename?: 'DeleteActiveCartLaborItemErrorPayload';
  error: DeleteActiveCartLaborItemError;
  errorMessage: Scalars['String']['output'];
};

export type DeleteActiveCartLaborItemInput = {
  id: Scalars['ID']['input'];
};

export type DeleteActiveCartLaborItemPayload = DeleteActiveCartLaborItemErrorPayload | DeleteActiveCartLaborItemSuccessPayload;

export type DeleteActiveCartLaborItemSuccessPayload = {
  __typename?: 'DeleteActiveCartLaborItemSuccessPayload';
  success: Scalars['Boolean']['output'];
};

export enum DeleteActiveCartOrderError {
  /** No active cart in session. */
  NoActiveCart = 'NO_ACTIVE_CART'
}

export type DeleteActiveCartOrderErrorPayload = {
  __typename?: 'DeleteActiveCartOrderErrorPayload';
  /** Error type. */
  error: DeleteActiveCartOrderError;
  /** Error message. */
  errorMessage: Scalars['String']['output'];
};

export type DeleteActiveCartOrderInput = {
  /** ID of order to delete from active cart. */
  orderId: Scalars['ID']['input'];
};

export type DeleteActiveCartOrderPayload = DeleteActiveCartOrderErrorPayload | DeleteActiveCartOrderSuccessPayload;

export type DeleteActiveCartOrderSuccessPayload = {
  __typename?: 'DeleteActiveCartOrderSuccessPayload';
  /** Active cart. */
  cart?: Maybe<Cart>;
  /** Order ID. */
  deletedOrderId: Scalars['ID']['output'];
};

export type DeleteCartItemsInput = {
  /** Cart ID. */
  cartId: Scalars['ID']['input'];
  /** Item IDs. */
  itemIds: Array<Scalars['ID']['input']>;
};

export type DeleteCartItemsPayload = {
  __typename?: 'DeleteCartItemsPayload';
  /** Result cart. */
  cart?: Maybe<Cart>;
};

export type DeleteCartOrderInput = {
  /** Cart ID. */
  cartId: Scalars['ID']['input'];
  /** Order ID. */
  orderId: Scalars['ID']['input'];
};

export type DeleteCartOrderPayload = {
  __typename?: 'DeleteCartOrderPayload';
  /** Result cart. */
  cart?: Maybe<Cart>;
};

export type DeleteLaborRateInput = {
  id: Scalars['ID']['input'];
};

export type DeleteLaborRatePayload = {
  __typename?: 'DeleteLaborRatePayload';
  id: Scalars['ID']['output'];
};

export type DeleteMatrixInput = {
  id: Scalars['ID']['input'];
};

export type DeleteMatrixPayload = {
  __typename?: 'DeleteMatrixPayload';
  id: Scalars['ID']['output'];
};

export type DeleteMemberInput = {
  id: Scalars['ID']['input'];
};

export type DeleteMemberPayload = {
  __typename?: 'DeleteMemberPayload';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeletePackageInput = {
  id: Scalars['ID']['input'];
};

export type DeletePackagePayload = {
  __typename?: 'DeletePackagePayload';
  id: Scalars['ID']['output'];
};

export type DeleteShippingAddressInput = {
  id: Scalars['ID']['input'];
};

export type DeleteShippingAddressPayload = {
  __typename?: 'DeleteShippingAddressPayload';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteShopAccountInput = {
  /** Account id. */
  accountId: Scalars['ID']['input'];
};

export type DeleteShopAccountPayload = {
  __typename?: 'DeleteShopAccountPayload';
  /** Deleted account id. */
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteShopCardInput = {
  cardId: Scalars['ID']['input'];
};

export type DeleteShopCardPayload = {
  __typename?: 'DeleteShopCardPayload';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteStockOrderPayload = {
  __typename?: 'DeleteStockOrderPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteStockOrderTemplateInput = {
  id: Scalars['ID']['input'];
};

export type DeleteStockOrderTemplatePayload = {
  __typename?: 'DeleteStockOrderTemplatePayload';
  id?: Maybe<Scalars['ID']['output']>;
};

export enum DeliverySource {
  MarkAsDelivered = 'MARK_AS_DELIVERED',
  Tracking = 'TRACKING'
}

export type Diagram = {
  __typename?: 'Diagram';
  brand?: Maybe<Brand>;
  category?: Maybe<PartCategory>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  partTypes?: Maybe<Array<PartType>>;
  references?: Maybe<Array<DiagramReference>>;
  url?: Maybe<Scalars['String']['output']>;
};

export type DiagramAttribute = {
  __typename?: 'DiagramAttribute';
  name: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
};

export type DiagramReference = {
  __typename?: 'DiagramReference';
  attributes?: Maybe<Array<DiagramAttribute>>;
  layer?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  partType?: Maybe<PartType>;
};

export type Discount = {
  __typename?: 'Discount';
  calculatedBy: Array<DiscountCalculatedBy>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  perTire: Scalars['Boolean']['output'];
  percentage: Scalars['Boolean']['output'];
  percentageAmount?: Maybe<PercentageAmount>;
  price: Scalars['BigDecimal']['output'];
};

export enum DiscountCalculatedBy {
  Addon = 'ADDON',
  Disposal = 'DISPOSAL',
  Fee = 'FEE',
  Labor = 'LABOR',
  Tires = 'TIRES'
}

export type DiscountInput = {
  calculatedBy: Array<DiscountCalculatedBy>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  perTire: Scalars['Boolean']['input'];
  percentage: Scalars['Boolean']['input'];
  percentageAmount?: InputMaybe<PercentageAmountInput>;
  price: Scalars['BigDecimal']['input'];
};

export enum DisplayAction {
  /** Hide prices and "Add to cart" button. */
  No = 'NO',
  /** Show prices only. */
  PriceOnly = 'PRICE_ONLY',
  /** Show prices and "Add to cart" button. */
  Yes = 'YES'
}

export type Disposal = {
  __typename?: 'Disposal';
  applyTax: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  percentage: Scalars['Boolean']['output'];
  percentageAmount?: Maybe<PercentageAmount>;
  price: Scalars['BigDecimal']['output'];
};

export type DisposalInput = {
  applyTax: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  percentage: Scalars['Boolean']['input'];
  percentageAmount?: InputMaybe<PercentageAmountInput>;
  price: Scalars['BigDecimal']['input'];
};

export type DowngradeSubscriptionPlanInput = {
  planId: Scalars['ID']['input'];
};

export type DowngradeSubscriptionPlanPayload = {
  __typename?: 'DowngradeSubscriptionPlanPayload';
  shopSubscription?: Maybe<ShopSubscription>;
};

export type DowngradeSubscriptionPlanToFreePayload = {
  __typename?: 'DowngradeSubscriptionPlanToFreePayload';
  shopSubscription?: Maybe<ShopSubscription>;
};

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN'
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN'
}

export enum ExProfileType {
  TypeDynamic = 'TYPE_DYNAMIC',
  TypeRetail = 'TYPE_RETAIL',
  TypeStatic = 'TYPE_STATIC'
}

export enum ExpectedAvailabilityType {
  Main = 'MAIN',
  ManyOnlyNetwork = 'MANY_ONLY_NETWORK',
  ManyWithMain = 'MANY_WITH_MAIN',
  Network = 'NETWORK'
}

export type ExtendedParameter = {
  __typename?: 'ExtendedParameter';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ExtendedParameters = {
  __typename?: 'ExtendedParameters';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum FailedSubmitActiveCart {
  CallbackError = 'CALLBACK_ERROR'
}

export type FailedSubmitActiveCartPayload = {
  __typename?: 'FailedSubmitActiveCartPayload';
  callbackError: FailedSubmitActiveCart;
  callbackErrorMessage: Scalars['String']['output'];
};

export type Fee = {
  __typename?: 'Fee';
  applyTax: Scalars['Boolean']['output'];
  calculatedBy: Array<FeeCalculatedBy>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  perTire: Scalars['Boolean']['output'];
  percentage: Scalars['Boolean']['output'];
  percentageAmount?: Maybe<PercentageAmount>;
  price: Scalars['BigDecimal']['output'];
};

export enum FeeCalculatedBy {
  Addon = 'ADDON',
  Disposal = 'DISPOSAL',
  Labor = 'LABOR',
  Tires = 'TIRES'
}

export type FeeInput = {
  applyTax: Scalars['Boolean']['input'];
  calculatedBy: Array<FeeCalculatedBy>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  perTire: Scalars['Boolean']['input'];
  percentage: Scalars['Boolean']['input'];
  percentageAmount?: InputMaybe<PercentageAmountInput>;
  price: Scalars['BigDecimal']['input'];
};

export type Fet = {
  __typename?: 'Fet';
  applyTax: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  perTire: Scalars['Boolean']['output'];
  price: Scalars['BigDecimal']['output'];
};

export type FetInput = {
  applyTax: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  perTire: Scalars['Boolean']['input'];
  price: Scalars['BigDecimal']['input'];
};

export enum FilterRecentOrderStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  OrderPlaced = 'ORDER_PLACED',
  Shipped = 'SHIPPED',
  Shipping = 'SHIPPING'
}

export type FluidsApplication = {
  __typename?: 'FluidsApplication';
  applicationId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  positionName?: Maybe<Scalars['String']['output']>;
};

export type FluidsDetails = {
  __typename?: 'FluidsDetails';
  capacities: Array<Capacity>;
  items: Array<FluidsDetailsItem>;
};

export type FluidsDetailsItem = {
  __typename?: 'FluidsDetailsItem';
  detailsNotes: Array<Scalars['String']['output']>;
  formulation?: Maybe<Scalars['String']['output']>;
  grade: Scalars['String']['output'];
  gradePreference: Scalars['Int']['output'];
  maxCapacity: Scalars['String']['output'];
  partNumber?: Maybe<Scalars['String']['output']>;
  specStandard?: Maybe<Scalars['String']['output']>;
  tempRange?: Maybe<Scalars['String']['output']>;
  tradeName?: Maybe<Scalars['String']['output']>;
  viscosity: Scalars['String']['output'];
};

export type FluidsSummary = {
  __typename?: 'FluidsSummary';
  applications: Array<FluidsApplication>;
};

export type FreightTerm = {
  __typename?: 'FreightTerm';
  frightTermService?: Maybe<FreightTermService>;
  name: Scalars['String']['output'];
};

export type FreightTermService = {
  __typename?: 'FreightTermService';
  charge: Scalars['Float']['output'];
  method: Scalars['String']['output'];
  methodCode?: Maybe<Scalars['String']['output']>;
};

export type GroupedPartNumber = {
  __typename?: 'GroupedPartNumber';
  companyName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
};

export type Invitation = {
  __typename?: 'Invitation';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  permissions?: Maybe<MemberPermissions>;
  sentDate: Scalars['DateTime']['output'];
  status: InvitationStatus;
};

export type InvitationInfo = {
  __typename?: 'InvitationInfo';
  alreadyRegistered: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  shopLogo?: Maybe<Scalars['String']['output']>;
  shopName: Scalars['String']['output'];
  status: InvitationStatus;
};

export enum InvitationStatus {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Sent = 'SENT'
}

export type ItemParametersInput = {
  /** Account ID. */
  accountId: Scalars['ID']['input'];
  /** Availability branch for supplier where customer can choose an availability by name. */
  availabilityBranch?: InputMaybe<Scalars['String']['input']>;
  /** Line card ID. */
  lineCardId: Scalars['ID']['input'];
  /** Origin of item - from where it is adding. */
  origin?: InputMaybe<CartOrderItemOrigin>;
  /** Part name/title. */
  partName?: InputMaybe<Scalars['String']['input']>;
  /** Display part number or part number. */
  partNumber: Scalars['String']['input'];
  /** Part number ID. */
  partNumberId: Scalars['String']['input'];
  /** Part type ID. */
  partTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Requested quantity. */
  quantity: Scalars['Int']['input'];
  /** The position of the product in the search results. */
  searchResultPosition?: InputMaybe<Scalars['Int']['input']>;
  /** Part number from search. */
  searchedPartNumber?: InputMaybe<Scalars['String']['input']>;
  /** Store ID where to buy item. */
  storeId: Scalars['ID']['input'];
  /** Vehicle ID. */
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
  /** VIN from search. */
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type Job = {
  __typename?: 'Job';
  id: Scalars['ID']['output'];
  isFree: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  partTypes: Array<JobPartType>;
};

export type JobPartType = {
  __typename?: 'JobPartType';
  attributes: Array<PartTypeAttribute>;
  label?: Maybe<Scalars['String']['output']>;
  partType: PartType;
  required: Scalars['Boolean']['output'];
};

export type KeywordSearchInput = {
  accountId: Scalars['ID']['input'];
  keyword: Scalars['String']['input'];
  vehicleId: Scalars['ID']['input'];
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type Labor = {
  __typename?: 'Labor';
  applyTax: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  perTire: Scalars['Boolean']['output'];
  price: Scalars['BigDecimal']['output'];
};

export type LaborApplication = {
  __typename?: 'LaborApplication';
  applicationId: Scalars['ID']['output'];
  attributes?: Maybe<Array<Attribute>>;
  baseLaborTime: Scalars['BigDecimal']['output'];
  baseLaborTimeDescription?: Maybe<Scalars['String']['output']>;
  baseWarrantyLaborTime: Scalars['BigDecimal']['output'];
  descriptions?: Maybe<Array<Scalars['String']['output']>>;
  hasPartTypes: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  laborTimeInterval: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<Scalars['String']['output']>>;
  parentId?: Maybe<Scalars['ID']['output']>;
  partTypeDetails?: Maybe<Array<PartDetails>>;
  position?: Maybe<Position>;
  serviceType: Scalars['String']['output'];
  skillCode: Scalars['String']['output'];
  skillDescription: Scalars['String']['output'];
  skillName: Scalars['String']['output'];
  taxonomyAction?: Maybe<Scalars['String']['output']>;
  taxonomyId: Scalars['ID']['output'];
  taxonomyName: Scalars['String']['output'];
};

export type LaborInput = {
  applyTax: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  perTire: Scalars['Boolean']['input'];
  price: Scalars['BigDecimal']['input'];
};

export type LaborRate = {
  __typename?: 'LaborRate';
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  rate: Scalars['BigDecimal']['output'];
};

export type LicensePlate = {
  plate: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type LineCard = {
  __typename?: 'LineCard';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type LinkVehicleToCartInput = {
  /** Cart ID. */
  cartId: Scalars['ID']['input'];
  /** Vehicle ID. */
  vehicleId: Scalars['ID']['input'];
  /** VIN of vehicle. */
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type LinkVehicleToCartPayload = {
  __typename?: 'LinkVehicleToCartPayload';
  /** Result cart. */
  cart?: Maybe<Cart>;
  /** Added or updated cart linked vehicle. */
  linkedVehicle?: Maybe<CartLinkedVehicle>;
};

export type MaintenanceSchedulesDetails = {
  __typename?: 'MaintenanceSchedulesDetails';
  attributeName?: Maybe<Scalars['String']['output']>;
  baseLaborTime: Scalars['BigDecimal']['output'];
  description?: Maybe<Scalars['String']['output']>;
  detailsNotes: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  positionName?: Maybe<Scalars['String']['output']>;
};

export type MaintenanceSchedulesSummary = {
  __typename?: 'MaintenanceSchedulesSummary';
  distances: Array<Scalars['Int']['output']>;
  indicators: Array<Scalars['String']['output']>;
  months: Array<Scalars['Int']['output']>;
};

export type ManagementSystem = {
  __typename?: 'ManagementSystem';
  id: Scalars['ID']['output'];
  isDefaultDisplay: Scalars['Boolean']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  manual?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  priority?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ManagementSystemStatus>;
  synonyms?: Maybe<Scalars['String']['output']>;
};

export enum ManagementSystemStatus {
  Blocked = 'BLOCKED',
  IntegrationComplete = 'INTEGRATION_COMPLETE',
  InDevelopment = 'IN_DEVELOPMENT',
  New = 'NEW',
  TestingCertification = 'TESTING_CERTIFICATION'
}

export type MapBoschAccountInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MapBoschAccountPayload = {
  __typename?: 'MapBoschAccountPayload';
  success: Scalars['Boolean']['output'];
};

export type Matrix = {
  __typename?: 'Matrix';
  brands?: Maybe<Array<Brand>>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  items: Array<MatrixItem>;
  name: Scalars['String']['output'];
  subCategories?: Maybe<Array<MatrixSubCategory>>;
  type: MatrixType;
};

export type MatrixItem = {
  __typename?: 'MatrixItem';
  endPrice: Scalars['BigDecimal']['output'];
  fixed: Scalars['Boolean']['output'];
  price: Scalars['BigDecimal']['output'];
  startPrice: Scalars['BigDecimal']['output'];
};

export type MatrixItemInput = {
  endPrice: Scalars['BigDecimal']['input'];
  fixed: Scalars['Boolean']['input'];
  price: Scalars['BigDecimal']['input'];
  startPrice: Scalars['BigDecimal']['input'];
};

export type MatrixSubCategory = {
  __typename?: 'MatrixSubCategory';
  partTypes: Array<PartType>;
  subCategory: PartSubCategory;
};

export type MatrixSubCategoryInput = {
  id: Scalars['ID']['input'];
  partTypeIds: Array<Scalars['ID']['input']>;
};

export enum MatrixType {
  Part = 'PART',
  Tire = 'TIRE'
}

export type MemberPermissions = {
  __typename?: 'MemberPermissions';
  allOrders: Scalars['Boolean']['output'];
  boschRewards: Scalars['Boolean']['output'];
  brandPreferences: Scalars['Boolean']['output'];
  partsReport: Scalars['Boolean']['output'];
  payments: Scalars['Boolean']['output'];
  reportsDashboardAccess: Scalars['Boolean']['output'];
  retailPricing: Scalars['Boolean']['output'];
  shipping: Scalars['Boolean']['output'];
  shopProfile: Scalars['Boolean']['output'];
  spendReport: Scalars['Boolean']['output'];
  subscription: Scalars['Boolean']['output'];
  suppliers: Scalars['Boolean']['output'];
  tireReport: Scalars['Boolean']['output'];
  viewWholesaleCosts: Scalars['Boolean']['output'];
};

export type MemberPermissionsInput = {
  allOrders: Scalars['Boolean']['input'];
  boschRewards: Scalars['Boolean']['input'];
  brandPreferences: Scalars['Boolean']['input'];
  partsReport: Scalars['Boolean']['input'];
  payments: Scalars['Boolean']['input'];
  reportsDashboardAccess: Scalars['Boolean']['input'];
  retailPricing: Scalars['Boolean']['input'];
  shipping: Scalars['Boolean']['input'];
  shopProfile: Scalars['Boolean']['input'];
  spendReport: Scalars['Boolean']['input'];
  subscription: Scalars['Boolean']['input'];
  suppliers: Scalars['Boolean']['input'];
  tireReport: Scalars['Boolean']['input'];
  viewWholesaleCosts: Scalars['Boolean']['input'];
};

export type Mitchell1Identifiers = {
  __typename?: 'Mitchell1Identifiers';
  m1VehicleId: Scalars['ID']['output'];
};

export type Mitchell1Labor = {
  __typename?: 'Mitchell1Labor';
  availableMitchell1Vehicles: Array<Mitchell1Vehicle>;
  error?: Maybe<Scalars['String']['output']>;
  mitchell1VehicleId?: Maybe<Scalars['String']['output']>;
  operationProcedures: Array<OperationProcedure>;
  partType?: Maybe<PartType>;
  partTypeId: Scalars['ID']['output'];
  vehicle?: Maybe<Vehicle>;
  vehicleId: Scalars['ID']['output'];
};

export type Mitchell1LaborInput = {
  mitchell1VehicleId?: InputMaybe<Scalars['ID']['input']>;
  partTypeId: Scalars['ID']['input'];
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
};

export type Mitchell1LaborItem = {
  __typename?: 'Mitchell1LaborItem';
  additionalApplication?: Maybe<Scalars['String']['output']>;
  applicationNote?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  standardTime: Scalars['BigDecimal']['output'];
  vehicleApplication?: Maybe<Scalars['String']['output']>;
  warrantyTime: Scalars['BigDecimal']['output'];
};

export type Mitchell1Order = {
  __typename?: 'Mitchell1Order';
  confirmationNumber: Scalars['String']['output'];
  deliveryOptions: Scalars['String']['output'];
  orderMessage: Scalars['String']['output'];
  parts: Array<Mitchell1OrderedPart>;
  reservedPoNumber?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type Mitchell1OrderedPart = {
  __typename?: 'Mitchell1OrderedPart';
  description: Scalars['String']['output'];
  locationId: Scalars['String']['output'];
  locationName: Scalars['String']['output'];
  manufacturerLineCode: Scalars['String']['output'];
  manufacturerName: Scalars['String']['output'];
  metadata: Scalars['String']['output'];
  partCategory: Scalars['String']['output'];
  partNumber: Scalars['String']['output'];
  quantityAvailable: Scalars['Int']['output'];
  quantityOrdered: Scalars['Int']['output'];
  quantityRequested: Scalars['Int']['output'];
  shippingCost?: Maybe<Scalars['Float']['output']>;
  shippingDescription?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  supplierName: Scalars['String']['output'];
  type: Scalars['String']['output'];
  unitCore: Scalars['Float']['output'];
  unitCost: Scalars['Float']['output'];
  unitList: Scalars['Float']['output'];
};

export type Mitchell1SourceInput = {
  m1VehicleId: Scalars['ID']['input'];
};

export type Mitchell1SubmittedLabor = {
  __typename?: 'Mitchell1SubmittedLabor';
  description: Scalars['String']['output'];
  hours: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

export type Mitchell1SubmittedNote = {
  __typename?: 'Mitchell1SubmittedNote';
  note: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Mitchell1SubmittedPart = {
  __typename?: 'Mitchell1SubmittedPart';
  description: Scalars['String']['output'];
  isTire: Scalars['Boolean']['output'];
  manufacturerLineCode: Scalars['String']['output'];
  manufacturerName: Scalars['String']['output'];
  metadata: Scalars['String']['output'];
  partCategory: Scalars['String']['output'];
  partNumber: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  shippingCost?: Maybe<Scalars['Float']['output']>;
  shippingDescription?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  supplierName: Scalars['String']['output'];
  type: Scalars['String']['output'];
  unitCore: Scalars['Float']['output'];
  unitCost: Scalars['Float']['output'];
  unitList: Scalars['Float']['output'];
  upcCode?: Maybe<Scalars['String']['output']>;
};

export type Mitchell1Vehicle = {
  __typename?: 'Mitchell1Vehicle';
  bodyStyle: Scalars['String']['output'];
  country: Scalars['String']['output'];
  driveType: Scalars['String']['output'];
  engine: Scalars['String']['output'];
  engineCode: Scalars['String']['output'];
  fuelType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  make: Scalars['String']['output'];
  mitchell1VehicleId: Scalars['String']['output'];
  model: Scalars['String']['output'];
  submodel: Scalars['String']['output'];
  transferCaseType: Scalars['String']['output'];
  transmissionCode: Scalars['String']['output'];
  transmissionControlType: Scalars['String']['output'];
};

export type MotorIdentifiers = {
  __typename?: 'MotorIdentifiers';
  applicationId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
};

export type MotorSourceInput = {
  applicationId: Scalars['ID']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation?: Maybe<AcceptInvitationPayload>;
  /** Set cart as active. */
  activateCart?: Maybe<ActivateCartPayload>;
  /** Adds item to active cart. */
  addItemToActiveCart?: Maybe<AddItemToActiveCartPayload>;
  /** Add labor item to active cart. */
  addLaborItemToActiveCart?: Maybe<AddLaborItemToActiveCartPayload>;
  /** Shop Account. */
  addShopAccount?: Maybe<AddShopAccountPayload>;
  applyTireQuote?: Maybe<ApplyTireQuotePayload>;
  /** Archive selected cart. Change status to ARCHIVED. */
  archiveCart?: Maybe<ArchiveCartPayload>;
  /** Batch adds items to active cart. */
  batchAddItemsToActiveCart?: Maybe<BatchAddItemsToActiveCartPayload>;
  /** Batch add labor items to active cart. */
  batchAddLaborItemsToActiveCart?: Maybe<BatchAddLaborItemsToActiveCartPayload>;
  /** Batch delete labor items from active cart. */
  batchDeleteActiveCartLaborItems?: Maybe<DeleteActiveCartLaborItemPayload>;
  /** Batch update labor items in active cart. */
  batchUpdateActiveCartLaborItems?: Maybe<BatchUpdateActiveCartLaborItemsPayload>;
  /** Buy active cart. */
  buyActiveCart?: Maybe<BuyActiveCartPayload>;
  cancelInvitation?: Maybe<CancelInvitationPayload>;
  changeAccountPriorities?: Maybe<ChangeAccountPrioritiesPayload>;
  changeActiveShopMembership?: Maybe<ChangeActiveShopMembershipPayload>;
  changeActiveSupplierMembership?: Maybe<ChangeActiveSupplierMembershipPayload>;
  changeEmailConfirmation?: Maybe<ChangeEmailConfirmationPayload>;
  changePassword?: Maybe<ChangePasswordPayload>;
  changePasswordConfirmation?: Maybe<ChangePasswordConfirmationPayload>;
  /** Subscriptions. */
  changePaymentMethod?: Maybe<ChangePaymentMethodPayload>;
  checkDuplicates?: Maybe<CheckDuplicatesPayload>;
  compareCalculatePackagePrice?: Maybe<CompareCalculatePackagePricePayload>;
  confirmationEmail?: Maybe<ConfirmationEmailPayload>;
  /** SMS Auto Connect. */
  connectShopToSms?: Maybe<ConnectShopToSmsPayload>;
  createAdditionalDetails?: Maybe<CreateAdditionalDetailsPayload>;
  /** Create a new cart. */
  createCart?: Maybe<CreateCartPayload>;
  createLaborRate?: Maybe<CreateLaborRatePayload>;
  createMatrix?: Maybe<CreateMatrixPayload>;
  createOrUpdateOnboardStep?: Maybe<CreateOrUpdateOnboardStepPayload>;
  createPackage?: Maybe<CreatePackagePayload>;
  createRecentSearch?: Maybe<CreateRecentSearchPayload>;
  /** Pricing. */
  createRetailPricing?: Maybe<CreateRetailPricingPayload>;
  /** Shipping address. */
  createShippingAddress?: Maybe<CreateShippingAddressPayload>;
  createShop?: Maybe<CreateShopPayload>;
  createShopAndUserWithSmsConnect?: Maybe<CreateShopAndUserWithSmsConnectPayload>;
  /** Shop Cards. */
  createShopCard?: Maybe<CreateShopCardPayload>;
  createShopManagementSystemRelation?: Maybe<CreateShopManagementSystemRelationPayload>;
  /** Stock Order Templates. */
  createStockOrderTemplate?: Maybe<CreateStockOrderTemplatePayload>;
  createSubscription?: Maybe<CreateSubscriptionPayload>;
  createSubscriptionHelpRequest?: Maybe<CreateSubscriptionHelpRequestPayload>;
  /** Support. */
  createSupportCase?: Maybe<CreateSupportCasePayload>;
  /** Tire quote. */
  createTireQuote?: Maybe<CreateTireQuotePayload>;
  /** Sign up. */
  createUser?: Maybe<CreateUserPayload>;
  /** Delete order items in active cart. */
  deleteActiveCartItems?: Maybe<DeleteActiveCartItemsPayload>;
  /** Delete labor item from active cart. */
  deleteActiveCartLaborItem?: Maybe<DeleteActiveCartLaborItemPayload>;
  /** Delete order in active cart. */
  deleteActiveCartOrder?: Maybe<DeleteActiveCartOrderPayload>;
  /** Delete list of items from cart. */
  deleteCartItems?: Maybe<DeleteCartItemsPayload>;
  /** Delete an order from cart. */
  deleteCartOrder?: Maybe<DeleteCartOrderPayload>;
  deleteLaborRate?: Maybe<DeleteLaborRatePayload>;
  deleteMatrix?: Maybe<DeleteMatrixPayload>;
  /** Member. */
  deleteMember?: Maybe<DeleteMemberPayload>;
  deletePackage?: Maybe<DeletePackagePayload>;
  deleteShippingAddress?: Maybe<DeleteShippingAddressPayload>;
  deleteShopAccount?: Maybe<DeleteShopAccountPayload>;
  deleteShopCard?: Maybe<DeleteShopCardPayload>;
  deleteStockOrder?: Maybe<DeleteStockOrderPayload>;
  deleteStockOrderTemplate?: Maybe<DeleteStockOrderTemplatePayload>;
  downgradeSubscriptionPlan?: Maybe<DowngradeSubscriptionPlanPayload>;
  downgradeSubscriptionPlanToFree?: Maybe<DowngradeSubscriptionPlanToFreePayload>;
  /** Link or update linked vehicle by vehicleID and VIN. */
  linkVehicleToCart?: Maybe<LinkVehicleToCartPayload>;
  mapBoschAccount?: Maybe<MapBoschAccountPayload>;
  reactivateSubscriptionAddon?: Maybe<ReactivateSubscriptionAddonPayload>;
  reactivateSubscriptionPlan?: Maybe<ReactivateSubscriptionPlanPayload>;
  /** Bosch account. */
  registerBoschAccount?: Maybe<RegisterBoschAccountPayload>;
  removeShopLogo?: Maybe<RemoveShopLogoPayload>;
  removeSubscriptionAddon?: Maybe<RemoveSubscriptionAddonPayload>;
  removeUserAvatar?: Maybe<RemoveUserAvatarPayload>;
  resendActivationEmail?: Maybe<ResendActivationEmailPayload>;
  resendInvitation?: Maybe<ResendInvitationPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  /** Invitation. */
  sendInvitation?: Maybe<SendInvitationPayload>;
  sendResettingPasswordEmail?: Maybe<SendResettingPasswordEmailPayload>;
  sendUnsubscribeReasons?: Maybe<SendUnsubscribeReasonsPayload>;
  /** User. */
  setAllowOrderEmails?: Maybe<SetAllowOrderEmailsPayload>;
  /** Preferred brands. */
  setCustomPreferredBrands?: Maybe<SetCustomPreferredBrandsPayload>;
  setInvitationPermissions?: Maybe<SetInvitationPermissionsPayload>;
  setMemberPermissions?: Maybe<SetMemberPermissionsPayload>;
  /** Recent orders. */
  setOrderAsDelivered?: Maybe<SetOrderAsDeliveredPayload>;
  setPickupRadius?: Maybe<SetPickupRadiusPayload>;
  setPreferredBrandsMode?: Maybe<SetPreferredBrandsModePayload>;
  setShowRetailPricing?: Maybe<SetShowRetailPricingPayload>;
  /** Stock Order. */
  setStockOrder?: Maybe<SetStockOrderPayload>;
  shopHelpRequest?: Maybe<ShopHelpRequestPayload>;
  skipOnboard?: Maybe<SkipOnboardPayload>;
  /** Submit quote. */
  submitActiveCart?: Maybe<SubmitActiveCartPayload>;
  /** Toggle show retail pricing flag. Show only retail prices or not. */
  toggleShowRetailPricing?: Maybe<ToggleShowRetailPricingPayload>;
  transferAdmin?: Maybe<TransferAdminPayload>;
  /** Unarchive selected cart. Change status to CREATED. */
  unarchiveCart?: Maybe<UnarchiveCartPayload>;
  /** Remove selected linked vehicle. */
  unlinkVehicleFromCart?: Maybe<UnlinkVehicleFromCartPayload>;
  /** Update item quantity in active cart. */
  updateActiveCartItemQuantity?: Maybe<UpdateActiveCartItemQuantityPayload>;
  /** Update labor item in active cart. */
  updateActiveCartLaborItem?: Maybe<UpdateActiveCartLaborItemPayload>;
  /** Update labor rate in active cart. */
  updateActiveCartLaborRate?: Maybe<UpdateActiveCartLaborRatePayload>;
  /** Updates additional fields in active cart order. */
  updateActiveCartOrderAdditionalFields?: Maybe<UpdateActiveCartOrderAdditionalFieldsPayload>;
  /** Updates payment notes in active cart order - purchase order number and custom notes. */
  updateActiveCartOrderPaymentNotes?: Maybe<UpdateActiveCartOrderPaymentNotesPayload>;
  /** Updates shipping method in active cart order. */
  updateActiveCartOrderShippingMethod?: Maybe<UpdateActiveCartOrderShippingMethodPayload>;
  /** Update selected cart. */
  updateCart?: Maybe<UpdateCartPayload>;
  /** Update quantity for selected cart item. */
  updateCartItemQuantity?: Maybe<UpdateCartItemQuantityPayload>;
  /** Update default/preferred shipping method for selected supplier. */
  updateCartSupplierPreferredShippingMethod?: Maybe<UpdateCartSupplierPreferredShippingMethodPayload>;
  updateLaborRate?: Maybe<UpdateLaborRatePayload>;
  updateLastUseDateStockOrderTemplate?: Maybe<UpdateLastUseDateStockOrderTemplatePayload>;
  updateMatrix?: Maybe<UpdateMatrixPayload>;
  updatePackage?: Maybe<UpdatePackagePayload>;
  updateRetailPricing?: Maybe<UpdateRetailPricingPayload>;
  updateShippingAddress?: Maybe<UpdateShippingAddressPayload>;
  updateShop?: Maybe<UpdateShopPayload>;
  updateShopAccount?: Maybe<UpdateShopAccountPayload>;
  updateShopCard?: Maybe<UpdateShopCardPayload>;
  updateShopCartSettings?: Maybe<UpdateShopCartSettingsPayload>;
  updateShopDetails?: Maybe<UpdateShopDetailsPayload>;
  updateShopSmsSettings?: Maybe<UpdateShopSmsSettingsPayload>;
  updateStockOrderTemplate?: Maybe<UpdateStockOrderTemplatePayload>;
  updateSubscription?: Maybe<UpdateSubscriptionPayload>;
  updateSubscriptionPeriod?: Maybe<UpdateSubscriptionPeriodPayload>;
  updateTireQuote?: Maybe<UpdateTireQuotePayload>;
  updateUserInformation?: Maybe<UpdateUserInformationPayload>;
  updateUserPhoneNumber?: Maybe<UpdateUserPhoneNumberPayload>;
  userAlreadyExists?: Maybe<UserAlreadyExistsPayload>;
  validateAddresses?: Maybe<ValidateAddressesPayload>;
};


export type MutationAcceptInvitationArgs = {
  input: AcceptInvitationInput;
};


export type MutationActivateCartArgs = {
  input: ActivateCartInput;
};


export type MutationAddItemToActiveCartArgs = {
  input: AddItemToActiveCartInput;
};


export type MutationAddLaborItemToActiveCartArgs = {
  input: AddLaborItemToActiveCartInput;
};


export type MutationAddShopAccountArgs = {
  input: AddShopAccountInput;
};


export type MutationApplyTireQuoteArgs = {
  input: ApplyTireQuoteInput;
};


export type MutationArchiveCartArgs = {
  input: ArchiveCartInput;
};


export type MutationBatchAddItemsToActiveCartArgs = {
  input: BatchAddItemsToActiveCartInput;
};


export type MutationBatchAddLaborItemsToActiveCartArgs = {
  input: BatchAddLaborItemsToActiveCartInput;
};


export type MutationBatchDeleteActiveCartLaborItemsArgs = {
  input: BatchDeleteActiveCartLaborItemInput;
};


export type MutationBatchUpdateActiveCartLaborItemsArgs = {
  input: BatchUpdateActiveCartLaborItemsInput;
};


export type MutationBuyActiveCartArgs = {
  input: BuyActiveCartInput;
};


export type MutationCancelInvitationArgs = {
  input: CancelInvitationInput;
};


export type MutationChangeAccountPrioritiesArgs = {
  input: ChangeAccountPrioritiesInput;
};


export type MutationChangeActiveShopMembershipArgs = {
  input: ChangeActiveShopMembershipInput;
};


export type MutationChangeActiveSupplierMembershipArgs = {
  input: ChangeActiveSupplierMembershipInput;
};


export type MutationChangeEmailConfirmationArgs = {
  input: ChangeEmailConfirmationInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationChangePasswordConfirmationArgs = {
  input: ChangePasswordConfirmationInput;
};


export type MutationChangePaymentMethodArgs = {
  input: ChangePaymentMethodInput;
};


export type MutationCheckDuplicatesArgs = {
  input: CheckDuplicatesInput;
};


export type MutationCompareCalculatePackagePriceArgs = {
  input?: InputMaybe<CompareCalculatePackagePriceInput>;
};


export type MutationConfirmationEmailArgs = {
  input?: InputMaybe<ConfirmationEmailInput>;
};


export type MutationConnectShopToSmsArgs = {
  input: ConnectShopToSmsInput;
};


export type MutationCreateAdditionalDetailsArgs = {
  input: CreateAdditionalDetailsInput;
};


export type MutationCreateCartArgs = {
  input: CreateCartInput;
};


export type MutationCreateLaborRateArgs = {
  input: CreateLaborRateInput;
};


export type MutationCreateMatrixArgs = {
  input: CreateMatrixInput;
};


export type MutationCreateOrUpdateOnboardStepArgs = {
  input: CreateOrUpdateOnboardStepInput;
};


export type MutationCreatePackageArgs = {
  input: CreatePackageInput;
};


export type MutationCreateRecentSearchArgs = {
  input: CreateRecentSearchInput;
};


export type MutationCreateRetailPricingArgs = {
  input: CreateRetailPricingInput;
};


export type MutationCreateShippingAddressArgs = {
  input: CreateShippingAddressInput;
};


export type MutationCreateShopArgs = {
  input: CreateShopInput;
};


export type MutationCreateShopAndUserWithSmsConnectArgs = {
  input: CreateShopAndUserWithSmsConnectInput;
};


export type MutationCreateShopCardArgs = {
  input: CreateShopCardInput;
};


export type MutationCreateShopManagementSystemRelationArgs = {
  input: CreateShopManagementSystemRelationInput;
};


export type MutationCreateStockOrderTemplateArgs = {
  input: CreateStockOrderTemplateInput;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationCreateSubscriptionHelpRequestArgs = {
  input: CreateSubscriptionHelpRequestInput;
};


export type MutationCreateSupportCaseArgs = {
  input: CreateSupportCaseInput;
};


export type MutationCreateTireQuoteArgs = {
  input: CreateTireQuoteInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteActiveCartItemsArgs = {
  input: DeleteActiveCartItemsInput;
};


export type MutationDeleteActiveCartLaborItemArgs = {
  input: DeleteActiveCartLaborItemInput;
};


export type MutationDeleteActiveCartOrderArgs = {
  input: DeleteActiveCartOrderInput;
};


export type MutationDeleteCartItemsArgs = {
  input: DeleteCartItemsInput;
};


export type MutationDeleteCartOrderArgs = {
  input: DeleteCartOrderInput;
};


export type MutationDeleteLaborRateArgs = {
  input: DeleteLaborRateInput;
};


export type MutationDeleteMatrixArgs = {
  input: DeleteMatrixInput;
};


export type MutationDeleteMemberArgs = {
  input: DeleteMemberInput;
};


export type MutationDeletePackageArgs = {
  input: DeletePackageInput;
};


export type MutationDeleteShippingAddressArgs = {
  input: DeleteShippingAddressInput;
};


export type MutationDeleteShopAccountArgs = {
  input: DeleteShopAccountInput;
};


export type MutationDeleteShopCardArgs = {
  input: DeleteShopCardInput;
};


export type MutationDeleteStockOrderTemplateArgs = {
  input: DeleteStockOrderTemplateInput;
};


export type MutationDowngradeSubscriptionPlanArgs = {
  input: DowngradeSubscriptionPlanInput;
};


export type MutationLinkVehicleToCartArgs = {
  input: LinkVehicleToCartInput;
};


export type MutationMapBoschAccountArgs = {
  input: MapBoschAccountInput;
};


export type MutationReactivateSubscriptionAddonArgs = {
  input: ReactivateSubscriptionAddonInput;
};


export type MutationRemoveSubscriptionAddonArgs = {
  input: RemoveSubscriptionAddonInput;
};


export type MutationResendActivationEmailArgs = {
  input: ResendActivationEmailInput;
};


export type MutationResendInvitationArgs = {
  input: ResendInvitationInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSendInvitationArgs = {
  input: SendInvitationInput;
};


export type MutationSendResettingPasswordEmailArgs = {
  input: SendResettingPasswordEmailInput;
};


export type MutationSendUnsubscribeReasonsArgs = {
  input: SendUnsubscribeReasonsInput;
};


export type MutationSetAllowOrderEmailsArgs = {
  input: SetAllowOrderEmailsInput;
};


export type MutationSetCustomPreferredBrandsArgs = {
  input: SetCustomPreferredBrandsInput;
};


export type MutationSetInvitationPermissionsArgs = {
  input: SetInvitationPermissionsInput;
};


export type MutationSetMemberPermissionsArgs = {
  input: SetMemberPermissionsInput;
};


export type MutationSetOrderAsDeliveredArgs = {
  input: SetOrderAsDeliveredInput;
};


export type MutationSetPickupRadiusArgs = {
  input: SetPickupRadiusInput;
};


export type MutationSetPreferredBrandsModeArgs = {
  input: SetPreferredBrandsModeInput;
};


export type MutationSetShowRetailPricingArgs = {
  input: SetShowRetailPricingInput;
};


export type MutationSetStockOrderArgs = {
  input: SetStockOrderInput;
};


export type MutationSubmitActiveCartArgs = {
  input: SubmitActiveCartInput;
};


export type MutationToggleShowRetailPricingArgs = {
  input: ToggleShowRetailPricingInput;
};


export type MutationTransferAdminArgs = {
  input: TransferAdminInput;
};


export type MutationUnarchiveCartArgs = {
  input: UnarchiveCartInput;
};


export type MutationUnlinkVehicleFromCartArgs = {
  input: UnlinkVehicleFromCartInput;
};


export type MutationUpdateActiveCartItemQuantityArgs = {
  input: UpdateActiveCartItemQuantityInput;
};


export type MutationUpdateActiveCartLaborItemArgs = {
  input: UpdateActiveCartLaborItemInput;
};


export type MutationUpdateActiveCartLaborRateArgs = {
  input: UpdateActiveCartLaborRateInput;
};


export type MutationUpdateActiveCartOrderAdditionalFieldsArgs = {
  input: UpdateActiveCartOrderAdditionalFieldsInput;
};


export type MutationUpdateActiveCartOrderPaymentNotesArgs = {
  input: UpdateActiveCartOrderPaymentNotesInput;
};


export type MutationUpdateActiveCartOrderShippingMethodArgs = {
  input: UpdateActiveCartOrderShippingMethodInput;
};


export type MutationUpdateCartArgs = {
  input: UpdateCartInput;
};


export type MutationUpdateCartItemQuantityArgs = {
  input: UpdateCartItemQuantityInput;
};


export type MutationUpdateCartSupplierPreferredShippingMethodArgs = {
  input: UpdateCartSupplierPreferredShippingMethodInput;
};


export type MutationUpdateLaborRateArgs = {
  input: UpdateLaborRateInput;
};


export type MutationUpdateLastUseDateStockOrderTemplateArgs = {
  input: UpdateLastUseDateStockOrderTemplateInput;
};


export type MutationUpdateMatrixArgs = {
  input: UpdateMatrixInput;
};


export type MutationUpdatePackageArgs = {
  input: UpdatePackageInput;
};


export type MutationUpdateRetailPricingArgs = {
  input: UpdateRetailPricingInput;
};


export type MutationUpdateShippingAddressArgs = {
  input: UpdateShippingAddressInput;
};


export type MutationUpdateShopArgs = {
  input: UpdateShopInput;
};


export type MutationUpdateShopAccountArgs = {
  input: UpdateShopAccountInput;
};


export type MutationUpdateShopCardArgs = {
  input: UpdateShopCardInput;
};


export type MutationUpdateShopCartSettingsArgs = {
  input: UpdateShopCartSettingsInput;
};


export type MutationUpdateShopDetailsArgs = {
  input: UpdateShopDetailsInput;
};


export type MutationUpdateShopSmsSettingsArgs = {
  input: UpdateShopSmsSettingsInput;
};


export type MutationUpdateStockOrderTemplateArgs = {
  input: UpdateStockOrderTemplateInput;
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MutationUpdateTireQuoteArgs = {
  input: UpdateTireQuoteInput;
};


export type MutationUpdateUserInformationArgs = {
  input: UpdateUserInformationInput;
};


export type MutationUpdateUserPhoneNumberArgs = {
  input: UpdateUserPhoneNumberInput;
};


export type MutationUserAlreadyExistsArgs = {
  input: UserAlreadyExistsInput;
};


export type MutationValidateAddressesArgs = {
  input: ValidateAddressesInput;
};

export type Onboard = {
  __typename?: 'Onboard';
  status: OnboardStatus;
  type: OnboardType;
};

export enum OnboardStatus {
  Completed = 'COMPLETED',
  Initial = 'INITIAL',
  Skipped = 'SKIPPED'
}

export type OnboardStep = {
  __typename?: 'OnboardStep';
  step: OnboardStepType;
  value?: Maybe<Scalars['String']['output']>;
};

export enum OnboardStepType {
  Parts = 'PARTS',
  Phone = 'PHONE',
  PrimaryRole = 'PRIMARY_ROLE',
  Referral = 'REFERRAL',
  ShopType = 'SHOP_TYPE',
  Sms = 'SMS',
  Tires = 'TIRES'
}

export enum OnboardType {
  MultiplePeople = 'MULTIPLE_PEOPLE',
  OnePerson = 'ONE_PERSON',
  Sms = 'SMS',
  Suppliers = 'SUPPLIERS'
}

export type Operation = {
  __typename?: 'Operation';
  component: Scalars['String']['output'];
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  operation: Scalars['String']['output'];
  subgroup: Scalars['String']['output'];
};

export type OperationProcedure = {
  __typename?: 'OperationProcedure';
  error?: Maybe<Scalars['String']['output']>;
  operation: Operation;
  procedure?: Maybe<Procedure>;
};

export type Order = {
  __typename?: 'Order';
  billingAddress: Address;
  canMarkAsDelivered: Scalars['Boolean']['output'];
  card?: Maybe<Card>;
  confirmationNumbers: Array<Scalars['String']['output']>;
  credential?: Maybe<Account>;
  customNotes?: Maybe<Scalars['String']['output']>;
  customerNumber: Scalars['String']['output'];
  datePaid?: Maybe<Scalars['DateTime']['output']>;
  delivery?: Maybe<OrderDelivery>;
  discountTotal: Scalars['BigDecimal']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  errorType?: Maybe<RecentOrderErrorType>;
  fetChargeTotal: Scalars['BigDecimal']['output'];
  grandTotal: Scalars['BigDecimal']['output'];
  hash: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastTrackingDate?: Maybe<Scalars['DateTime']['output']>;
  lines?: Maybe<Array<OrderItem>>;
  paymentWay: PaymentWay;
  purchaseOrderNumber: Scalars['String']['output'];
  shippingAddress: Address;
  shippingMethod: Scalars['String']['output'];
  shippingTotal: Scalars['BigDecimal']['output'];
  shippings?: Maybe<Array<ShippingPackage>>;
  shop?: Maybe<Shop>;
  status: OrderStatus;
  store?: Maybe<Store>;
  supplier?: Maybe<Supplier>;
  taxTotal: Scalars['BigDecimal']['output'];
  trackingStatus: TrackingStatus;
  user?: Maybe<User>;
};

export type OrderAdditionalField = {
  __typename?: 'OrderAdditionalField';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OrderBoschReward = {
  __typename?: 'OrderBoschReward';
  bonus: Scalars['Int']['output'];
  points: Scalars['Int']['output'];
  program: Scalars['String']['output'];
};

export type OrderDelivery = {
  __typename?: 'OrderDelivery';
  deliveryDate?: Maybe<Scalars['DateTime']['output']>;
  deliverySource: DeliverySource;
};

export type OrderError = {
  __typename?: 'OrderError';
  errorType: OrderErrorType;
  message: Scalars['String']['output'];
};

export enum OrderErrorType {
  AdditionalFields = 'ADDITIONAL_FIELDS',
  Integration = 'INTEGRATION',
  Outage = 'OUTAGE',
  Outdated = 'OUTDATED',
  Shipping = 'SHIPPING'
}

export type OrderFieldValidation = {
  __typename?: 'OrderFieldValidation';
  maxLength?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  minLength?: Maybe<Scalars['Int']['output']>;
  regex?: Maybe<Scalars['String']['output']>;
};

export type OrderFieldValidationError = {
  __typename?: 'OrderFieldValidationError';
  /** Validation error message. */
  errorMessage: Scalars['String']['output'];
  /** Name of field. */
  field: Scalars['String']['output'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  bundled: Scalars['Boolean']['output'];
  coreCharge: Scalars['BigDecimal']['output'];
  delivery?: Maybe<OrderItemDelivery>;
  displayPartNumber?: Maybe<Scalars['String']['output']>;
  ediStatus?: Maybe<RecentOrderItemEdiStatus>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  errorType?: Maybe<RecentOrderItemErrorType>;
  etas: Array<OrderItemEta>;
  expectedAvailabilityType?: Maybe<ExpectedAvailabilityType>;
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  lineCardId?: Maybe<Scalars['Int']['output']>;
  orderedLaborItems?: Maybe<Array<CartLaborItem>>;
  partName: Scalars['String']['output'];
  partNumber: Scalars['String']['output'];
  partNumberId: Scalars['String']['output'];
  partType?: Maybe<PartType>;
  price: Scalars['BigDecimal']['output'];
  quantity: Scalars['Int']['output'];
  returnAction?: Maybe<ReturnAction>;
  returns?: Maybe<OrderItemReturn>;
  rewardPoints?: Maybe<Scalars['Int']['output']>;
  status: OrderItemStatus;
  subtotal: Scalars['BigDecimal']['output'];
  supplierNotes?: Maybe<Scalars['String']['output']>;
  tireSize?: Maybe<Scalars['String']['output']>;
  totalDiscount: Scalars['BigDecimal']['output'];
  trackingNumbers: Array<Scalars['String']['output']>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<Vehicle>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type OrderItemDelivery = {
  __typename?: 'OrderItemDelivery';
  deliveryDate?: Maybe<Scalars['DateTime']['output']>;
  deliverySource: DeliverySource;
};

export type OrderItemEta = {
  __typename?: 'OrderItemETA';
  label?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type OrderItemError = {
  __typename?: 'OrderItemError';
  errorType: OrderItemErrorType;
  message: Scalars['String']['output'];
};

export enum OrderItemErrorType {
  Availability = 'AVAILABILITY',
  DisplayAction = 'DISPLAY_ACTION',
  Outdated = 'OUTDATED',
  OutOfStock = 'OUT_OF_STOCK',
  Quantity = 'QUANTITY',
  QuantityStep = 'QUANTITY_STEP',
  SpecialOrder = 'SPECIAL_ORDER'
}

export type OrderItemProduct = {
  __typename?: 'OrderItemProduct';
  attributes: Array<OrderItemProductAttribute>;
  availabilityLines: Array<OrderItemProductAvailabilityLine>;
  backOrderable?: Maybe<Scalars['Boolean']['output']>;
  coreCharge?: Maybe<Scalars['Float']['output']>;
  customerPrice?: Maybe<Scalars['Float']['output']>;
  deliveryNotes: Array<OrderItemProductDeliveryNotes>;
  fetCharge?: Maybe<Scalars['Float']['output']>;
  listPrice?: Maybe<Scalars['Float']['output']>;
  mapPrice?: Maybe<Scalars['Float']['output']>;
  notes: Array<OrderItemProductNotes>;
  partNumberDisplay: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  quantityStep: Scalars['Int']['output'];
  specialOrder?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  warnings: Array<OrderItemProductWarning>;
};

export type OrderItemProductAttachmentWarning = {
  __typename?: 'OrderItemProductAttachmentWarning';
  attachment: Scalars['String']['output'];
};

export type OrderItemProductAttribute = {
  __typename?: 'OrderItemProductAttribute';
  name?: Maybe<Scalars['String']['output']>;
  value: Array<Scalars['String']['output']>;
};

export type OrderItemProductAvailabilityLine = {
  __typename?: 'OrderItemProductAvailabilityLine';
  address?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  rank?: Maybe<Scalars['Int']['output']>;
  type: OrderItemProductAvailabilityLineType;
};

export enum OrderItemProductAvailabilityLineType {
  Main = 'MAIN',
  Network = 'NETWORK'
}

export type OrderItemProductDeliveryNotes = {
  __typename?: 'OrderItemProductDeliveryNotes';
  label?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type OrderItemProductImageWarning = {
  __typename?: 'OrderItemProductImageWarning';
  path: Scalars['String']['output'];
};

export type OrderItemProductNotes = {
  __typename?: 'OrderItemProductNotes';
  notes: Array<Scalars['String']['output']>;
};

export type OrderItemProductTextWarning = {
  __typename?: 'OrderItemProductTextWarning';
  text: Scalars['String']['output'];
};

export type OrderItemProductWarning = OrderItemProductAttachmentWarning | OrderItemProductImageWarning | OrderItemProductTextWarning;

export type OrderItemReturn = {
  __typename?: 'OrderItemReturn';
  address?: Maybe<Address>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  reason: Scalars['String']['output'];
  refund: Refund;
  returnAuthNumber?: Maybe<Scalars['String']['output']>;
  returnType: ReturnType;
  state?: Maybe<ReturnState>;
  statusMessage?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum OrderItemSponsorType {
  None = 'NONE',
  PotentiallySponsored = 'POTENTIALLY_SPONSORED',
  Sponsored = 'SPONSORED'
}

export enum OrderItemStatus {
  Cancelled = 'CANCELLED',
  CancelApproved = 'CANCEL_APPROVED',
  CancelInitiated = 'CANCEL_INITIATED',
  Delivered = 'DELIVERED',
  New = 'NEW',
  NotShipped = 'NOT_SHIPPED',
  Returned = 'RETURNED',
  ReturnApproved = 'RETURN_APPROVED',
  ReturnInitiated = 'RETURN_INITIATED',
  Shipped = 'SHIPPED'
}

export enum OrderPaidStatus {
  Full = 'FULL',
  New = 'NEW',
  Partially = 'PARTIALLY'
}

export enum OrderPaymentWay {
  CreditCard = 'CREDIT_CARD',
  Wholesale = 'WHOLESALE'
}

export enum OrderStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  Returned = 'RETURNED'
}

export enum OrderType {
  Backorder = 'BACKORDER',
  CheckAvailability = 'CHECK_AVAILABILITY',
  None = 'NONE',
  Regular = 'REGULAR',
  SpecialOrder = 'SPECIAL_ORDER'
}

export type OrderingStatistic = {
  __typename?: 'OrderingStatistic';
  lastPaidDate?: Maybe<Scalars['DateTime']['output']>;
  totalPaidOrders: Scalars['Int']['output'];
};

export type Outage = {
  __typename?: 'Outage';
  message?: Maybe<Scalars['String']['output']>;
  type: OutageType;
};

export enum OutageType {
  FullOutage = 'FULL_OUTAGE',
  PartialOutage = 'PARTIAL_OUTAGE'
}

export type Package = {
  __typename?: 'Package';
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  items: Array<PackageItem>;
  name: Scalars['String']['output'];
};

export type PackageItem = Addon | Discount | Disposal | Fee | Labor;

export type PackageItemInput = {
  addon?: InputMaybe<AddonInput>;
  discount?: InputMaybe<DiscountInput>;
  disposal?: InputMaybe<DisposalInput>;
  fee?: InputMaybe<FeeInput>;
  fet?: InputMaybe<FetInput>;
  labor?: InputMaybe<LaborInput>;
  type: PackageItemType;
};

export enum PackageItemType {
  Addon = 'ADDON',
  Discount = 'DISCOUNT',
  Disposal = 'DISPOSAL',
  Fee = 'FEE',
  Fet = 'FET',
  Labor = 'LABOR'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PartCategory = {
  __typename?: 'PartCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  subCategories?: Maybe<Array<PartSubCategory>>;
  subCategory?: Maybe<PartSubCategory>;
};


export type PartCategorySubCategoryArgs = {
  subCategoryID: Scalars['ID']['input'];
};

export type PartChart = {
  __typename?: 'PartChart';
  partTypesCount: Scalars['Int']['output'];
  totalOrders: Scalars['Int']['output'];
  totalPrice: Scalars['BigDecimal']['output'];
  totalQuantity: Scalars['Int']['output'];
};

export type PartDetails = {
  __typename?: 'PartDetails';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  partType?: Maybe<PartType>;
};

export type PartNumberSearchInput = {
  accountId: Scalars['ID']['input'];
  partNumber: Scalars['String']['input'];
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
};

export enum PartReportSortBy {
  TotalPrice = 'TOTAL_PRICE',
  TotalQuantity = 'TOTAL_QUANTITY'
}

export type PartReports = {
  __typename?: 'PartReports';
  partChart?: Maybe<PartChart>;
  partProducts?: Maybe<Array<ReportProduct>>;
  partStores?: Maybe<Array<Store>>;
  partType?: Maybe<PartTypesStatistic>;
  partTypes?: Maybe<ReportPartTypes>;
};


export type PartReportsPartChartArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate: Scalars['DateTime']['input'];
  storeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type PartReportsPartProductsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  partTypeId: Scalars['ID']['input'];
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PartReportSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  startDate: Scalars['DateTime']['input'];
  storeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type PartReportsPartTypeArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  partTypeId: Scalars['ID']['input'];
  startDate: Scalars['DateTime']['input'];
  storeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type PartReportsPartTypesArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<PartReportSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  startDate: Scalars['DateTime']['input'];
  storeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PartSubCategory = {
  __typename?: 'PartSubCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  partTypes?: Maybe<Array<PartType>>;
};

export type PartType = {
  __typename?: 'PartType';
  aliases?: Maybe<Array<Scalars['String']['output']>>;
  application: PartTypeApplication;
  attributes?: Maybe<Array<PartTypeAttribute>>;
  category?: Maybe<PartCategory>;
  /** Get a part type attribute that depends on previous attribute values. */
  dependentAttribute?: Maybe<PartTypeAttribute>;
  id: Scalars['ID']['output'];
  linkedPartTypes?: Maybe<Array<PartType>>;
  name: Scalars['String']['output'];
  subCategory?: Maybe<PartSubCategory>;
};


export type PartTypeAttributesArgs = {
  requiredOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PartTypeDependentAttributeArgs = {
  attributeName: Scalars['String']['input'];
  previousAttributes: Array<PreviousAttribute>;
  vehicleID?: InputMaybe<Scalars['ID']['input']>;
};

export enum PartTypeApplication {
  Fitted = 'FITTED',
  Universal = 'UNIVERSAL',
  UniversalFitted = 'UNIVERSAL_FITTED'
}

export type PartTypeAttribute = {
  __typename?: 'PartTypeAttribute';
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export type PartTypeAttributeSearchInput = {
  accountId: Scalars['ID']['input'];
  attributes?: InputMaybe<Array<AttributeInput>>;
  jobId?: InputMaybe<Scalars['ID']['input']>;
  partTypeIds: Array<Scalars['ID']['input']>;
  universal?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type PartTypeGroup = {
  __typename?: 'PartTypeGroup';
  aliases?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  partTypes?: Maybe<Array<PartType>>;
};

export type PartTypesStatistic = {
  __typename?: 'PartTypesStatistic';
  partType?: Maybe<PartType>;
  totalOrders: Scalars['Int']['output'];
  totalPrice: Scalars['BigDecimal']['output'];
  totalQuantity: Scalars['Int']['output'];
};

export type Partner = {
  __typename?: 'Partner';
  catalogSdk: Scalars['Boolean']['output'];
  generalButtons: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  managementSystem?: Maybe<ManagementSystem>;
  name: Scalars['String']['output'];
  shutOffPoNumbers: Scalars['Boolean']['output'];
  supplierButtons: Scalars['Boolean']['output'];
};

export type PaymentNotesInput = {
  /** Custom notes. */
  customNotes?: InputMaybe<Scalars['String']['input']>;
  /** Purchase order number. */
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum PaymentWay {
  CreditCard = 'CREDIT_CARD',
  Wholesale = 'WHOLESALE'
}

export type PercentageAmount = {
  __typename?: 'PercentageAmount';
  max?: Maybe<Scalars['BigDecimal']['output']>;
  min?: Maybe<Scalars['BigDecimal']['output']>;
  perQuantity: Scalars['Boolean']['output'];
};

export type PercentageAmountInput = {
  max?: InputMaybe<Scalars['BigDecimal']['input']>;
  min?: InputMaybe<Scalars['BigDecimal']['input']>;
  perQuantity: Scalars['Boolean']['input'];
};

export type Position = {
  __typename?: 'Position';
  name: Scalars['String']['output'];
  pcdbPositionId: Scalars['ID']['output'];
  positionId: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type PreferredBrands = {
  __typename?: 'PreferredBrands';
  brand?: Maybe<Brand>;
  brands?: Maybe<Array<Brand>>;
  partType?: Maybe<PartType>;
  purchaseCount: Scalars['Int']['output'];
};

export type PreferredBrandsConnection = {
  __typename?: 'PreferredBrandsConnection';
  edges?: Maybe<Array<Maybe<PreferredBrandsEdge>>>;
  pageInfo: PageInfo;
};

export type PreferredBrandsEdge = {
  __typename?: 'PreferredBrandsEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<PreferredBrands>;
};

export enum PreferredBrandsMode {
  Automatic = 'AUTOMATIC',
  Custom = 'CUSTOM',
  Disable = 'DISABLE'
}

export type PreviousAttribute = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Procedure = {
  __typename?: 'Procedure';
  applicationGroups: Array<ApplicationGroup>;
  component: Scalars['String']['output'];
  group: Scalars['String']['output'];
  operation: Scalars['String']['output'];
  skillLevel?: Maybe<Scalars['String']['output']>;
  subgroup: Scalars['String']['output'];
};

export type ProcedureInformation = {
  __typename?: 'ProcedureInformation';
  component: Scalars['String']['output'];
  items: Array<Mitchell1LaborItem>;
  operation: Scalars['String']['output'];
  procedureNote?: Maybe<Scalars['String']['output']>;
  skillLevel?: Maybe<Scalars['String']['output']>;
};

export type Product = {
  __typename?: 'Product';
  account?: Maybe<Account>;
  attachments: Array<ProductAttachment>;
  attributes: Array<ProductAttribute>;
  availability: Array<ProductAvailabilityLine>;
  brand?: Maybe<Brand>;
  /** @deprecated No longer supported */
  bundleAvailable: Scalars['Boolean']['output'];
  bundles: Array<Bundle>;
  coreCharge?: Maybe<Scalars['BigDecimal']['output']>;
  customerPrice?: Maybe<Scalars['BigDecimal']['output']>;
  deliveryNotes: Array<ProductDeliveryNote>;
  descriptions: Array<ProductDescription>;
  diagrams?: Maybe<Array<Diagram>>;
  extendedInformation: Array<ProductExtendedInformation>;
  fetCharge?: Maybe<Scalars['BigDecimal']['output']>;
  freightPackage?: Maybe<ProductFreightPackage>;
  hasFitment: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image360Groups: Array<ProductImage360Group>;
  images: Array<ProductImage>;
  interchangeFor?: Maybe<ProductInterchange>;
  interchanges?: Maybe<Array<ProductInterchange>>;
  lineCard?: Maybe<LineCard>;
  links: Array<ProductLink>;
  listPrice?: Maybe<Scalars['BigDecimal']['output']>;
  mapPrice?: Maybe<Scalars['BigDecimal']['output']>;
  marketingDescriptions: Array<Scalars['String']['output']>;
  notes: Array<ProductNotes>;
  notesOem: Array<Scalars['String']['output']>;
  orderType: OrderType;
  partNumber: Scalars['String']['output'];
  partNumberId: Scalars['String']['output'];
  partType?: Maybe<PartType>;
  price?: Maybe<Scalars['BigDecimal']['output']>;
  productAttributes: Array<ProductAttribute>;
  quantityStep: Scalars['Int']['output'];
  rebates: Array<ProductRebate>;
  rewardPoints?: Maybe<RewardPoints>;
  sale: Scalars['Boolean']['output'];
  shippingCharge: Scalars['Boolean']['output'];
  sortHash?: Maybe<Scalars['String']['output']>;
  specificMarketingDescriptions: Array<ProductDescription>;
  sponsorType: SponsorType;
  sponsoredPurchasesCount?: Maybe<Scalars['Int']['output']>;
  stocked: Scalars['Boolean']['output'];
  store?: Maybe<Store>;
  title: Scalars['String']['output'];
  variations: Array<ProductVariation>;
  vehicle?: Maybe<Vehicle>;
  videos: Array<ProductVideo>;
  warnings: Array<ProductWarning>;
};

export type ProductAttachment = {
  __typename?: 'ProductAttachment';
  filename: Scalars['String']['output'];
  group?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type ProductAttribute = {
  __typename?: 'ProductAttribute';
  name: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type ProductAvailabilityLine = {
  __typename?: 'ProductAvailabilityLine';
  address?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  type: ProductAvailabilityLineType;
};

export enum ProductAvailabilityLineType {
  Main = 'MAIN',
  Network = 'NETWORK'
}

export type ProductDeliveryNote = {
  __typename?: 'ProductDeliveryNote';
  label: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type ProductDescription = {
  __typename?: 'ProductDescription';
  code: Scalars['String']['output'];
  label: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type ProductDimensions = {
  __typename?: 'ProductDimensions';
  height: Scalars['Float']['output'];
  length: Scalars['Float']['output'];
  width: Scalars['Float']['output'];
};

export type ProductExtendedInformation = {
  __typename?: 'ProductExtendedInformation';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ProductFreightPackage = {
  __typename?: 'ProductFreightPackage';
  merchandising?: Maybe<ProductDimensions>;
  shipping?: Maybe<ProductDimensions>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  full: Scalars['String']['output'];
  medium: Scalars['String']['output'];
  preview: Scalars['String']['output'];
};

export type ProductImage360Group = {
  __typename?: 'ProductImage360Group';
  images: Array<ProductImage>;
};

export type ProductInterchange = {
  __typename?: 'ProductInterchange';
  brandName?: Maybe<Scalars['String']['output']>;
  partNumber: Scalars['String']['output'];
  type: ProductInterchangeType;
};

export enum ProductInterchangeType {
  Alternate = 'ALTERNATE',
  Interchange = 'INTERCHANGE',
  Sibling = 'SIBLING',
  Supersede = 'SUPERSEDE'
}

export type ProductLink = {
  __typename?: 'ProductLink';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ProductNotes = {
  __typename?: 'ProductNotes';
  group: Array<Scalars['String']['output']>;
};

export type ProductRebate = {
  __typename?: 'ProductRebate';
  couponLink?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  legalLink?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['BigDecimal']['output']>;
  title: Scalars['String']['output'];
  validDate?: Maybe<Scalars['String']['output']>;
};

export enum ProductType {
  Part = 'PART',
  Tire = 'TIRE'
}

export type ProductVariation = {
  __typename?: 'ProductVariation';
  attributes: Array<ProductAttribute>;
  notes: Array<ProductNotes>;
  variationID: Scalars['String']['output'];
};

export type ProductVideo = {
  __typename?: 'ProductVideo';
  full: Scalars['String']['output'];
  medium: Scalars['String']['output'];
  preview: Scalars['String']['output'];
};

export type ProductWarning = ProductWarningAttachment | ProductWarningImage | ProductWarningText;

export type ProductWarningAttachment = {
  __typename?: 'ProductWarningAttachment';
  url: Scalars['String']['output'];
};

export type ProductWarningImage = {
  __typename?: 'ProductWarningImage';
  url: Scalars['String']['output'];
};

export type ProductWarningText = {
  __typename?: 'ProductWarningText';
  text: Scalars['String']['output'];
};

export type ProductsResult = {
  __typename?: 'ProductsResult';
  errors: Array<Scalars['String']['output']>;
  products: Array<Product>;
};

export type Punchout = {
  __typename?: 'Punchout';
  punchoutEnabled: Scalars['Boolean']['output'];
  session?: Maybe<PunchoutSession>;
};

export type PunchoutSession = {
  __typename?: 'PunchoutSession';
  callbackOrderUrl?: Maybe<Scalars['String']['output']>;
  callbackUrl?: Maybe<Scalars['String']['output']>;
  hasReservedPoNumbers: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  partner?: Maybe<Partner>;
  purchaseOrderNumber?: Maybe<Scalars['String']['output']>;
  sessionHash: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  /** Get current active cart. */
  activeCart?: Maybe<Cart>;
  /** Application configuration. */
  appConfig?: Maybe<AppConfig>;
  /** Returns batch Mitchell1 labors application by vehicle_id and part_type_id. */
  batchMitchell1Labor?: Maybe<BatchMitchell1LaborPayload>;
  /** Returns batch summaries information for estimated work time applications by vehicle_id and part_type_id. */
  batchWorkTimesSummary?: Maybe<BatchWorkTimesSummariesPayload>;
  /** Get brands. */
  brands?: Maybe<Array<Brand>>;
  /** Get specific cart. */
  cart?: Maybe<Cart>;
  /** List carts. */
  carts?: Maybe<CartConnection>;
  catalogProducts?: Maybe<Array<Product>>;
  /** List of part categories. */
  categories?: Maybe<Array<PartCategory>>;
  /** Get a category. */
  category?: Maybe<PartCategory>;
  /** Current active user */
  currentUser?: Maybe<User>;
  /** Get invitation. */
  invitationInfo?: Maybe<InvitationInfo>;
  /** Check if text is part number. */
  isPartNumber?: Maybe<Scalars['Boolean']['output']>;
  /** Get management systems. */
  managementSystems?: Maybe<Array<ManagementSystem>>;
  /** Get order by hash. */
  order?: Maybe<Order>;
  /** Part reports. */
  partReports?: Maybe<PartReports>;
  /** Get part type group (legacy job). */
  partTypeGroup?: Maybe<PartTypeGroup>;
  /** Get part types. */
  partTypes?: Maybe<Array<PartType>>;
  /** Get popular part types. */
  popularPartTypes?: Maybe<Array<PartType>>;
  product?: Maybe<Product>;
  products?: Maybe<ProductsResult>;
  /** Punchout Info. */
  punchout?: Maybe<Punchout>;
  signUpEmailSentDate?: Maybe<Scalars['DateTime']['output']>;
  /** Sign up. */
  signUpGoogleAuthUri?: Maybe<Scalars['String']['output']>;
  /** Sign up from sms. */
  smsRegistrationData?: Maybe<SmsRegistrationDataResponse>;
  /** Spend Reports. */
  spendReports?: Maybe<SpendReports>;
  /**
   * Get states.
   * The country param is optional.
   * If the country param is omitted, returns results for the USA.
   */
  states?: Maybe<Array<State>>;
  /** Stock Order. */
  stockOrder?: Maybe<StockOrder>;
  /** Get active system alert. */
  systemAlert?: Maybe<SystemAlert>;
  /** Get tire quote. */
  tireQuote?: Maybe<TireQuote>;
  /** Tire Reports. */
  tireReports?: Maybe<TireReports>;
  /** Get tire sizes. */
  tireSizes?: Maybe<Array<TireSize>>;
  /** Search typeahead. */
  typeahead?: Maybe<Array<SearchResultItem>>;
  /**
   * Get a vehicle.
   * Should be used vehicle id or vin param.
   */
  vehicle?: Maybe<Vehicle>;
  vehicleMakeGroups?: Maybe<Array<VehicleMakeGroup>>;
  vehicleSuggest?: Maybe<Array<VehicleSuggest>>;
  /**
   * Get vehicles.
   * Should be used year/makeID or plate param.
   */
  vehicles?: Maybe<Array<Vehicle>>;
};


export type QueryBatchMitchell1LaborArgs = {
  batchMitchell1LaborInput?: InputMaybe<BatchMitchell1LaborInput>;
};


export type QueryBatchWorkTimesSummaryArgs = {
  batchWorkTimesSummariesInput?: InputMaybe<BatchWorkTimesSummaryInput>;
};


export type QueryBrandsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  productType?: InputMaybe<ProductType>;
};


export type QueryCartArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCartsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
  statuses?: InputMaybe<Array<CartStatusFilter>>;
};


export type QueryCatalogProductsArgs = {
  searchInput: CatalogSearchInput;
};


export type QueryCategoryArgs = {
  categoryID: Scalars['ID']['input'];
};


export type QueryInvitationInfoArgs = {
  token: Scalars['String']['input'];
};


export type QueryIsPartNumberArgs = {
  search: Scalars['String']['input'];
};


export type QueryOrderArgs = {
  hash: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryPartTypeGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPartTypesArgs = {
  IDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  categoryID?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProductArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  lineCardId?: InputMaybe<Scalars['ID']['input']>;
  partNumberId: Scalars['String']['input'];
  partTypeId?: InputMaybe<Scalars['ID']['input']>;
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProductsArgs = {
  searchInput: SearchInput;
};


export type QuerySignUpEmailSentDateArgs = {
  usernameOrEmail: Scalars['String']['input'];
};


export type QuerySignUpGoogleAuthUriArgs = {
  selectedSms?: InputMaybe<Scalars['String']['input']>;
  utmParams?: InputMaybe<Array<UtmParamInput>>;
};


export type QuerySmsRegistrationDataArgs = {
  hash: Scalars['String']['input'];
};


export type QueryStatesArgs = {
  country?: InputMaybe<Country>;
};


export type QueryTireQuoteArgs = {
  hash: Scalars['String']['input'];
};


export type QueryTypeaheadArgs = {
  search: Scalars['String']['input'];
};


export type QueryVehicleArgs = {
  vehicleID: Scalars['ID']['input'];
};


export type QueryVehicleSuggestArgs = {
  search: Scalars['String']['input'];
};


export type QueryVehiclesArgs = {
  makeID?: InputMaybe<Scalars['ID']['input']>;
  plate?: InputMaybe<LicensePlate>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type ReactivateSubscriptionAddonInput = {
  planId: Scalars['ID']['input'];
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type ReactivateSubscriptionAddonPayload = {
  __typename?: 'ReactivateSubscriptionAddonPayload';
  shopSubscription?: Maybe<ShopSubscription>;
};

export type ReactivateSubscriptionPlanPayload = {
  __typename?: 'ReactivateSubscriptionPlanPayload';
  shopSubscription?: Maybe<ShopSubscription>;
};

export type RecentOrderConnection = {
  __typename?: 'RecentOrderConnection';
  edges?: Maybe<Array<Maybe<RecentOrderEdge>>>;
  pageInfo: PageInfo;
};

export type RecentOrderEdge = {
  __typename?: 'RecentOrderEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Order>;
};

export enum RecentOrderErrorType {
  Other = 'OTHER',
  OverCreditLimit = 'OVER_CREDIT_LIMIT'
}

export enum RecentOrderItemEdiStatus {
  Error = 'ERROR',
  Ok = 'OK'
}

export enum RecentOrderItemErrorType {
  Other = 'OTHER',
  OutOfStock = 'OUT_OF_STOCK'
}

export type RecentSearch = {
  __typename?: 'RecentSearch';
  id: Scalars['ID']['output'];
  params: RecentSearchVehicle;
  type: RecentSearchType;
};

export type RecentSearchAttribute = {
  __typename?: 'RecentSearchAttribute';
  name: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type RecentSearchAttributeInput = {
  name: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type RecentSearchCategories = RecentSearchVehicle & {
  __typename?: 'RecentSearchCategories';
  manufacturers?: Maybe<Array<Scalars['String']['output']>>;
  partCategory?: Maybe<PartCategory>;
  partSubCategory?: Maybe<PartSubCategory>;
  partText?: Maybe<RecentSearchPartText>;
  partTypes?: Maybe<Array<PartType>>;
  plate?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<Vehicle>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type RecentSearchKeyword = RecentSearchVehicle & {
  __typename?: 'RecentSearchKeyword';
  manufacturers?: Maybe<Array<Scalars['String']['output']>>;
  partText?: Maybe<RecentSearchPartText>;
  plate?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<Vehicle>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type RecentSearchNonApp = RecentSearchVehicle & {
  __typename?: 'RecentSearchNonApp';
  attributes?: Maybe<Array<RecentSearchAttribute>>;
  manufacturers?: Maybe<Array<Scalars['String']['output']>>;
  partText?: Maybe<RecentSearchPartText>;
  partTypes?: Maybe<Array<PartType>>;
  plate?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<Vehicle>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type RecentSearchPartNumber = RecentSearchVehicle & {
  __typename?: 'RecentSearchPartNumber';
  manufacturers?: Maybe<Array<Scalars['String']['output']>>;
  partNumber: Scalars['String']['output'];
  plate?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<Vehicle>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type RecentSearchPartText = {
  __typename?: 'RecentSearchPartText';
  id?: Maybe<Scalars['ID']['output']>;
  text: Scalars['String']['output'];
};

export type RecentSearchPartTextInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

export type RecentSearchTire = {
  __typename?: 'RecentSearchTire';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rearSize?: Maybe<TireSize>;
  size: TireSize;
};

export type RecentSearchTireInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rearSize?: InputMaybe<TireSizeInput>;
  size: TireSizeInput;
};

export type RecentSearchTires = RecentSearchVehicle & {
  __typename?: 'RecentSearchTires';
  partNumber?: Maybe<Scalars['String']['output']>;
  plate?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  tire?: Maybe<RecentSearchTire>;
  vehicle?: Maybe<Vehicle>;
  vin?: Maybe<Scalars['String']['output']>;
};

export enum RecentSearchType {
  Categories = 'CATEGORIES',
  Keyword = 'KEYWORD',
  NonApp = 'NON_APP',
  PartNumber = 'PART_NUMBER',
  Tires = 'TIRES'
}

export type RecentSearchVehicle = {
  plate?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<Vehicle>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type RecentVehicle = {
  __typename?: 'RecentVehicle';
  id: Scalars['ID']['output'];
  plate?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<Vehicle>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type RecentlyViewedPart = {
  __typename?: 'RecentlyViewedPart';
  id: Scalars['ID']['output'];
  partImage: Scalars['String']['output'];
  partNumber: Scalars['String']['output'];
  partTitle: Scalars['String']['output'];
  rewardPoints?: Maybe<RewardPoints>;
  urlParams: RecentlyViewedPartUrlParams;
};

export type RecentlyViewedPartUrlParams = {
  __typename?: 'RecentlyViewedPartUrlParams';
  accountId?: Maybe<Scalars['Int']['output']>;
  lineCardId?: Maybe<Scalars['Int']['output']>;
  partNumberId: Scalars['String']['output'];
  partTypeId?: Maybe<Scalars['Int']['output']>;
  supplierId?: Maybe<Scalars['Int']['output']>;
  vehicleId?: Maybe<Scalars['Int']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type Refund = {
  __typename?: 'Refund';
  hasSuccessful: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  totalAmount: Scalars['BigDecimal']['output'];
};

export type RegisterBoschAccountPayload = {
  __typename?: 'RegisterBoschAccountPayload';
  success: Scalars['Boolean']['output'];
};

export enum RegistrationDataError {
  ErrorEmailRecognizedNoShop = 'ERROR_EMAIL_RECOGNIZED_NO_SHOP'
}

export type RemoveShopLogoPayload = {
  __typename?: 'RemoveShopLogoPayload';
  shop?: Maybe<Shop>;
};

export type RemoveSubscriptionAddonInput = {
  planId: Scalars['ID']['input'];
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type RemoveSubscriptionAddonPayload = {
  __typename?: 'RemoveSubscriptionAddonPayload';
  shopSubscription?: Maybe<ShopSubscription>;
};

export type RemoveUserAvatarPayload = {
  __typename?: 'RemoveUserAvatarPayload';
  user?: Maybe<User>;
};

export enum ReportGrouping {
  Day = 'DAY',
  Month = 'MONTH'
}

export type ReportPartTypes = {
  __typename?: 'ReportPartTypes';
  partTypesStatistics: Array<PartTypesStatistic>;
  totalCount: Scalars['Int']['output'];
};

export type ReportProduct = {
  __typename?: 'ReportProduct';
  account?: Maybe<Account>;
  brand?: Maybe<Brand>;
  dateOfFirstPurchase: Scalars['DateTime']['output'];
  dateOfLastPurchase: Scalars['DateTime']['output'];
  displayPartNumber?: Maybe<Scalars['String']['output']>;
  image: Scalars['String']['output'];
  lineCardId?: Maybe<Scalars['Int']['output']>;
  mostCommonSupplier?: Maybe<Supplier>;
  partNumber: Scalars['String']['output'];
  partNumberId: Scalars['String']['output'];
  partType?: Maybe<PartType>;
  supplier?: Maybe<Supplier>;
  title: Scalars['String']['output'];
  totalOrders: Scalars['Int']['output'];
  totalPrice: Scalars['BigDecimal']['output'];
  totalQuantity: Scalars['Int']['output'];
  vehicle?: Maybe<Vehicle>;
};

export type ReportTireSizes = {
  __typename?: 'ReportTireSizes';
  statistic: Array<TireSizesStatistic>;
  totalCount: Scalars['Int']['output'];
};

export type ResendActivationEmailByRegistrationInput = {
  newEmail: Scalars['String']['input'];
  oldEmail: Scalars['String']['input'];
  selectedSms?: InputMaybe<Scalars['String']['input']>;
};

export type ResendActivationEmailByUsernameOrEmailInput = {
  usernameOrEmail: Scalars['String']['input'];
};

export type ResendActivationEmailInput = {
  registration?: InputMaybe<ResendActivationEmailByRegistrationInput>;
  usernameOrEmail?: InputMaybe<ResendActivationEmailByUsernameOrEmailInput>;
};

export type ResendActivationEmailPayload = {
  __typename?: 'ResendActivationEmailPayload';
  success: Scalars['Boolean']['output'];
};

export type ResendInvitationInput = {
  id: Scalars['ID']['input'];
};

export type ResendInvitationPayload = {
  __typename?: 'ResendInvitationPayload';
  invitation?: Maybe<Invitation>;
};

export type ResetPasswordInput = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  success: Scalars['Boolean']['output'];
};

export type RetailPricing = {
  __typename?: 'RetailPricing';
  id: Scalars['ID']['output'];
  matrices?: Maybe<Array<Matrix>>;
  packages?: Maybe<Array<Package>>;
  taxRate: Scalars['BigDecimal']['output'];
};

export enum ReturnAction {
  Cancel = 'CANCEL',
  None = 'NONE',
  Return = 'RETURN'
}

export enum ReturnAuthSetting {
  Rga = 'RGA',
  Rma = 'RMA'
}

export enum ReturnState {
  Approved = 'APPROVED',
  Attempted = 'ATTEMPTED',
  Closed = 'CLOSED',
  Declined = 'DECLINED',
  Initiated = 'INITIATED',
  Refund = 'REFUND'
}

export enum ReturnType {
  Cancel = 'CANCEL',
  Return = 'RETURN'
}

export type RewardPoints = {
  __typename?: 'RewardPoints';
  isPromotion: Scalars['Boolean']['output'];
  points: Scalars['Int']['output'];
};

export type SearchInput = {
  category?: InputMaybe<CategorySearchInput>;
  keyword?: InputMaybe<KeywordSearchInput>;
  partNumber?: InputMaybe<PartNumberSearchInput>;
  partTypeAttribute?: InputMaybe<PartTypeAttributeSearchInput>;
  tireApplicationStaggered?: InputMaybe<TireApplicationStaggeredSearchInput>;
  tireSize?: InputMaybe<TireSizeSearchInput>;
};

export type SearchItem = GroupedPartNumber | PartType | PartTypeGroup;

export type SearchResultItem = {
  __typename?: 'SearchResultItem';
  item: SearchItem;
  suggested: Scalars['Boolean']['output'];
};

export type SendInvitationInput = {
  email: Scalars['String']['input'];
  permissions?: InputMaybe<MemberPermissionsInput>;
};

export type SendInvitationPayload = {
  __typename?: 'SendInvitationPayload';
  invitation?: Maybe<Invitation>;
};

export enum SendResettingPasswordEmailError {
  UserDisabled = 'USER_DISABLED'
}

export type SendResettingPasswordEmailInput = {
  usernameOrEmail: Scalars['String']['input'];
};

export type SendResettingPasswordEmailPayload = {
  __typename?: 'SendResettingPasswordEmailPayload';
  error?: Maybe<SendResettingPasswordEmailError>;
};

export type SendUnsubscribeReasonsInput = {
  otherReasonText?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['ID']['input'];
  reasons: Array<Scalars['String']['input']>;
};

export type SendUnsubscribeReasonsPayload = {
  __typename?: 'SendUnsubscribeReasonsPayload';
  success: Scalars['Boolean']['output'];
};

export type ServeLocation = {
  __typename?: 'ServeLocation';
  country: Country;
  regions: Array<Scalars['String']['output']>;
};

export type ServiceProcedure = {
  __typename?: 'ServiceProcedure';
  diagrams: Array<Scalars['String']['output']>;
};

export type SetAllowOrderEmailsInput = {
  allowOrderEmails: Scalars['Boolean']['input'];
};

export type SetAllowOrderEmailsPayload = {
  __typename?: 'SetAllowOrderEmailsPayload';
  user?: Maybe<User>;
};

export type SetCustomPreferredBrandsInput = {
  preferredBrands: Array<SetPreferredBrandItem>;
  type: ProductType;
};

export type SetCustomPreferredBrandsPayload = {
  __typename?: 'SetCustomPreferredBrandsPayload';
  preferredBrands?: Maybe<Array<CustomPreferredBrand>>;
};

export type SetInvitationPermissionsInput = {
  id: Scalars['ID']['input'];
  permissions: MemberPermissionsInput;
};

export type SetInvitationPermissionsPayload = {
  __typename?: 'SetInvitationPermissionsPayload';
  invitation?: Maybe<Invitation>;
};

export type SetMemberPermissionsInput = {
  memberId: Scalars['ID']['input'];
  permissions: MemberPermissionsInput;
};

export type SetMemberPermissionsPayload = {
  __typename?: 'SetMemberPermissionsPayload';
  permissions?: Maybe<MemberPermissions>;
};

export type SetOrderAsDeliveredInput = {
  id: Scalars['ID']['input'];
};

export type SetOrderAsDeliveredPayload = {
  __typename?: 'SetOrderAsDeliveredPayload';
  order?: Maybe<Order>;
};

export type SetPickupRadiusInput = {
  pickupRadius: Scalars['Int']['input'];
};

export type SetPickupRadiusPayload = {
  __typename?: 'SetPickupRadiusPayload';
  shop?: Maybe<Shop>;
};

export type SetPreferredBrandItem = {
  brandId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
};

export type SetPreferredBrandsModeInput = {
  freePreferredBrands: Scalars['Boolean']['input'];
  preferredPartsBrands: PreferredBrandsMode;
  preferredTireBrands: PreferredBrandsMode;
};

export type SetPreferredBrandsModePayload = {
  __typename?: 'SetPreferredBrandsModePayload';
  shop?: Maybe<Shop>;
};

export type SetShowRetailPricingInput = {
  showRetailPricing: Scalars['Boolean']['input'];
};

export type SetShowRetailPricingPayload = {
  __typename?: 'SetShowRetailPricingPayload';
  shop?: Maybe<Shop>;
};

export type SetStockOrderInput = {
  parts: Array<SetStockOrderPart>;
  splitByLastPurchases?: InputMaybe<Scalars['Boolean']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetStockOrderPart = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  brandId?: InputMaybe<Scalars['ID']['input']>;
  lineCardId?: InputMaybe<Scalars['ID']['input']>;
  partNumber: Scalars['String']['input'];
  partNumberId?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  selectedIndex?: InputMaybe<Scalars['Int']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetStockOrderPayload = {
  __typename?: 'SetStockOrderPayload';
  stockOrder?: Maybe<StockOrder>;
};

export enum Severity {
  Normal = 'NORMAL',
  Severe = 'SEVERE'
}

export enum ShippingMethod {
  Fedex = 'FEDEX',
  HotShotDelivery = 'HOT_SHOT_DELIVERY',
  InStorePickup = 'IN_STORE_PICKUP',
  Ups = 'UPS',
  Usps = 'USPS'
}

export type ShippingPackage = {
  __typename?: 'ShippingPackage';
  id: Scalars['Int']['output'];
  receivedDate?: Maybe<Scalars['DateTime']['output']>;
  shippingMethod?: Maybe<ShippingMethod>;
  shippingStatus: ShippingStatus;
  trackingNumber: Scalars['String']['output'];
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

export enum ShippingStatus {
  Cancelled = 'CANCELLED',
  Delivered = 'DELIVERED',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  NotFound = 'NOT_FOUND',
  Returned = 'RETURNED'
}

export type Shop = {
  __typename?: 'Shop';
  accountAddresses?: Maybe<Array<ShopAccountAddress>>;
  accounts?: Maybe<Array<Account>>;
  addresses?: Maybe<Array<ShopAddress>>;
  allowToModifySuppliers: Scalars['Boolean']['output'];
  attributes?: Maybe<ShopAttributes>;
  boschAccount?: Maybe<BoschAccount>;
  cards?: Maybe<Array<Card>>;
  catalog: Scalars['Boolean']['output'];
  cellphone?: Maybe<Scalars['String']['output']>;
  confirmShipping: Scalars['Boolean']['output'];
  customPreferredBrands?: Maybe<Array<CustomPreferredBrand>>;
  demo: Scalars['Boolean']['output'];
  exProfileType?: Maybe<ExProfileType>;
  featureFlags: ShopFeatureFlags;
  id: Scalars['ID']['output'];
  invitations?: Maybe<Array<Invitation>>;
  laborRates?: Maybe<Array<LaborRate>>;
  logo?: Maybe<Scalars['String']['output']>;
  managementSystem?: Maybe<ShopManagementSystem>;
  mandatoryPoNumber: Scalars['Boolean']['output'];
  members?: Maybe<Array<ShopMember>>;
  name: Scalars['String']['output'];
  newOnboard: Scalars['Boolean']['output'];
  onboard?: Maybe<Array<Onboard>>;
  onboardSteps?: Maybe<Array<OnboardStep>>;
  orderingStatistic?: Maybe<OrderingStatistic>;
  otherBusinessName?: Maybe<Scalars['String']['output']>;
  partsPreferredBrands: PreferredBrandsMode;
  permissions?: Maybe<ShopPermissions>;
  phone?: Maybe<Scalars['String']['output']>;
  pickupRadius: Scalars['Int']['output'];
  preferredBrands?: Maybe<PreferredBrandsConnection>;
  recentOrder?: Maybe<Order>;
  recentOrders?: Maybe<RecentOrderConnection>;
  recentOrdersBrands?: Maybe<Array<Brand>>;
  recentOrdersSuppliers?: Maybe<Array<Supplier>>;
  recentSearches?: Maybe<Array<RecentSearch>>;
  recentVehicles?: Maybe<Array<RecentVehicle>>;
  recentlyViewedParts?: Maybe<Array<RecentlyViewedPart>>;
  retailPricing?: Maybe<RetailPricing>;
  shopSubscription?: Maybe<ShopSubscription>;
  showRetailPrice: Scalars['Boolean']['output'];
  skipOnboard: Scalars['Boolean']['output'];
  smsPartTypeAsPartName: Scalars['Boolean']['output'];
  stockOrderTemplate?: Maybe<StockOrderTemplate>;
  stockOrderTemplates?: Maybe<StockOrderTemplateConnection>;
  subscriptionPayments?: Maybe<Array<SubscriptionCustomerInvoice>>;
  subscriptionProducts?: Maybe<Array<SubscriptionProduct>>;
  subscriptionUpcomingInvoice?: Maybe<SubscriptionUpcomingInvoice>;
  subscriptionUpcomingUpdatePeriodInvoice?: Maybe<SubscriptionUpcomingInvoice>;
  tiresPreferredBrands: PreferredBrandsMode;
  website?: Maybe<Scalars['String']['output']>;
};


export type ShopAccountsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type ShopCustomPreferredBrandsArgs = {
  partType?: InputMaybe<ProductType>;
};


export type ShopLaborRatesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type ShopPreferredBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type ShopRecentOrderArgs = {
  id: Scalars['ID']['input'];
};


export type ShopRecentOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filterStatus?: InputMaybe<FilterRecentOrderStatus>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  manufactureIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type ShopStockOrderTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type ShopStockOrderTemplatesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type ShopSubscriptionUpcomingInvoiceArgs = {
  input: UpcomingInvoiceInput;
};

export type ShopAccountAddress = {
  __typename?: 'ShopAccountAddress';
  address: Address;
  credentialsId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  shipToId?: Maybe<Scalars['String']['output']>;
};

export type ShopAddress = {
  __typename?: 'ShopAddress';
  address: Address;
  coordinates?: Maybe<Coordinates>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  type: ShopAddressType;
};

export enum ShopAddressType {
  Billing = 'BILLING',
  Shipping = 'SHIPPING'
}

export type ShopAttributes = {
  __typename?: 'ShopAttributes';
  defaultShippingZipCode: Scalars['String']['output'];
  demo: Scalars['Boolean']['output'];
  suppliers: Array<Scalars['ID']['output']>;
  totalPaidOrders: Scalars['Int']['output'];
  usersCount: Scalars['Int']['output'];
};

export type ShopFeatureFlags = {
  __typename?: 'ShopFeatureFlags';
  advertisingSegment?: Maybe<AdvertisingSegment>;
  exShop: Scalars['Boolean']['output'];
  newLabor: Scalars['Boolean']['output'];
};

export type ShopHelpRequestPayload = {
  __typename?: 'ShopHelpRequestPayload';
  success: Scalars['Boolean']['output'];
};

export type ShopManagementSystem = CustomManagementSystem | ManagementSystem;

export type ShopMember = {
  __typename?: 'ShopMember';
  active: Scalars['Boolean']['output'];
  apiKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  permissions?: Maybe<MemberPermissions>;
  shop?: Maybe<Shop>;
  user?: Maybe<User>;
};

export type ShopPermissions = {
  __typename?: 'ShopPermissions';
  additionalReports: Scalars['Boolean']['output'];
  jobs: Scalars['Boolean']['output'];
  labor: Scalars['Boolean']['output'];
  preferredBrands: Scalars['Boolean']['output'];
  retailPricing: Scalars['Boolean']['output'];
  schedules: Scalars['Boolean']['output'];
  specs: Scalars['Boolean']['output'];
  spendReport: Scalars['Boolean']['output'];
  tires: Scalars['Boolean']['output'];
  userPermissions: Scalars['Boolean']['output'];
};

export type ShopSubscription = {
  __typename?: 'ShopSubscription';
  amount: Scalars['BigDecimal']['output'];
  card?: Maybe<Card>;
  coupon?: Maybe<Coupon>;
  currentPeriodEnd?: Maybe<Scalars['DateTime']['output']>;
  period: SubscriptionPlanPeriod;
  plans?: Maybe<Array<SubscriptionSubscriptionPlan>>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ShopType {
  Diy = 'DIY',
  FleetRepair = 'FLEET_REPAIR',
  GeneralRepairShop = 'GENERAL_REPAIR_SHOP',
  HeavyDuty = 'HEAVY_DUTY',
  MobileMechanic = 'MOBILE_MECHANIC',
  NewUsedVehicleDealer = 'NEW_USED_VEHICLE_DEALER',
  OtherAutomotiveRepair = 'OTHER_AUTOMOTIVE_REPAIR',
  PartsSupplier = 'PARTS_SUPPLIER',
  QuickLube = 'QUICK_LUBE',
  TireShop = 'TIRE_SHOP'
}

export type SkipOnboardPayload = {
  __typename?: 'SkipOnboardPayload';
  onboard?: Maybe<Array<Onboard>>;
};

export type SmsRegistrationData = {
  __typename?: 'SmsRegistrationData';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  alreadyRegistered: Scalars['Boolean']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  managementSystemId: Scalars['String']['output'];
  shopName?: Maybe<Scalars['String']['output']>;
  shopPhoneNumber?: Maybe<Scalars['String']['output']>;
  shopType?: Maybe<ShopType>;
  state?: Maybe<Scalars['String']['output']>;
  video: Scalars['String']['output'];
  website?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type SmsRegistrationDataResponse = {
  __typename?: 'SmsRegistrationDataResponse';
  data?: Maybe<SmsRegistrationData>;
  error?: Maybe<RegistrationDataError>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SpecificationDetailItem = {
  __typename?: 'SpecificationDetailItem';
  extendedParameters: Array<ExtendedParameter>;
  itemNotes: Array<Scalars['String']['output']>;
  maxValue?: Maybe<Scalars['String']['output']>;
  minValue?: Maybe<Scalars['String']['output']>;
  unitOfMeasure?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SpecificationDetails = {
  __typename?: 'SpecificationDetails';
  description?: Maybe<Scalars['String']['output']>;
  detailsNotes: Array<Scalars['String']['output']>;
  diagrams: Array<Scalars['String']['output']>;
  items: Array<SpecificationDetailItem>;
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['String']['output']>;
  serviceProcedures: Array<ServiceProcedure>;
};

export type SpecificationsDetailsConnection = {
  __typename?: 'SpecificationsDetailsConnection';
  edges?: Maybe<Array<SpecificationsEdge>>;
  pageInfo: PageInfo;
};

export type SpecificationsEdge = {
  __typename?: 'SpecificationsEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<SpecificationDetails>;
};

export type SpendOrders = {
  __typename?: 'SpendOrders';
  items: Array<SpendReportOrder>;
  totalCount: Scalars['Int']['output'];
};

export type SpendReport = {
  __typename?: 'SpendReport';
  day?: Maybe<Scalars['Int']['output']>;
  month: Scalars['Int']['output'];
  orderCount: Scalars['Int']['output'];
  orderSum: Scalars['BigDecimal']['output'];
  store?: Maybe<Store>;
  supplier?: Maybe<Supplier>;
  totalOrders: Scalars['Int']['output'];
  totalPrice: Scalars['BigDecimal']['output'];
  user?: Maybe<User>;
  year: Scalars['Int']['output'];
};

export type SpendReportOrder = {
  __typename?: 'SpendReportOrder';
  customerNumber?: Maybe<Scalars['String']['output']>;
  datePaid: Scalars['DateTime']['output'];
  grandTotal: Scalars['BigDecimal']['output'];
  hash: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  numberOfItems: Scalars['Int']['output'];
  paymentWay: PaymentWay;
  purchaseOrderNumber: Scalars['String']['output'];
  store?: Maybe<Store>;
  supplier?: Maybe<Supplier>;
  user?: Maybe<User>;
};

export enum SpendReportOrdersSortBy {
  DatePaid = 'DATE_PAID',
  GrandTotal = 'GRAND_TOTAL'
}

export type SpendReports = {
  __typename?: 'SpendReports';
  spendOrders?: Maybe<SpendOrders>;
  spendReport?: Maybe<Array<SpendReport>>;
  spendStores?: Maybe<Array<Store>>;
  spendSummary?: Maybe<SpendSummary>;
};


export type SpendReportsSpendOrdersArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  poNumber?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SpendReportOrdersSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  startDate: Scalars['DateTime']['input'];
  storeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type SpendReportsSpendReportArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  grouping?: InputMaybe<ReportGrouping>;
  startDate: Scalars['DateTime']['input'];
  storeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};


export type SpendReportsSpendSummaryArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate: Scalars['DateTime']['input'];
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SpendSummary = {
  __typename?: 'SpendSummary';
  totalParts: Scalars['Int']['output'];
  totalPrice: Scalars['BigDecimal']['output'];
  totalTires: Scalars['Int']['output'];
};

export enum SponsorType {
  None = 'NONE',
  PotentiallySponsored = 'POTENTIALLY_SPONSORED',
  Sponsored = 'SPONSORED'
}

export type State = {
  __typename?: 'State';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  twoLetterCode: Scalars['String']['output'];
};

export type StockOrder = {
  __typename?: 'StockOrder';
  parts: Array<StockOrderPart>;
  splitByLastPurchases: Scalars['Boolean']['output'];
  store?: Maybe<Store>;
  supplier?: Maybe<Supplier>;
};

export type StockOrderPart = {
  __typename?: 'StockOrderPart';
  account?: Maybe<Account>;
  brand?: Maybe<Brand>;
  lineCardId?: Maybe<Scalars['ID']['output']>;
  partNumber: Scalars['String']['output'];
  partNumberId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  selectedIndex?: Maybe<Scalars['Int']['output']>;
  store?: Maybe<Store>;
  supplier?: Maybe<Supplier>;
};

export type StockOrderTemplate = {
  __typename?: 'StockOrderTemplate';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<StockOrderTemplateItem>>;
  lastUseDate: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type StockOrderTemplateConnection = {
  __typename?: 'StockOrderTemplateConnection';
  items?: Maybe<Array<Maybe<StockOrderTemplate>>>;
  totalCount: Scalars['Int']['output'];
};

export type StockOrderTemplateItem = {
  __typename?: 'StockOrderTemplateItem';
  account?: Maybe<Account>;
  displayPartNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  lineCardId?: Maybe<Scalars['Int']['output']>;
  partName?: Maybe<Scalars['String']['output']>;
  partNumberId: Scalars['String']['output'];
  partType?: Maybe<PartType>;
  quantity: Scalars['Int']['output'];
  supplier?: Maybe<Supplier>;
  tireSize?: Maybe<TireSize>;
  vehicle?: Maybe<Vehicle>;
};

export type Store = {
  __typename?: 'Store';
  address: Address;
  coordinates?: Maybe<Coordinates>;
  dropship: Scalars['Boolean']['output'];
  hotshot: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  pickUp: Scalars['Boolean']['output'];
  supplier?: Maybe<Supplier>;
  warehouse: Scalars['Boolean']['output'];
};

export enum SubmitActiveCartError {
  ApplyParamsErrors = 'APPLY_PARAMS_ERRORS',
  EmptyCart = 'EMPTY_CART',
  Inconsistent = 'INCONSISTENT',
  NonPunchout = 'NON_PUNCHOUT',
  NoActiveCart = 'NO_ACTIVE_CART'
}

export type SubmitActiveCartErrorPayload = {
  __typename?: 'SubmitActiveCartErrorPayload';
  error: SubmitActiveCartError;
  errorMessage: Scalars['String']['output'];
  submitOrdersErrors?: Maybe<Array<SubmitOrderErrorPayload>>;
};

export type SubmitActiveCartInput = {
  laborItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  submitOrders: Array<SubmitOrderInput>;
  submittedLabor?: InputMaybe<Array<SubmittedLabor>>;
};

export type SubmitActiveCartMitchell1Payload = {
  __typename?: 'SubmitActiveCartMitchell1Payload';
  labors?: Maybe<Array<Mitchell1SubmittedLabor>>;
  notes?: Maybe<Array<Mitchell1SubmittedNote>>;
  parts: Array<Mitchell1SubmittedPart>;
};

export type SubmitActiveCartOrderErrorsPayload = {
  __typename?: 'SubmitActiveCartOrderErrorsPayload';
  failedOrders: Array<CartOrder>;
};

export type SubmitActiveCartPayload = FailedSubmitActiveCartPayload | SubmitActiveCartErrorPayload | SubmitActiveCartOrderErrorsPayload | SubmitActiveCartSuccessPayload;

export type SubmitActiveCartSuccessPayload = {
  __typename?: 'SubmitActiveCartSuccessPayload';
  mitchell1Payload?: Maybe<SubmitActiveCartMitchell1Payload>;
  orders: Array<CartOrder>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export type SubmitItemInput = {
  itemId: Scalars['ID']['input'];
  supplierNotes?: InputMaybe<Scalars['String']['input']>;
};

export enum SubmitOrderError {
  IncorrectShippingMethod = 'INCORRECT_SHIPPING_METHOD',
  Integration = 'INTEGRATION',
  Outage = 'OUTAGE',
  Validation = 'VALIDATION'
}

export type SubmitOrderErrorPayload = {
  __typename?: 'SubmitOrderErrorPayload';
  error: SubmitOrderError;
  errorMessage: Scalars['String']['output'];
  /** Field validation errors. */
  fieldValidationErrors?: Maybe<Array<OrderFieldValidationError>>;
  orderId: Scalars['ID']['output'];
};

export type SubmitOrderInput = {
  customNotes?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>;
  shippingMethodCode: Scalars['String']['input'];
  submitItems: Array<SubmitItemInput>;
};

export type SubmittedLabor = {
  applicationId: Scalars['String']['input'];
  duration?: InputMaybe<Scalars['Float']['input']>;
  orderItemId: Scalars['ID']['input'];
};

export type SubscriptionCustomerInvoice = {
  __typename?: 'SubscriptionCustomerInvoice';
  amountDue: Scalars['BigDecimal']['output'];
  amountPaid: Scalars['BigDecimal']['output'];
  card?: Maybe<SubscriptionCustomerInvoiceCard>;
  coupon?: Maybe<SubscriptionCustomerInvoiceCoupon>;
  hostedInvoiceUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lines?: Maybe<Array<SubscriptionCustomerInvoiceLine>>;
  paid: Scalars['Boolean']['output'];
  periodStart: Scalars['DateTime']['output'];
  subTotal: Scalars['BigDecimal']['output'];
  tax: Scalars['BigDecimal']['output'];
  total: Scalars['BigDecimal']['output'];
};

export type SubscriptionCustomerInvoiceCard = {
  __typename?: 'SubscriptionCustomerInvoiceCard';
  brand: Scalars['String']['output'];
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  owner?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionCustomerInvoiceCoupon = {
  __typename?: 'SubscriptionCustomerInvoiceCoupon';
  amountOff?: Maybe<Scalars['BigDecimal']['output']>;
  discountAmount: Scalars['BigDecimal']['output'];
  name: Scalars['String']['output'];
  percentOff?: Maybe<Scalars['BigDecimal']['output']>;
};

export type SubscriptionCustomerInvoiceLine = {
  __typename?: 'SubscriptionCustomerInvoiceLine';
  amount: Scalars['BigDecimal']['output'];
  description: Scalars['String']['output'];
  periodEnd: Scalars['DateTime']['output'];
  periodStart: Scalars['DateTime']['output'];
  qty: Scalars['Int']['output'];
  unitPrice?: Maybe<Scalars['BigDecimal']['output']>;
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  amount: Scalars['BigDecimal']['output'];
  id: Scalars['ID']['output'];
  isTrial: Scalars['Boolean']['output'];
  monthlyPrice?: Maybe<Scalars['BigDecimal']['output']>;
  period: SubscriptionPlanPeriod;
  product?: Maybe<SubscriptionProduct>;
};

export enum SubscriptionPlanPeriod {
  Month = 'MONTH',
  Year = 'YEAR'
}

export type SubscriptionProduct = {
  __typename?: 'SubscriptionProduct';
  alias: SubscriptionProductAlias;
  description?: Maybe<Scalars['String']['output']>;
  isPlan: Scalars['Boolean']['output'];
  lazy: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  plans: Array<SubscriptionPlan>;
  unsubscribeReasons?: Maybe<Array<Scalars['String']['output']>>;
};

export enum SubscriptionProductAlias {
  Labor = 'LABOR',
  MaintenanceSchedules = 'MAINTENANCE_SCHEDULES',
  PartstechComplete = 'PARTSTECH_COMPLETE',
  PartstechPlus = 'PARTSTECH_PLUS',
  Tires = 'TIRES',
  VehicleSpecs = 'VEHICLE_SPECS'
}

export type SubscriptionSubscriptionPlan = {
  __typename?: 'SubscriptionSubscriptionPlan';
  account?: Maybe<Account>;
  downgradeProductAlias?: Maybe<SubscriptionProductAlias>;
  id: Scalars['ID']['output'];
  plan?: Maybe<SubscriptionPlan>;
  status: SubscriptionSubscriptionPlanStatus;
};

export enum SubscriptionSubscriptionPlanStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  CancelRequested = 'CANCEL_REQUESTED',
  Incomplete = 'INCOMPLETE'
}

export type SubscriptionUpcomingInvoice = {
  __typename?: 'SubscriptionUpcomingInvoice';
  grandTotal: Scalars['BigDecimal']['output'];
  nextPaymentAmount: Scalars['BigDecimal']['output'];
  plans: Array<SubscriptionUpcomingInvoicePlan>;
  tax: Scalars['BigDecimal']['output'];
  total: Scalars['BigDecimal']['output'];
  totalExcludingTax: Scalars['BigDecimal']['output'];
};

export type SubscriptionUpcomingInvoicePlan = {
  __typename?: 'SubscriptionUpcomingInvoicePlan';
  monthlyPrice?: Maybe<Scalars['BigDecimal']['output']>;
  nextPaymentAmount: Scalars['BigDecimal']['output'];
  planId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  tax: Scalars['BigDecimal']['output'];
  total: Scalars['BigDecimal']['output'];
  totalExcludingTax: Scalars['BigDecimal']['output'];
};

export type Supplier = {
  __typename?: 'Supplier';
  additionalInformationConfiguration?: Maybe<AdditionalInformation>;
  allowCustomNotes: Scalars['Boolean']['output'];
  allowCustomPurchaseOrderNumbers: Scalars['Boolean']['output'];
  allowStockOrders: Scalars['Boolean']['output'];
  allowStoreSelect: Scalars['Boolean']['output'];
  autoValidation: Scalars['Boolean']['output'];
  checkoutMessage?: Maybe<Scalars['String']['output']>;
  comingSoon: Scalars['Boolean']['output'];
  connectionTime: ConnectionTime;
  coreChargePolicy?: Maybe<Scalars['String']['output']>;
  coreReturns: Scalars['Boolean']['output'];
  credentialsConfiguration: Array<ConfigurationField>;
  displayAction: DisplayAction;
  hideLocation: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isTire: Scalars['Boolean']['output'];
  isTireConnect: Scalars['Boolean']['output'];
  lockedStore: Scalars['Boolean']['output'];
  logo: Scalars['String']['output'];
  marketingDescription?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orderingSupported: Scalars['Boolean']['output'];
  outageType?: Maybe<Outage>;
  paid: Scalars['Boolean']['output'];
  registrationInstruction?: Maybe<Scalars['String']['output']>;
  returnAuthSetting: ReturnAuthSetting;
  returnPolicy?: Maybe<Scalars['String']['output']>;
  serveLocations: Array<ServeLocation>;
  slug: Scalars['String']['output'];
  tutorialUrl?: Maybe<Scalars['String']['output']>;
  type: SupplierType;
};

export type SupplierMember = {
  __typename?: 'SupplierMember';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  supplier?: Maybe<Supplier>;
  user?: Maybe<User>;
};

export type SupplierOptionsPreferences = {
  __typename?: 'SupplierOptionsPreferences';
  shippingMethodCode?: Maybe<Scalars['String']['output']>;
};

export type SupplierStore = {
  __typename?: 'SupplierStore';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum SupplierType {
  Aftermarket = 'AFTERMARKET',
  Dealer = 'DEALER',
  Tires = 'TIRES'
}

export type SystemAlert = {
  __typename?: 'SystemAlert';
  id: Scalars['ID']['output'];
  link?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  type?: Maybe<SystemAlertType>;
};

export enum SystemAlertType {
  Error = 'ERROR',
  Information = 'INFORMATION',
  Success = 'SUCCESS',
  Warning = 'WARNING'
}

export type SystemGroup = {
  __typename?: 'SystemGroup';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  subGroups?: Maybe<Array<SystemSubGroup>>;
};

export type SystemSubGroup = {
  __typename?: 'SystemSubGroup';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  taxonomies?: Maybe<Array<TaxonomyInfo>>;
};

export type TaxonomyInfo = {
  __typename?: 'TaxonomyInfo';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TaxonomySystem = {
  __typename?: 'TaxonomySystem';
  groups?: Maybe<Array<SystemGroup>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TireApplicationStaggeredSearchInput = {
  accountId: Scalars['ID']['input'];
  frontSize: TireSizeInput;
  rearSize: TireSizeInput;
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type TireQuote = {
  __typename?: 'TireQuote';
  executed: Scalars['Boolean']['output'];
  quantity: Scalars['Int']['output'];
  shopInfo: TireQuoteShopInfo;
  tires: Array<TireQuoteItem>;
};

export type TireQuoteCustomerInfoInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  repairOrderId: Scalars['ID']['input'];
  sendInvitation: Scalars['Boolean']['input'];
};

export type TireQuoteItem = {
  __typename?: 'TireQuoteItem';
  calculations: CalculatedPackagePrice;
  customerPrice?: Maybe<Scalars['BigDecimal']['output']>;
  label: TireQuoteLabel;
  note?: Maybe<Scalars['String']['output']>;
  product: Product;
};

export type TireQuoteItemInput = {
  fet?: InputMaybe<FetInput>;
  label: TireQuoteLabel;
  note?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['BigDecimal']['input']>;
  searchParams: TireQuoteSearchParamsInput;
};

export enum TireQuoteLabel {
  Best = 'BEST',
  Better = 'BETTER',
  Good = 'GOOD'
}

export type TireQuoteSearchParamsInput = {
  accountId: Scalars['ID']['input'];
  lineCardId: Scalars['ID']['input'];
  partNumberId: Scalars['String']['input'];
};

export type TireQuoteShopInfo = {
  __typename?: 'TireQuoteShopInfo';
  email?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type TireReports = {
  __typename?: 'TireReports';
  tireChart?: Maybe<Array<ChartLine>>;
  tireProducts?: Maybe<Array<ReportProduct>>;
  tireSizes?: Maybe<ReportTireSizes>;
  tireSuppliers?: Maybe<Array<Supplier>>;
};


export type TireReportsTireChartArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  grouping?: InputMaybe<ReportGrouping>;
  startDate: Scalars['DateTime']['input'];
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};


export type TireReportsTireProductsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate: Scalars['DateTime']['input'];
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tireSize: Scalars['String']['input'];
};


export type TireReportsTireSizesArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<TireReportsSizesSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  startDate: Scalars['DateTime']['input'];
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum TireReportsSizesSortBy {
  TireSize = 'TIRE_SIZE',
  TotalPrice = 'TOTAL_PRICE',
  TotalQuantity = 'TOTAL_QUANTITY'
}

export type TireSize = {
  __typename?: 'TireSize';
  height: Scalars['Float']['output'];
  loadIndex?: Maybe<Scalars['Int']['output']>;
  rim: Scalars['Float']['output'];
  speedRating?: Maybe<Scalars['String']['output']>;
  width: Scalars['Float']['output'];
};

export type TireSizeInput = {
  height: Scalars['Float']['input'];
  rim: Scalars['Float']['input'];
  width: Scalars['Float']['input'];
};

export type TireSizeSearchInput = {
  accountId: Scalars['ID']['input'];
  height: Scalars['Float']['input'];
  rim: Scalars['Float']['input'];
  width: Scalars['Float']['input'];
};

export type TireSizesStatistic = {
  __typename?: 'TireSizesStatistic';
  mostPopularBrand?: Maybe<Brand>;
  tireSize?: Maybe<Scalars['String']['output']>;
  totalOrders: Scalars['Int']['output'];
  totalPrice: Scalars['BigDecimal']['output'];
  totalQuantity: Scalars['Int']['output'];
};

export type ToggleShowRetailPricingInput = {
  /** Cart ID. */
  id: Scalars['ID']['input'];
  showRetailPricing: Scalars['Boolean']['input'];
};

export type ToggleShowRetailPricingPayload = {
  __typename?: 'ToggleShowRetailPricingPayload';
  /** Result cart. */
  cart?: Maybe<Cart>;
};

export enum TrackingStatus {
  Acknowledged = 'ACKNOWLEDGED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  OrderPlaced = 'ORDER_PLACED',
  Received = 'RECEIVED',
  Returned = 'RETURNED',
  Shipped = 'SHIPPED',
  Shipping = 'SHIPPING'
}

export type TransferAdminInput = {
  id: Scalars['ID']['input'];
};

export type TransferAdminPayload = {
  __typename?: 'TransferAdminPayload';
  members?: Maybe<Array<ShopMember>>;
};

export type UnarchiveCartInput = {
  /** Cart ID. */
  id: Scalars['ID']['input'];
};

export type UnarchiveCartPayload = {
  __typename?: 'UnarchiveCartPayload';
  /** Result cart. */
  cart?: Maybe<Cart>;
};

export enum UnlinkVehicleFromCartError {
  /** Vehicle is used in items and cannot be deleted. */
  UsedVehicle = 'USED_VEHICLE'
}

export type UnlinkVehicleFromCartInput = {
  /** Cart ID. */
  id: Scalars['ID']['input'];
  /** ID of linked vehicle to remove. */
  linkedVehicleId: Scalars['ID']['input'];
};

export type UnlinkVehicleFromCartPayload = {
  __typename?: 'UnlinkVehicleFromCartPayload';
  /** Result cart. */
  cart?: Maybe<Cart>;
  /** Error during unlinking vehicle. */
  error?: Maybe<UnlinkVehicleFromCartError>;
};

export type UpcomingInvoiceInput = {
  plans: Array<UpcomingInvoicePlan>;
};

export type UpcomingInvoicePlan = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export enum UpdateActiveCartItemQuantityError {
  /** Part is not available. */
  Availability = 'AVAILABILITY',
  /** Part is not available. */
  DisplayAction = 'DISPLAY_ACTION',
  /** Integration error. Supplier rejects item and order. */
  Integration = 'INTEGRATION',
  /** No active cart. */
  NoActiveCart = 'NO_ACTIVE_CART',
  /** Supplier is in outage. */
  Outage = 'OUTAGE',
  /** Item is outdated. */
  Outdated = 'OUTDATED',
  /** Part is out of stock. */
  OutOfStock = 'OUT_OF_STOCK',
  /** Selected quantity is more than in stock. */
  Quantity = 'QUANTITY',
  /** Incorrect quantity step. */
  QuantityStep = 'QUANTITY_STEP',
  /** Special order. */
  SpecialOrder = 'SPECIAL_ORDER'
}

export type UpdateActiveCartItemQuantityErrorPayload = {
  __typename?: 'UpdateActiveCartItemQuantityErrorPayload';
  /** Error type. */
  error: UpdateActiveCartItemQuantityError;
  /** Error message. */
  errorMessage: Scalars['String']['output'];
};

export type UpdateActiveCartItemQuantityInput = {
  /** Item ID to update. */
  itemId: Scalars['ID']['input'];
  /** Selected quantity. */
  quantity: Scalars['Int']['input'];
};

export type UpdateActiveCartItemQuantityPayload = UpdateActiveCartItemQuantityErrorPayload | UpdateActiveCartItemQuantitySuccessPayload;

export type UpdateActiveCartItemQuantitySuccessPayload = {
  __typename?: 'UpdateActiveCartItemQuantitySuccessPayload';
  /** ID of new/existent order where item was added/updated. */
  orderId: Scalars['ID']['output'];
  /** "ID of item that was added or updated. */
  orderItemId: Scalars['ID']['output'];
};

export enum UpdateActiveCartLaborItemError {
  /** Internal error. */
  InternalError = 'INTERNAL_ERROR',
  /** Active Cart not found. */
  NoActiveCart = 'NO_ACTIVE_CART'
}

export type UpdateActiveCartLaborItemErrorPayload = {
  __typename?: 'UpdateActiveCartLaborItemErrorPayload';
  /** Error type. */
  error: UpdateActiveCartLaborItemError;
  /** Error message. */
  errorMessage: Scalars['String']['output'];
};

export type UpdateActiveCartLaborItemInput = {
  /** Hours. */
  hours: Scalars['BigDecimal']['input'];
  /** Labor item ID. */
  id: Scalars['ID']['input'];
};

export type UpdateActiveCartLaborItemPayload = UpdateActiveCartLaborItemErrorPayload | UpdateActiveCartLaborItemSuccessPayload;

export type UpdateActiveCartLaborItemSuccessPayload = {
  __typename?: 'UpdateActiveCartLaborItemSuccessPayload';
  /** Updated cart labor item. */
  cartLaborItem: CartLaborItem;
};

export type UpdateActiveCartLaborRateErrorPayload = {
  __typename?: 'UpdateActiveCartLaborRateErrorPayload';
  /** Error type. */
  error: UpdateLaborRateInActiveCartError;
  /** Error message. */
  errorMessage: Scalars['String']['output'];
};

export type UpdateActiveCartLaborRateInput = {
  laborRate?: InputMaybe<Scalars['BigDecimal']['input']>;
};

export type UpdateActiveCartLaborRatePayload = UpdateActiveCartLaborRateErrorPayload | UpdateActiveCartLaborRateSuccessPayload;

export type UpdateActiveCartLaborRateSuccessPayload = {
  __typename?: 'UpdateActiveCartLaborRateSuccessPayload';
  /** Updated cart. */
  cart?: Maybe<Cart>;
};

export type UpdateActiveCartOrderAdditionalFieldsErrorPayload = {
  __typename?: 'UpdateActiveCartOrderAdditionalFieldsErrorPayload';
  /** Error type. */
  error: UpdateAdditionalFieldsError;
  /** Error message. */
  errorMessage: Scalars['String']['output'];
};

export type UpdateActiveCartOrderAdditionalFieldsInput = {
  /** List of values for the fields. */
  additionalFields: Array<AdditionalFieldValue>;
  /** ID of order to update. */
  orderId: Scalars['ID']['input'];
};

export type UpdateActiveCartOrderAdditionalFieldsPayload = UpdateActiveCartOrderAdditionalFieldsErrorPayload | UpdateActiveCartOrderAdditionalFieldsSuccessPayload;

export type UpdateActiveCartOrderAdditionalFieldsSuccessPayload = {
  __typename?: 'UpdateActiveCartOrderAdditionalFieldsSuccessPayload';
  /** Result order. */
  order?: Maybe<CartOrder>;
  /** ID of updated order. */
  updatedOrderId: Scalars['ID']['output'];
};

export type UpdateActiveCartOrderPaymentNotesInput = {
  /** ID of order to update. */
  orderId: Scalars['ID']['input'];
  /** Payment notes. */
  paymentNotes: PaymentNotesInput;
};

export type UpdateActiveCartOrderPaymentNotesPayload = UpdateOrderPaymentNotesErrorPayload | UpdateOrderPaymentNotesSuccessPayload;

export type UpdateActiveCartOrderShippingMethodInput = {
  /** ID of order to update. */
  orderId: Scalars['ID']['input'];
  /** Selected shipping method code. */
  shippingMethodCode: Scalars['String']['input'];
};

export type UpdateActiveCartOrderShippingMethodPayload = UpdateOrderShippingMethodErrorPayload | UpdateOrderShippingMethodSuccessPayload;

export enum UpdateAdditionalFieldsError {
  /** No active cart. */
  NoActiveCart = 'NO_ACTIVE_CART'
}

export type UpdateCartInput = {
  customerEmail?: InputMaybe<Scalars['String']['input']>;
  /** Cart ID. */
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  repairOrderNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum UpdateCartItemQuantityError {
  /** Part is not available. */
  Availability = 'AVAILABILITY',
  /** Part is not available. */
  DisplayAction = 'DISPLAY_ACTION',
  /** Item is outdated. */
  Outdated = 'OUTDATED',
  /** Part is out of stock. */
  OutOfStock = 'OUT_OF_STOCK',
  /** Selected quantity is more than in stock. */
  Quantity = 'QUANTITY',
  /** Incorrect quantity step. */
  QuantityStep = 'QUANTITY_STEP',
  /** Special order. */
  SpecialOrder = 'SPECIAL_ORDER'
}

export type UpdateCartItemQuantityErrorPayload = {
  __typename?: 'UpdateCartItemQuantityErrorPayload';
  /** Error type. */
  error: UpdateCartItemQuantityError;
  /** Message for error. */
  errorMessage: Scalars['String']['output'];
};

export type UpdateCartItemQuantityInput = {
  /** Cart ID. */
  cartId: Scalars['ID']['input'];
  /** Item ID to update. */
  itemId: Scalars['ID']['input'];
  /** Selected quantity. */
  quantity: Scalars['Int']['input'];
};

export type UpdateCartItemQuantityPayload = UpdateCartItemQuantityErrorPayload | UpdateCartItemQuantitySuccessPayload;

export type UpdateCartItemQuantitySuccessPayload = {
  __typename?: 'UpdateCartItemQuantitySuccessPayload';
  /** Result cart. */
  cart?: Maybe<Cart>;
  /** Result order. */
  order?: Maybe<CartOrder>;
};

export type UpdateCartPayload = {
  __typename?: 'UpdateCartPayload';
  /** Updated cart. */
  cart?: Maybe<Cart>;
};

export type UpdateCartSupplierPreferredShippingMethodInput = {
  shippingMethodCode?: InputMaybe<Scalars['String']['input']>;
  supplierID: Scalars['ID']['input'];
};

export type UpdateCartSupplierPreferredShippingMethodPayload = {
  __typename?: 'UpdateCartSupplierPreferredShippingMethodPayload';
  optionsPreferences: SupplierOptionsPreferences;
};

export enum UpdateLaborRateInActiveCartError {
  /** No active cart. */
  NoActiveCart = 'NO_ACTIVE_CART'
}

export type UpdateLaborRateInput = {
  id: Scalars['ID']['input'];
  isDefault: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  rate: Scalars['BigDecimal']['input'];
};

export type UpdateLaborRatePayload = {
  __typename?: 'UpdateLaborRatePayload';
  laborRate?: Maybe<LaborRate>;
};

export type UpdateLastUseDateStockOrderTemplateInput = {
  id: Scalars['ID']['input'];
};

export type UpdateLastUseDateStockOrderTemplatePayload = {
  __typename?: 'UpdateLastUseDateStockOrderTemplatePayload';
  template?: Maybe<StockOrderTemplate>;
};

export type UpdateMatrixInput = {
  brandIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  items: Array<MatrixItemInput>;
  name: Scalars['String']['input'];
  subCategories?: InputMaybe<Array<MatrixSubCategoryInput>>;
};

export type UpdateMatrixPayload = {
  __typename?: 'UpdateMatrixPayload';
  matrix?: Maybe<Matrix>;
};

export enum UpdateOrderPaymentNotesError {
  /** No active cart. */
  NoActiveCart = 'NO_ACTIVE_CART',
  /** Validation errors. See `fieldValidationErrors`. */
  Validation = 'VALIDATION'
}

export type UpdateOrderPaymentNotesErrorPayload = {
  __typename?: 'UpdateOrderPaymentNotesErrorPayload';
  /** Error type. */
  error: UpdateOrderPaymentNotesError;
  /** Error message. */
  errorMessage: Scalars['String']['output'];
  /** Field validation errors. */
  fieldValidationErrors?: Maybe<Array<OrderFieldValidationError>>;
};

export type UpdateOrderPaymentNotesSuccessPayload = {
  __typename?: 'UpdateOrderPaymentNotesSuccessPayload';
  /** Result order. */
  order?: Maybe<CartOrder>;
  /** ID of updated order. */
  updatedOrderId: Scalars['ID']['output'];
};

export enum UpdateOrderShippingMethodError {
  /** Selected shipping method is incorrect and cannot be applied. */
  IncorrectShippingMethod = 'INCORRECT_SHIPPING_METHOD',
  /** Integration error. Supplier rejected new order configuration. */
  Integration = 'INTEGRATION',
  /** No active cart. */
  NoActiveCart = 'NO_ACTIVE_CART',
  /** Supplier is in outage. Shipping method cannot be updated. */
  Outage = 'OUTAGE'
}

export type UpdateOrderShippingMethodErrorPayload = {
  __typename?: 'UpdateOrderShippingMethodErrorPayload';
  /** Error type. */
  error: UpdateOrderShippingMethodError;
  /** Error message. */
  errorMessage: Scalars['String']['output'];
};

export type UpdateOrderShippingMethodSuccessPayload = {
  __typename?: 'UpdateOrderShippingMethodSuccessPayload';
  /** Result order. */
  order?: Maybe<CartOrder>;
  /** ID of updated order. */
  updatedOrderId: Scalars['ID']['output'];
};

export type UpdatePackageInput = {
  default: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  items: Array<PackageItemInput>;
  name: Scalars['String']['input'];
};

export type UpdatePackagePayload = {
  __typename?: 'UpdatePackagePayload';
  package?: Maybe<Package>;
};

export type UpdatePlan = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateRetailPricingInput = {
  taxRate: Scalars['BigDecimal']['input'];
};

export type UpdateRetailPricingPayload = {
  __typename?: 'UpdateRetailPricingPayload';
  retailPricing?: Maybe<RetailPricing>;
};

export type UpdateShippingAddressInput = {
  address: AddressInput;
  default: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateShippingAddressPayload = {
  __typename?: 'UpdateShippingAddressPayload';
  address?: Maybe<Array<ShopAddress>>;
};

export type UpdateShopAccountInput = {
  /** Account id. */
  accountId: Scalars['ID']['input'];
  /** Account additional information. */
  additionalInformation?: InputMaybe<Array<AdditionalInformationAccountInput>>;
  /** Account credentials. */
  credentials?: InputMaybe<Array<AccountCredentialsInput>>;
  /** Account nickname. */
  nickname?: InputMaybe<Scalars['String']['input']>;
  /** Store id. */
  storeId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateShopAccountPayload = {
  __typename?: 'UpdateShopAccountPayload';
  /** Updated shop account. */
  account?: Maybe<Account>;
};

export type UpdateShopCardInput = {
  cardId: Scalars['ID']['input'];
  expMonth: Scalars['Int']['input'];
  expYear: Scalars['Int']['input'];
};

export type UpdateShopCardPayload = {
  __typename?: 'UpdateShopCardPayload';
  card?: Maybe<Card>;
};

export type UpdateShopCartSettingsInput = {
  confirmShipping: Scalars['Boolean']['input'];
  mandatoryPoNumber: Scalars['Boolean']['input'];
};

export type UpdateShopCartSettingsPayload = {
  __typename?: 'UpdateShopCartSettingsPayload';
  shop?: Maybe<Shop>;
};

export type UpdateShopDetailsInput = {
  address: AddressInput;
  cellphone?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  otherBusinessName?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

export type UpdateShopDetailsPayload = {
  __typename?: 'UpdateShopDetailsPayload';
  addresses?: Maybe<Array<ShopAddress>>;
  shop?: Maybe<Shop>;
};

export type UpdateShopInput = {
  billingAddress: AddressInput;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  shippingAddress?: InputMaybe<AddressInput>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateShopPayload = {
  __typename?: 'UpdateShopPayload';
  shop?: Maybe<Shop>;
};

export type UpdateShopSmsSettingsInput = {
  smsPartTypeAsPartName: Scalars['Boolean']['input'];
};

export type UpdateShopSmsSettingsPayload = {
  __typename?: 'UpdateShopSmsSettingsPayload';
  shop?: Maybe<Shop>;
};

export type UpdateStockOrderTemplateInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateStockOrderTemplatePayload = {
  __typename?: 'UpdateStockOrderTemplatePayload';
  template?: Maybe<StockOrderTemplate>;
};

export type UpdateSubscriptionInput = {
  plans: Array<UpdatePlan>;
};

export type UpdateSubscriptionPayload = {
  __typename?: 'UpdateSubscriptionPayload';
  shopSubscription?: Maybe<ShopSubscription>;
};

export enum UpdateSubscriptionPeriodError {
  UpdatePeriodErrorUnableToUpgrade = 'UPDATE_PERIOD_ERROR_UNABLE_TO_UPGRADE'
}

export type UpdateSubscriptionPeriodPayload = {
  __typename?: 'UpdateSubscriptionPeriodPayload';
  error?: Maybe<UpdateSubscriptionPeriodError>;
  shopSubscription?: Maybe<ShopSubscription>;
};

export type UpdateTemplateItem = {
  accountId: Scalars['Int']['input'];
  displayPartNumber: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  lineCardId?: InputMaybe<Scalars['Int']['input']>;
  partName?: InputMaybe<Scalars['String']['input']>;
  partNumberId: Scalars['String']['input'];
  partTypeId?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  supplierId: Scalars['Int']['input'];
  templateId?: InputMaybe<Scalars['Int']['input']>;
  tireSize?: InputMaybe<TireSizeInput>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTireQuoteInput = {
  customerInfo: TireQuoteCustomerInfoInput;
  hash: Scalars['ID']['input'];
  notificationEmails: Array<Scalars['String']['input']>;
  packageItems: Array<PackageItemInput>;
  quantity: Scalars['Int']['input'];
  tires: Array<TireQuoteItemInput>;
};

export type UpdateTireQuotePayload = {
  __typename?: 'UpdateTireQuotePayload';
  hash: Scalars['ID']['output'];
};

export type UpdateUserInformationInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneType: UserPhoneType;
};

export type UpdateUserInformationPayload = {
  __typename?: 'UpdateUserInformationPayload';
  user?: Maybe<User>;
};

export type UpdateUserPhoneNumberInput = {
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneType: UserPhoneType;
};

export type UpdateUserPhoneNumberPayload = {
  __typename?: 'UpdateUserPhoneNumberPayload';
  user?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  /** Get current active cart. */
  activeCart?: Maybe<Cart>;
  activeMember?: Maybe<ShopMember>;
  allowOrdersEmail: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isConferenceAccount: Scalars['Boolean']['output'];
  isGoogleAccount: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  phoneType: UserPhoneType;
  registrationDate?: Maybe<Scalars['DateTime']['output']>;
  shopMemberships?: Maybe<Array<ShopMember>>;
  supplierMemberships?: Maybe<Array<SupplierMember>>;
  username: Scalars['String']['output'];
};

export enum UserAccountStatus {
  Disabled = 'DISABLED',
  Unverified = 'UNVERIFIED'
}

export type UserAlreadyExistsInput = {
  email: Scalars['String']['input'];
};

export type UserAlreadyExistsPayload = {
  __typename?: 'UserAlreadyExistsPayload';
  exists: Scalars['Boolean']['output'];
};

export enum UserPhoneType {
  Direct = 'DIRECT',
  Mobile = 'MOBILE'
}

export type UtmParamInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ValidateAddressesInput = {
  billingAddress: AddressInput;
  shippingAddress?: InputMaybe<AddressInput>;
};

export type ValidateAddressesPayload = {
  __typename?: 'ValidateAddressesPayload';
  billingValidity: Scalars['Boolean']['output'];
  shippingValidity?: Maybe<Scalars['Boolean']['output']>;
};

export enum ValidationInputType {
  Alphabetic = 'ALPHABETIC',
  Any = 'ANY',
  Email = 'EMAIL',
  Numeric = 'NUMERIC'
}

export type ValidationRule = {
  __typename?: 'ValidationRule';
  inputType: ValidationInputType;
  length?: Maybe<Scalars['String']['output']>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  body?: Maybe<VehicleBody>;
  /** Returns list of content silos. */
  contentSilos?: Maybe<Array<ContentSilo>>;
  /** Search vehicle diagrams with optional filters */
  diagrams?: Maybe<Array<Diagram>>;
  engine: VehicleEngine;
  /** Returns the details of the fluids that match a specific application record. */
  fluidsDetails?: Maybe<Array<Maybe<FluidsDetails>>>;
  /** Returns the summary information for fluid applications. */
  fluidsSummary?: Maybe<FluidsSummary>;
  id: Scalars['ID']['output'];
  /** List of jobs. */
  jobs?: Maybe<Array<Job>>;
  /**
   * Provides a details of maintenance schedules for a specific vehicle.
   * One of the three parameters (distance, months, indicator) must be specified for more focused details.
   */
  maintenanceSchedulesDetails?: Maybe<Array<Maybe<MaintenanceSchedulesDetails>>>;
  /** Retrieves a summary of the maintenance schedules based on different intervals. */
  maintenanceSchedulesSummary?: Maybe<MaintenanceSchedulesSummary>;
  make: VehicleMake;
  model: VehicleModel;
  name: Scalars['String']['output'];
  regions?: Maybe<Array<VehicleRegion>>;
  /** Returns specifications details by content silos id. */
  specificationsDetails?: Maybe<SpecificationsDetailsConnection>;
  subModel: VehicleSubModel;
  tires?: Maybe<Array<VehicleTire>>;
  type: VehicleType;
  vin?: Maybe<Scalars['String']['output']>;
  /** Returns the summary information for estimated work time applications. */
  workTimesSummary?: Maybe<WorkTimesSummaryConnection>;
  /** Returns the taxonomy for estimated work times. */
  workTimesTaxonomy?: Maybe<Array<TaxonomySystem>>;
  year: Scalars['Int']['output'];
};


export type VehicleContentSilosArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type VehicleDiagramsArgs = {
  brandID?: InputMaybe<Scalars['ID']['input']>;
  partTypeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type VehicleFluidsDetailsArgs = {
  applicationId: Scalars['Int']['input'];
};


export type VehicleFluidsSummaryArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type VehicleMaintenanceSchedulesDetailsArgs = {
  distance?: InputMaybe<Scalars['Int']['input']>;
  indicator?: InputMaybe<Scalars['String']['input']>;
  months?: InputMaybe<Scalars['Int']['input']>;
  severity: Severity;
};


export type VehicleMaintenanceSchedulesSummaryArgs = {
  severity: Severity;
};


export type VehicleSpecificationsDetailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  contentSiloId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type VehicleWorkTimesSummaryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  taxonomyId: Scalars['ID']['input'];
};


export type VehicleWorkTimesTaxonomyArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleBody = {
  __typename?: 'VehicleBody';
  doors?: Maybe<Scalars['Int']['output']>;
  drive?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VehicleEngine = {
  __typename?: 'VehicleEngine';
  aspiration?: Maybe<Scalars['String']['output']>;
  designation?: Maybe<Scalars['String']['output']>;
  fuel?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type VehicleMake = {
  __typename?: 'VehicleMake';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
};

export type VehicleMakeGroup = {
  __typename?: 'VehicleMakeGroup';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  types: Array<VehicleType>;
  years: Array<Scalars['Int']['output']>;
};

export type VehicleModel = {
  __typename?: 'VehicleModel';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum VehicleRegion {
  Canada = 'CANADA',
  Mexico = 'MEXICO',
  Usa = 'USA'
}

export type VehicleSubModel = {
  __typename?: 'VehicleSubModel';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type VehicleSuggest = {
  __typename?: 'VehicleSuggest';
  engine?: Maybe<VehicleEngine>;
  id?: Maybe<Scalars['ID']['output']>;
  make: VehicleMake;
  model: VehicleModel;
  regions?: Maybe<Array<VehicleRegion>>;
  subModel?: Maybe<VehicleSubModel>;
  type?: Maybe<VehicleType>;
  year: Scalars['Int']['output'];
};

export type VehicleTire = {
  __typename?: 'VehicleTire';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  rearSize?: Maybe<TireSize>;
  size: TireSize;
};

export enum VehicleType {
  HeavyTruck = 'HEAVY_TRUCK',
  LightDuty = 'LIGHT_DUTY'
}

export type WorkTimesSummary = {
  __typename?: 'WorkTimesSummary';
  applications: Array<LaborApplication>;
  partType?: Maybe<PartType>;
  partTypeId: Scalars['ID']['output'];
  vehicle?: Maybe<Vehicle>;
  vehicleId: Scalars['ID']['output'];
};

export type WorkTimesSummaryConnection = {
  __typename?: 'WorkTimesSummaryConnection';
  edges?: Maybe<Array<WorkTimesSummaryEdge>>;
  pageInfo: PageInfo;
};

export type WorkTimesSummaryEdge = {
  __typename?: 'WorkTimesSummaryEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<LaborApplication>;
};

export type WorkTimesSummaryInput = {
  partTypeId: Scalars['ID']['input'];
  vehicleId: Scalars['ID']['input'];
};

export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String']['output'];
};
