import { isEmpty, keys } from '@partstech/ui/utils';
import { DEFAULT_AVAILABILITY } from 'constant';
import { filterByAvailability } from './filterByAvailability';
import { filterByEnhancedAvailability } from './filterByEnhancedAvailability';
import { getAttributeValuesFromProduct } from './getAttributeValuesFromProduct';
import { filterMatchedTires, getMatchingSetId, MATCHING_SETS_VALUE } from './getMatchingSets';
import { isDynamicAttributeFilterName } from './isDynamicAttributeFilterName';
import type { AttributeName } from 'constant';
import type { Product } from 'models';
import type { CheckedFilters, CreateFiltersConfig, MatchingSets } from 'types/search';

const filterByStoreAvailability = (product: Product, values: CheckedFilters['storeAvailability'] | undefined) =>
  product.quote?.lines?.some((line) =>
    line.group
      ? (values?.some((value) => line.group === value) ?? false)
      : (values?.some((value) => value === `${line.id}` || value === line.name) ?? false)
  );

const filterByMatchingSet = (product: Product, checkedValues: Partial<CheckedFilters>, matchingSets: MatchingSets) => {
  const matchingSet = matchingSets[getMatchingSetId(product)];

  if (isEmpty(matchingSet)) {
    return false;
  }

  const availabilityValue = checkedValues.availability ?? DEFAULT_AVAILABILITY;

  const availableInMatchingSet = filterMatchedTires(
    matchingSet?.filter((matchingProduct) => filterByAvailability(matchingProduct, availabilityValue)) ?? []
  );

  return availableInMatchingSet.length > 1 && filterByAvailability(product, availabilityValue);
};

export const applyFiltersToProduct =
  (
    filters: Partial<CheckedFilters>,
    { isCatalog, shouldUseMatchingSets, matchingSets, isAvailabilityFilterEnhancements }: Partial<CreateFiltersConfig>
  ) =>
  (product: Product): boolean => {
    const filterNames = keys(filters);

    return filterNames.every((filterName) => {
      const values = filters[filterName];

      if (!Array.isArray(values)) {
        return true;
      }

      if (values && values.length === 0) {
        return true;
      }

      if (filterName === 'availability') {
        if (isAvailabilityFilterEnhancements) {
          return isCatalog || filterByEnhancedAvailability(product, values);
        }
        return isCatalog || filterByAvailability(product, values);
      }

      if (filterName === 'storeAvailability' && !isAvailabilityFilterEnhancements) {
        return isCatalog || filterByStoreAvailability(product, values);
      }

      if (filterName === 'parts') {
        return values?.includes(`${product.partTypeId ?? '0'}`);
      }

      if (filterName === 'manufacturers') {
        return values?.includes(product.brand?.name ?? '');
      }

      if (isDynamicAttributeFilterName(filterName)) {
        return true; // Dynamic attributes launch new search every time
      }

      if (filterName === 'fit') {
        return Boolean(product.hasVehicleFitment);
      }

      if (filterName === 'HasRebate') {
        return (
          (values.includes('1') && product.rebates.length > 0) || (values.includes('0') && product.rebates.length === 0)
        );
      }

      const attributeValue = getAttributeValuesFromProduct(product, filterName as AttributeName);

      if (shouldUseMatchingSets && filterName === 'Position' && values.includes(MATCHING_SETS_VALUE)) {
        return (
          (matchingSets && filterByMatchingSet(product, filters, matchingSets)) ||
          values?.filter((value) => value !== MATCHING_SETS_VALUE).some((value) => attributeValue.includes(value))
        );
      }

      return values?.some((value) => attributeValue.includes(value));
    });
  };
