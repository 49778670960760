import { uniq, isNotNull } from '@partstech/ui/utils';
import { findAttributeByName } from '../../product/attributes/findAttributeByName';
import { getEmptyAttributeValueByName } from './getEmptyAttributeValueByName';
import type { AttributeName } from 'constant';
import type { Product } from 'models';
import type { ProductAttribute as ProductAttributeGraph } from 'shared/api';

export const getAttributeValuesFromProduct = (product: Product, attributeName: AttributeName): string[] => {
  const emptyAttribute: ProductAttributeGraph = {
    name: attributeName,
    values: [getEmptyAttributeValueByName(attributeName)],
  };

  const variationAttributeValues = product.variations
    .map((variation) => findAttributeByName(attributeName, variation.attributes))
    .filter(isNotNull)
    .flatMap((attr) => attr.values);

  const productAttribute = findAttributeByName(attributeName, product.attributes);
  const attribute = !productAttribute && variationAttributeValues.length === 0 ? emptyAttribute : productAttribute;

  return uniq([...(attribute?.values ?? []), ...variationAttributeValues]);
};
