import { useMutationStatus } from 'shared/api';
import { api as rootApi } from '../../queries/getStockOrders/GetStockOrders.generated';
import { api as generatedApi } from './SetStockOrder.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    SetStockOrder: {
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          rootApi.util.updateQueryData('GetStockOrders', undefined, (draft) => {
            if (data.setStockOrder?.stockOrder) {
              Object.assign(draft, { stockOrder: data.setStockOrder.stockOrder });
            }
          })
        );
      },
    },
  },
});

const { useSetStockOrderMutation } = api;

export const useSetStockOrder = () => {
  const [setStockOrder, status] = useSetStockOrderMutation();

  useMutationStatus({ status });

  return [setStockOrder, status] as const;
};
