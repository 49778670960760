import { createAvailability } from './createAvailability';
import type { AvailabilityLine as AvailabilityLineType, AvailabilityLineVariant } from 'types/product';

export const createAvailabilityLineFromData = (
  line: AvailabilityLineType,
  storeId?: string,
  accountNickname?: string | null,
  variant?: AvailabilityLineVariant
) => {
  const availabilityLineData = {
    ...line,
    quantity: line.qty.value,
  };

  const availabilityLine = createAvailability(availabilityLineData, variant);

  if (availabilityLine.id === Number(storeId)) {
    availabilityLine.setNickname(accountNickname);
  }

  return availabilityLine;
};
