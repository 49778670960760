import { Box, Card, Link, Switcher, Typography, useMedia } from '@partstech/ui';
import React, { useCallback } from 'react';
import { usePriceMatrices, useToggleRetailPrice } from 'hooks/retailPricing';
import { useSearchParams } from 'hooks/search';
import { usePermissions } from 'store/queries/currentUser/usePermissions';
import { getMyShopUrl } from 'utils/url';

type Props = {
  onDismiss?: () => void;
};

export const ConfigureRetailPrice = ({ onDismiss }: Props) => {
  const { isMobile } = useMedia();

  const { isTiresSearch, isPartsSearch } = useSearchParams();
  const { isShown: showRetailPrice, onToggle: toggleRetailPrice } = useToggleRetailPrice();

  const { hasPartMatrix, hasTireMatrix } = usePriceMatrices();

  const {
    member: { retailPricingAllowed },
  } = usePermissions();

  const hasSomeMatrix = hasPartMatrix || hasTireMatrix;
  const hasEmptyPartsMatrices = isPartsSearch && !hasPartMatrix;
  const hasEmptyTiresMatrices = isTiresSearch && !hasTireMatrix;
  const showConfigureLink = retailPricingAllowed && (hasEmptyPartsMatrices || hasEmptyTiresMatrices);

  const handleChangeSwitch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      toggleRetailPrice(event.target.checked);
    },
    [toggleRetailPrice]
  );

  if (!showConfigureLink && !hasSomeMatrix) {
    return null;
  }

  return (
    <Card
      px={{ sm: 4, md: 2 }}
      py={{ sm: 4, md: 1 }}
      mb={{ sm: 4, md: 0 }}
      display={{ sm: 'block', md: 'flex' }}
      alignItems="center"
      gap={1}
      maxWidth={{ sm: 58, md: 'fit-content' }}
      borderWidth={isMobile ? 1 : 0}
      radius={isMobile ? 8 : 4}
    >
      {hasSomeMatrix && (
        <Box mb={showConfigureLink && isMobile ? 4 : 0}>
          <Switcher
            id="show-retail-price"
            checked={showRetailPrice}
            alignItems="center"
            onChange={handleChangeSwitch}
            name="showRetailPrice"
          >
            <Typography
              variant="bodyText1"
              component="span"
            >{`Quote mode${showConfigureLink && !isMobile ? '.' : ''}`}</Typography>
          </Switcher>
        </Box>
      )}

      {showConfigureLink && (
        <Box textAlign="center">
          <Link
            to={getMyShopUrl('retail-pricing', undefined, { hash: isTiresSearch ? 'tires' : 'parts' })}
            color={isMobile ? 'primary' : 'linkText'}
            underline={isMobile ? 'default' : 'none'}
            data-testid="retailLink"
            onClick={onDismiss}
          >
            Configure quote settings
          </Link>
        </Box>
      )}
    </Card>
  );
};
