/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { BaseProductFragmentDoc } from './BaseProductFragment.generated';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetProductsQueryVariables = Types.Exact<{
  searchInput: Types.SearchInput;
}>;

export type GetProductsQuery = {
  __typename?: 'Query';
  products?: {
    __typename?: 'ProductsResult';
    errors: Array<Types.Scalars['String']['input']>;
    products: Array<{
      __typename?: 'Product';
      coreCharge?: Types.Scalars['BigDecimal']['input'] | null;
      customerPrice?: Types.Scalars['BigDecimal']['input'] | null;
      fetCharge?: Types.Scalars['BigDecimal']['input'] | null;
      hasFitment: Types.Scalars['Boolean']['input'];
      id: string;
      listPrice?: Types.Scalars['BigDecimal']['input'] | null;
      mapPrice?: Types.Scalars['BigDecimal']['input'] | null;
      notesOem: Array<Types.Scalars['String']['input']>;
      orderType: Types.OrderType;
      partNumber: Types.Scalars['String']['input'];
      partNumberId: Types.Scalars['String']['input'];
      price?: Types.Scalars['BigDecimal']['input'] | null;
      quantityStep: number;
      sponsorType: Types.SponsorType;
      sponsoredPurchasesCount?: number | null;
      sale: Types.Scalars['Boolean']['input'];
      shippingCharge: Types.Scalars['Boolean']['input'];
      sortHash?: Types.Scalars['String']['input'] | null;
      stocked: Types.Scalars['Boolean']['input'];
      title: Types.Scalars['String']['input'];
      attributes: Array<{
        __typename?: 'ProductAttribute';
        name: Types.Scalars['String']['input'];
        values: Array<Types.Scalars['String']['input']>;
      }>;
      availability: Array<{
        __typename?: 'ProductAvailabilityLine';
        address?: Types.Scalars['String']['input'] | null;
        group?: Types.Scalars['String']['input'] | null;
        id?: number | null;
        name: Types.Scalars['String']['input'];
        quantity: number;
        type: Types.ProductAvailabilityLineType;
      }>;
      brand?: {
        __typename?: 'Brand';
        id: string;
        lineCode?: Types.Scalars['String']['input'] | null;
        logo?: Types.Scalars['String']['input'] | null;
        name: Types.Scalars['String']['input'];
      } | null;
      bundles: Array<{
        __typename?: 'Bundle';
        id: string;
        name: Types.Scalars['String']['input'];
        description?: Types.Scalars['String']['input'] | null;
        expirationDate?: Types.Scalars['String']['input'] | null;
      }>;
      descriptions: Array<{
        __typename?: 'ProductDescription';
        code: Types.Scalars['String']['input'];
        label: Types.Scalars['String']['input'];
        values: Array<Types.Scalars['String']['input']>;
      }>;
      deliveryNotes: Array<{
        __typename?: 'ProductDeliveryNote';
        label: Types.Scalars['String']['input'];
        text: Types.Scalars['String']['input'];
      }>;
      images: Array<{
        __typename?: 'ProductImage';
        full: Types.Scalars['String']['input'];
        medium: Types.Scalars['String']['input'];
        preview: Types.Scalars['String']['input'];
      }>;
      interchangeFor?: {
        __typename?: 'ProductInterchange';
        brandName?: Types.Scalars['String']['input'] | null;
        partNumber: Types.Scalars['String']['input'];
        type: Types.ProductInterchangeType;
      } | null;
      image360Groups: Array<{
        __typename?: 'ProductImage360Group';
        images: Array<{
          __typename?: 'ProductImage';
          full: Types.Scalars['String']['input'];
          preview: Types.Scalars['String']['input'];
          medium: Types.Scalars['String']['input'];
        }>;
      }>;
      lineCard?: { __typename?: 'LineCard'; id: string } | null;
      notes: Array<{ __typename?: 'ProductNotes'; group: Array<Types.Scalars['String']['input']> }>;
      partType?: { __typename?: 'PartType'; id: string; name: Types.Scalars['String']['input'] } | null;
      rebates: Array<{
        __typename?: 'ProductRebate';
        couponLink?: Types.Scalars['String']['input'] | null;
        description: Types.Scalars['String']['input'];
        legalLink?: Types.Scalars['String']['input'] | null;
        price?: Types.Scalars['BigDecimal']['input'] | null;
        title: Types.Scalars['String']['input'];
        validDate?: Types.Scalars['String']['input'] | null;
      }>;
      rewardPoints?: {
        __typename?: 'RewardPoints';
        isPromotion: Types.Scalars['Boolean']['input'];
        points: number;
      } | null;
      variations: Array<{
        __typename?: 'ProductVariation';
        variationID: Types.Scalars['String']['input'];
        attributes: Array<{
          __typename?: 'ProductAttribute';
          name: Types.Scalars['String']['input'];
          values: Array<Types.Scalars['String']['input']>;
        }>;
        notes: Array<{ __typename?: 'ProductNotes'; group: Array<Types.Scalars['String']['input']> }>;
      }>;
      videos: Array<{
        __typename?: 'ProductVideo';
        full: Types.Scalars['String']['input'];
        medium: Types.Scalars['String']['input'];
        preview: Types.Scalars['String']['input'];
      }>;
    }>;
  } | null;
};

export const GetProductsDocument = `
    query GetProducts($searchInput: SearchInput!) {
  products(searchInput: $searchInput) {
    products {
      ...BaseProduct
    }
    errors
  }
}
    ${BaseProductFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetProducts: build.query<GetProductsQuery, GetProductsQueryVariables>({
      query: (variables) => ({ document: GetProductsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetProductsQuery, useLazyGetProductsQuery } = injectedRtkApi;
