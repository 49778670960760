export const AvailabilityTooltip = {
  BACK_ORDER: 'Will ship when available',
  IN_NETWORK:
    'Item is available from other locations. When you place your order, it is likely that your store will source the ' +
    'item from a location on this list.',
  IN_NETWORK_OTHER:
    'Item is available from other locations. When you place your order, it is likely that your store will source the ' +
    'item from other locations available for supplier.',
  SPECIAL_ORDER: 'This part is out of stock locally. Please contact supplier directly to order it.',
};

export const MIN_RECENT_PURCHASE_QUANTITY_FOR_SHOWING = 5;

export const MAX_QUANTITY = 999;
export const MAX_PRICE = 9999.99;

/** @deprecated use models/PartType.TIRE_ID instead */
export const tirePartId = '7636';

export { ATTRIBUTE_OPTIONS } from './attributeOptions';
export type { AttributeName } from './attributeNames';
export type { AttributeOption, AttributeUsageSection, RangeStep } from './attributeOptions';
