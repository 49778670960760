import type { AttributeName } from 'constant';
import type { Filter } from 'types/search';

export type AttributeUsageSection = 'product' | 'filters' | 'search' | 'customerView';

type SortPriority = number;

export type RangeStep = string;

export type AttributeOption = {
  emptyValue: string;
  isCollapsed: boolean;
  label: string;
  name: AttributeName | string;
  orderBy?: Filter['orderBy'];
  rangeStep?: Partial<Record<AttributeUsageSection, RangeStep>>;
  /**
   * Map of sorting weights on different areas listed in `AttributeUsageSection`
   */
  usage: Partial<Record<AttributeUsageSection, SortPriority>>;
  /**
   * If true hide attribute on search result page
   */
  isHidden?: boolean;
  childrenNames?: AttributeName[];
};

export const ATTRIBUTE_OPTIONS: AttributeOption[] = [
  {
    name: 'Position',
    label: 'Position',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 100, product: 100, filters: 23 },
  },
  {
    name: 'Drivetype',
    label: 'Drive',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 200, product: 200, filters: 200 },
  },
  {
    name: 'TransmissionControlTypeId',
    label: 'Transmission Control Type',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 830, product: 830, filters: 830 },
  },
  {
    name: 'Frontspring',
    label: 'Front Spring',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1000, product: 1000, filters: 1000 },
  },
  {
    name: 'Rearspring',
    label: 'Rear Spring',
    emptyValue: 'Not Specified',

    isCollapsed: false,
    usage: { search: 1010, product: 1010, filters: 1010 },
  },
  {
    name: 'Fueltype',
    label: 'Fuel Type',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 700, product: 700, filters: 700 },
  },
  {
    name: 'Enginedesignation',
    label: 'Engine Designation',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 620, product: 620, filters: 620 },
  },
  {
    name: 'Brakesystem',
    label: 'Brake System',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 910, product: 910, filters: 910 },
  },
  {
    name: 'Brakeabs',
    label: 'Brake ABS',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 900, product: 900, filters: 900 },
  },
  {
    name: 'Bodytype',
    label: 'Body',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 500, product: 500, filters: 500 },
  },
  {
    name: 'Bodynumdoors',
    label: 'Doors',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 510, product: 510, filters: 510 },
  },
  {
    name: 'Bedtype',
    label: 'Bed Type',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 410, product: 410, filters: 410 },
  },
  {
    name: 'Aspiration',
    label: 'Aspiration',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 600, product: 600, filters: 600 },
  },
  {
    name: 'Remanufactured',
    label: 'Remanufactured',
    emptyValue: 'New',
    isCollapsed: false,
    usage: { search: 0, product: 0, filters: 30 },
  },
  {
    name: 'EngineVersion',
    label: 'Engine Version',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 630, product: 630, filters: 630 },
  },
  {
    name: 'Composition',
    label: 'Composition',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 2010, filters: 2000 },
  },
  {
    name: 'Viscosity',
    label: 'Viscosity',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 2000, filters: 2010 },
  },
  {
    name: 'Qty',
    label: 'Quantity per vehicle',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1300, product: 1300 },
  },
  {
    name: 'Material',
    label: 'Material',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1310, product: 1310, filters: 26 },
  },
  {
    name: 'Wheelbase',
    label: 'Wheelbase',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 300, product: 300, filters: 300 },
  },
  {
    name: 'WarrantyTime',
    label: 'Warranty Time',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1510, product: 1510, filters: 1510 },
  },
  {
    name: 'WarrantyDistance',
    label: 'Warranty Distance',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1500, product: 1500, filters: 1500 },
  },
  {
    name: 'WarrantySpecial',
    label: 'Warranty Special',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1520, product: 1520 },
  },
  {
    name: 'WarrantyHasSpecial',
    label: 'Custom Warranty',
    emptyValue: 'No',
    isCollapsed: false,
    usage: { filters: 1530 },
  },
  {
    name: 'CCA',
    label: 'Cold Cranking Amps',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { filters: 1600 },
  },
  {
    name: 'ItemQuantity',
    label: 'Item Qty/Size/Weight',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1400, product: 1400 },
  },
  {
    name: 'TransmissionType',
    label: 'Transmission Type',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 810, product: 810, filters: 810 },
  },
  {
    name: 'TransmissionCode',
    label: 'Transmission Code',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 800, product: 800, filters: 800 },
  },
  {
    name: 'BedLength',
    label: 'Bed Length',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 400, product: 400, filters: 400 },
  },
  {
    name: 'EngineMfr',
    label: 'Engine Mfr',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 640, product: 640, filters: 640 },
  },
  {
    name: 'FrontBrakeType',
    label: 'Front Brake',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 920, product: 920, filters: 920 },
  },
  {
    name: 'FuelDeliverySubType',
    label: 'Fuel Delivery Sub Type',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 720, product: 720, filters: 720 },
  },
  {
    name: 'FuelDeliveryType',
    label: 'Fuel Delivery Type',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 710, product: 710, filters: 710 },
  },
  {
    name: 'FuelSystemControlType',
    label: 'Fuel System Control Type',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 740, product: 740, filters: 740 },
  },
  {
    name: 'FuelSystemDesign',
    label: 'Fuel System Design',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 730, product: 730, filters: 730 },
  },
  {
    name: 'IgnitionSystemType',
    label: 'Ignition System',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 650, product: 650, filters: 650 },
  },
  {
    name: 'MfrBodyCode',
    label: 'Body Code',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 520, product: 520, filters: 520 },
  },
  {
    name: 'RearBrakeType',
    label: 'Rear Brake',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 930, product: 930, filters: 930 },
  },
  {
    name: 'Region',
    label: 'Region',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { product: 1200, filters: 1200 },
  },
  {
    name: 'SteeringSystem',
    label: 'Steering System',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1100, product: 1100, filters: 1100 },
  },
  {
    name: 'SteeringType',
    label: 'Steering Type',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1110, product: 1110, filters: 1110 },
  },
  {
    name: 'TransmissionElectronicallyControlled',
    label: 'Transmission Elec Controlled',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 850, product: 850, filters: 850 },
  },
  {
    name: 'TransmissionMfr',
    label: 'Transmission Mfr',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 840, product: 840, filters: 840 },
  },
  {
    name: 'TransmissionNumberofSpeeds',
    label: 'Transmission Num Speeds',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 820, product: 820, filters: 820 },
  },
  {
    name: 'ValvesPerEngine',
    label: 'Valves',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 610, product: 610, filters: 610 },
  },
  {
    name: 'CylinderHeadType',
    label: 'Cylinder Head',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 631, product: 631, filters: 631 },
  },
  {
    name: 'EngineVIN',
    label: 'Engine VIN',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 601, product: 601, filters: 601 },
  },
  {
    name: 'PowerOutputHP',
    label: 'Power Output',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 645, product: 645, filters: 645 },
  },
  {
    name: 'TiresSize',
    label: 'Size',
    emptyValue: 'Not Specified',
    isCollapsed: true,
    usage: { search: 90, product: 90, customerView: 10 },
    childrenNames: ['TiresLoadIndex', 'TiresLoadIndexDual', 'TiresSpeedRating', 'TiresLoadRange'],
  },
  {
    name: 'TiresCategoryLabel',
    label: 'Category',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 2350, product: 2350, customerView: 30 },
  },
  {
    name: 'TiresMileageRating',
    label: 'Warranty',
    emptyValue: 'Not Specified',
    isCollapsed: true,
    usage: { search: 2200, product: 2200, filters: 2200, customerView: 20 },
  },
  {
    name: 'TiresKilometerRating',
    label: 'Kilometer Rating',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: {},
  },
  {
    name: 'TiresLoadIndex',
    label: 'Load Index',
    emptyValue: 'Not Specified',
    isCollapsed: true,
    isHidden: true,
    usage: { search: 2400, product: 2400, filters: 2400, customerView: 70 },
  },
  {
    name: 'TiresLoadIndexDual',
    label: 'Load Index Dual',
    emptyValue: 'Not Specified',
    isCollapsed: true,
    isHidden: true,
    usage: { search: 2450, product: 2450, customerView: 75 },
  },
  {
    name: 'TiresPlyRating',
    label: 'Ply',
    emptyValue: 'Not Specified',
    isCollapsed: true,
    usage: { search: 2550, product: 2550, filters: 2550, customerView: 85 },
  },
  {
    name: 'TiresLoadRange',
    label: 'Load Range',
    emptyValue: 'Not Specified',
    isCollapsed: true,
    isHidden: true,
    usage: { search: 2550, product: 2550, filters: 2600, customerView: 90 },
  },
  {
    name: 'TiresSpeedRating',
    label: 'Speed Rating',
    emptyValue: 'Not Specified',
    isCollapsed: true,
    isHidden: true,
    usage: { search: 2500, product: 2500, filters: 2500, customerView: 80 },
  },
  {
    name: 'TiresSideWallStyle',
    label: 'Sidewall Style',
    emptyValue: 'Not Specified',
    isCollapsed: true,
    usage: { product: 2700, customerView: 40 },
  },
  {
    name: 'TiresSupplier',
    label: 'Supplier',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1600, product: 1600, filters: 1600 },
  },
  {
    name: 'TiresCommercialPosition',
    label: 'Position',
    emptyValue: 'All Position',
    isCollapsed: false,
    usage: { search: 2250, product: 2250, filters: 2250, customerView: 100 },
  },
  {
    name: 'TiresApplication',
    label: 'Application',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 2300, product: 2300, filters: 2300, customerView: 110 },
  },
  {
    name: 'Refurbished',
    label: 'Refurbished',
    emptyValue: 'New',
    isCollapsed: false,
    usage: { search: 10, product: 10, filters: 10 },
  },
  {
    name: 'TiresQuality',
    label: 'UTQG',
    emptyValue: 'Not Specified',
    isCollapsed: true,
    usage: { search: 2800, product: 2800, filters: 2800, customerView: 60 },
  },
  {
    name: 'TireType',
    label: 'Tire Type',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: {},
  },
  {
    name: 'TiresCategory',
    label: 'Category',
    emptyValue: 'Not Specified',
    isCollapsed: true,
    usage: { filters: 2100 },
  },
  {
    name: 'TiresSeasons',
    label: 'Seasons',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { filters: 2000 },
  },
  {
    name: 'TiresRunFlat',
    label: 'Run-flat',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1900, product: 1900, filters: 1900, customerView: 50 },
    orderBy: { field: 'name', direction: 'desc' },
  },
  {
    name: 'TireTreadDepth',
    label: 'Tread Depth',
    emptyValue: 'Not Specified',
    isCollapsed: true,
    usage: { search: 2850, product: 2850, filters: 2850, customerView: 70 },
  },
  {
    name: 'TireWinterStud',
    label: 'Winter Stud',
    emptyValue: 'Not Specified',
    isCollapsed: true,
    usage: { search: 2900, product: 2900, filters: 2900, customerView: 80 },
  },
  {
    name: 'TireSpecialNote',
    label: 'Special Note',
    emptyValue: 'Not Specified',
    isCollapsed: true,
    usage: { search: 2950, product: 2950, filters: 2950, customerView: 90 },
  },
  {
    name: 'TiresOEM',
    label: 'OE',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1800, product: 1800, filters: 30 },
  },
  {
    name: 'WinterCertification',
    label: 'Winter Certification',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    isHidden: true,
    usage: { search: 2850, product: 2850 },
  },
  {
    name: 'Diameter',
    label: 'Diameter',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1800, product: 1800, filters: 2000 },
    rangeStep: {
      search: '0.2',
      filters: '0.2',
    },
  },
  {
    name: 'DiscBrakeRotorOutsideDiameter',
    label: 'Outside Diameter',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1800, product: 1800, filters: 1800 },
  },
  {
    name: 'CabinAirFilter',
    label: 'Filter Media',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1800, product: 1800, filters: 1800 },
  },
  {
    name: 'CatalyticConverter',
    label: 'Emissions Compliance',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1800, product: 1800, filters: 1800 },
  },
  {
    name: 'ThermostatTemperature',
    label: 'Temperature',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1800, product: 1800, filters: 1800 },
  },
  {
    name: 'ThermostatOEMTemperature',
    label: 'OE Temperature',
    emptyValue: 'Not Specified',
    isCollapsed: false,
    usage: { search: 1800, product: 1800, filters: 1800 },
  },
];
