import { useCreateModal, usePrevious } from '@partstech/ui/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DiagramsListModal } from 'components/VehicleDiagrams/DiagramsListModal';
import { useDiagramsCategories } from 'hooks/diagrams';
import { useSearchParams } from 'hooks/search';
import { useSearchBar } from '../../../widgets/searchBar/context/SearchBarContext';
import { DiagramButton } from './DiagramButton';

type Props = {
  onDismiss?: () => void;
};

export const DiagramQuickLink = ({ onDismiss }: Props) => {
  const { searchParams, partType } = useSearchParams();
  const previousSearchParams = usePrevious(searchParams);
  const { openVehicleSelector, vehicle } = useSearchBar();

  const [shouldOpenModal, setShouldOpenModal] = useState(false);

  const { categories } = useDiagramsCategories({ vehicleId: vehicle?.id ?? null });

  const selectedDiagramCategory = useMemo(
    () => categories.find((category) => partType?.category?.id === category.id),
    [categories, partType?.category?.id]
  );

  const diagramCategoryTitle = useMemo(() => {
    if (vehicle?.id && selectedDiagramCategory?.name) {
      return selectedDiagramCategory.name;
    }

    return 'All categories';
  }, [selectedDiagramCategory?.name, vehicle?.id]);

  const handleSelectVehicleClick = useCallback(() => {
    openVehicleSelector();
    setShouldOpenModal(true);
  }, [openVehicleSelector]);

  const { open: openDiagramQuickLinkModal } = useCreateModal(DiagramsListModal, {
    size: 'xl',
    onSelectVehicleClick: handleSelectVehicleClick,
    onClose: () => onDismiss?.(),
    defaultCategoryId: selectedDiagramCategory?.id ?? null,
    vehicleId: vehicle?.id ?? null,
  });

  useEffect(() => {
    if (!shouldOpenModal) {
      return;
    }

    if (previousSearchParams && !previousSearchParams?.vehicle && vehicle?.id) {
      setShouldOpenModal(false);
      openDiagramQuickLinkModal({ onSelectVehicleClick: handleSelectVehicleClick });
    }
  }, [handleSelectVehicleClick, vehicle?.id, openDiagramQuickLinkModal, previousSearchParams, shouldOpenModal]);

  return <DiagramButton categoryTitle={diagramCategoryTitle} onClick={openDiagramQuickLinkModal} />;
};
