import { Box, Breadcrumbs } from '@partstech/ui';
import { isNotNull, sortUniq } from '@partstech/ui/utils';
import { useMemo, useState } from 'react';
import { OptionsByColumns } from 'components/OptionsByColumns';
import { DiagramsOfCategory } from '../DiagramsOfCategory';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { Diagram } from 'models';

type Props = {
  options: Diagram[];
  onChange: (option: Diagram) => void;
  onSelect?: (option: SearchEntryPointInterface) => void;
  isKeyboardDisabled?: boolean;
};

type DiagramCategoryOption = Diagram['category'] & {
  populateInput: () => string;
  toString: () => string;
  isVehicleRequired: () => boolean;
};

const createDiagramCategoryOption = (diagramCategory: NonNullable<Diagram['category']>): DiagramCategoryOption => ({
  ...diagramCategory,
  isVehicleRequired: () => true,
  toString: () => diagramCategory?.name ?? '',
  populateInput: () => '',
});

const getUniqueDiagramCategories = (diagrams: Diagram[]) =>
  sortUniq(diagrams.map((diagram) => diagram.category).filter(isNotNull), 'name').map(createDiagramCategoryOption);

export const VehicleDiagrams = ({ options, onChange, onSelect, isKeyboardDisabled }: Props) => {
  const [selectedCategory, selectCategory] = useState<DiagramCategoryOption | null>(null);

  const categories = useMemo(() => getUniqueDiagramCategories(options), [options]);

  const breadcrumbs = useMemo(
    () => [
      {
        title: 'All Diagrams',
        onClick: () => {
          selectCategory(null);
        },
      },
      { title: `${selectedCategory?.name}` },
    ],
    [selectedCategory?.name]
  );

  if (selectedCategory) {
    return (
      <>
        <Box display="flex">
          <Breadcrumbs breadcrumbs={breadcrumbs} px={4} py={2} background="mono2" mb={4} />
        </Box>

        <DiagramsOfCategory
          diagrams={options}
          categoryId={selectedCategory.id}
          onChange={onChange}
          onSelect={onSelect}
          isKeyboardDisabled={isKeyboardDisabled}
        />
      </>
    );
  }

  return <OptionsByColumns options={categories} onChange={selectCategory} />;
};
